<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h2 class="pl-8">{{$_strings.mou.INPUT_LIMIT}}</h2>
      </v-card-title>
      <v-card-title class="caption">
        <v-row class="pl-8">
          <v-col class="pb-0">
            <v-row class="pb-0">
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{$_strings.mou.NUMBER_OF_VEHICLES}}</h3>
              </v-col>
              <v-col cols="12" sm="8">
                <h3 class="grey--text">{{item.typeTransporter || item.transportTypeName}}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{$_strings.mou.NUMBER_OF_UNIT}}</h3>
              </v-col>
              <v-col cols="12" sm="4">
                <h3 class="grey--text">{{item.unitLimit}}</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{$_strings.mou.INTERVAL}}</h3>
              </v-col>
              <v-col class="pb-0" cols="12" sm="4">
                <h3 class="grey--text">{{item.interval}}</h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row v-if="!form.length && !isLoading" class="mt-2">
          <v-col class="d-flex justify-center">
            <div><h2>{{$_strings.mou.NO_DATA}}</h2></div>
          </v-col>
        </v-row>
        <v-form ref="form">
          <v-data-table
            v-if="form.length"
            :headers="headerLicensePlate"
            :items="form"
            hide-default-footer
            :items-per-page="1000"
            class="elevation-0 pa-4 header_license_plate"
            item-key="companyTransportId"
          >
            <template v-slot:[`item.licensePlate`]="{item, index}">
              <v-autocomplete
                v-if="!item.companyTransportId"
                :items="[ ...mouTransportLimit.filter((itemLimit) => {
                  if (!form.find((fm) => fm.companyTransportId === itemLimit.companyTransportId)) {
                    return itemLimit
                  }
                })]"
                @input.native="($event) => fetchListLicensePlate($event.target.value, item)"
                item-text="licensePlate"
                item-value="companyTransportId"
                @change="($event) => updateForm($event, index)"
                :loading="item.isLoading || isLoadingGetListLicensePlate"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Nomor Kendaraan')]"
                v-model="item.companyTransportId"
                dense
                class="body-2"
                hide-details
                outlined
              ></v-autocomplete>
              <v-autocomplete
                v-else
                :items="[ ...mouTransportLimit.filter((itemLimit) => {
                  if ((itemLimit.id !== item.companyTransportId) && !form.find((fm) => fm.companyTransportId === itemLimit.companyTransportId)) {
                    return itemLimit
                  }
                }), item]"
                @input.native="($event) => fetchListLicensePlate($event.target.value, item)"
                item-text="licensePlate"
                item-value="companyTransportId"
                @change="($event) => updateForm($event, index)"
                :loading="item.isLoading || isLoadingGetListLicensePlate"
                v-model="item.companyTransportId"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED('Nomor Kendaraan')]"
                dense
                class="body-2"
                hide-details
                outlined
                item-key="companyTransportId"
              ></v-autocomplete>
            </template>
            <template v-slot:[`item.price`]="{}">
              {{formatAsCurrency(item.price)}}
            </template>
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="d-flex justify-center"
      >
        <v-row justify="center">
          <v-col cols="4">
            <v-btn
              block
              color="red"
              small
              class="white--text mr-4"
              @click="dialog = false"
            >
              {{$_strings.common.CANCEL}}
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              block
              color="primary"
              small
              @click="saveTransportLimit"
              :disabled="isLoading || form.length === 0"
            >
              {{$_strings.mou.APPLY}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  props: {
    mouId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      headerLicensePlate: [
        {
          text: this.$_strings.mou.LICENSE_PLATE,
          value: 'licensePlate',
          sortable: false,
          class: 'black--text tranparent license_plate',
        },
        {
          text: this.$_strings.mou.RITASE_OF_LIMIT,
          value: 'limits',
          sortable: false,
          class: 'black--text tranparent',
        },
        {
          text: this.$_strings.mou.PRICE_OF_INTERVAL,
          value: 'price',
          sortable: false,
          class: 'black--text tranparent',
        },
      ],
      mouTransportLimit: [],
      item: {},
      totalItems: 0,
      form: [],
      isLoadingListLicensePlate: [],
      isLoadingGetListLicensePlate: false,
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        source = CancelToken.source();
        this.fetchListTransportTypeLimit();
        this.fetchListLicensePlate();
      }
      if (!newVal) {
        this.mouTransportLimit = [];
        this.form = [];
        source.cancel('CANCELED_BY_THE_USER');
      }
    },
  },
  methods: {
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    updateForm(value, index) {
      const newData = this.mouTransportLimit.find((transportLimit) => transportLimit.companyTransportId === value);
      this.form.splice(index, 1, {
        ...this.form[index],
        ...newData,
      });
    },
    async fetchListLicensePlate(licensePlate, item) {
      const { transportTypeId } = this.item;
      const filters = {
        page: 0,
        size: 50,
        sort: 'createdAt,DESC',
        transportTypesId: transportTypeId,
        isActive: true,
      };
      if (licensePlate) {
        filters.licensePlate = licensePlate;
      }
      try {
        if (item) {
          this.$set(item, 'isLoading', true);
        } else {
          this.isLoadingGetListLicensePlate = true;
        }
        const result = await this.$_services.mou.getListLincesePlate(filters, source);
        if (result.data.contents) {
          this.mouTransportLimit = [...this.mouTransportLimit, ...result.data.contents.map((fm) => ({
            companyTransportId: fm.id,
            licensePlate: fm.licensePlate,
          }))];
        }
      } finally {
        if (item) {
          this.$delete(item, 'isLoading');
        } else {
          this.isLoadingGetListLicensePlate = false;
        }
      }
    },
    async fetchListTransportTypeLimit() {
      const { id, unitLimit } = this.item;
      const filters = {
        page: 0,
        size: unitLimit,
      };
      try {
        this.isLoading = true;
        const result = await this.$_services.mou.getListTransportTypeLimitShipper(this.mouId, id, filters, source);
        if (result.data.contents.length) {
          this.form = result.data.contents.map((xData) => ({
            ...xData,
            licensePlate: xData.licensePlate || '-',
          }));
        } else {
          this.generateForm();
        }
        this.totalItems = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    generateForm() {
      const { unitLimit, limits } = this.item;
      for (let i = 0; i < unitLimit; i += 1) {
        this.form.push({
          companyTransportId: null,
          id: null,
          licensePlate: '',
          limits,
        });
        this.isLoadingListLicensePlate.push(false);
      }
    },
    async saveTransportLimit() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return this.$dialog.notify.error('Nomor kendaraan wajib diisi!');
      }
      const dataSubmission = {
        mouTransportLimit: [...this.form.map((fm) => ({
          id: fm.id,
          companyTransportId: fm.companyTransportId,
          licensePlate: fm.licensePlate,
        }))],
      };
      const { id } = this.item;
      try {
        this.isLoading = true;
        await this.$_services.mou.saveTransportLimitShipper(this.mouId, id, dataSubmission, source);
        this.dialog = false;
        this.$dialog.notify.success('Berhasil');
        this.form = [];
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-data-table::v-deep > .v-data-table__wrapper > table > .v-data-table-header > tr > th:nth-child(1)::after {
  content: '*';
  color: red;
  font-size: 12px;
}
</style>
