var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.$_strings.mou.FILTER))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('common-auto-complete-items',{staticClass:"body-2",attrs:{"optional":{
            mouId: _vm.mouId,
            mouBy: _vm.mouBy,
            isDelete: false
          },"type":_vm.getAndCheckTypeAutoCompleteLocation,"placeholder":"Lokasi Asal","searchName":"locationName","item-value":"locationId","item-text":"locationName","clearable":"","dense":"","outlined":"","hide-details":"","filter":_vm.pageFiltersLocation,"items":_vm.itemsLocationMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateMouRouteLocationItems', newItems); }},model:{value:(_vm.originId),callback:function ($$v) {_vm.originId=$$v},expression:"originId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('common-auto-complete-items',{staticClass:"body-2",attrs:{"optional":{
            mouId: _vm.mouId,
            mouBy: _vm.mouBy,
            isDelete: false
          },"type":_vm.getAndCheckTypeAutoCompleteLocation,"placeholder":"Lokasi Tujuan","searchName":"locationName","item-value":"locationId","item-text":"locationName","clearable":"","dense":"","outlined":"","hide-details":"","filter":_vm.pageFiltersLocation,"items":_vm.itemsLocationMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateMouRouteLocationItems', newItems); }},model:{value:(_vm.destinationId),callback:function ($$v) {_vm.destinationId=$$v},expression:"destinationId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{staticClass:"white--text",attrs:{"small":"","color":"grey"},on:{"click":_vm.setFilters}},[_vm._v(" "+_vm._s(_vm.$_strings.common.SEARCH)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }