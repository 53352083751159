var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$_strings.mou.LOCATION_DATA))]),_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.LOCATION))]),(_vm.showBtnChangeHistory)?_c('div',[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2 mb-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","server-items-length":_vm.serverItemsLength,"options":_vm.pagination,"loading":_vm.isLoading,"headers":_vm.displayedHeaderLocation,"items":_vm.readonly ? _vm.mouLocations: _vm.mouLocations.concat( [{
          setNewData: true
        }]),"item-class":_vm.itemRowBackground,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.locationName",fn:function(ref){
        var item = ref.item;
return [(item.setNewData)?_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"type":"locations","searchName":"name","item-value":"id","item-text":"name","placeholder":"Masukkan Nama Lokasi","dense":"","outlined":"","filter":_vm.pageFilters,"items":_vm.itemsLocation},on:{"change":_vm.changeLocation,"updateItems":function (items) { return _vm.$emit('updateLocationItems', items); }},model:{value:(_vm.locationForm.id),callback:function ($$v) {_vm.$set(_vm.locationForm, "id", $$v)},expression:"locationForm.id"}}):_c('span',[_vm._v(_vm._s(item.locationName))])]}},{key:"item.address",fn:function(ref){
        var item = ref.item;
return [(item.setNewData)?_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":"","placeholder":"Alamat"},model:{value:(_vm.locationForm.address),callback:function ($$v) {_vm.$set(_vm.locationForm, "address", $$v)},expression:"locationForm.address"}}):_c('span',[_vm._v(_vm._s(item.address))])]}},{key:"item.locationCityName",fn:function(ref){
        var item = ref.item;
return [(item.setNewData)?_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":"","placeholder":"Kota/Kabupaten"},model:{value:(_vm.locationForm.locationCityName),callback:function ($$v) {_vm.$set(_vm.locationForm, "locationCityName", $$v)},expression:"locationForm.locationCityName"}}):_c('span',[_vm._v(_vm._s(item.locationCityName))])]}},{key:"item.latLong",fn:function(ref){
        var item = ref.item;
return [(item.setNewData)?_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":"","placeholder":"Koordinat"},model:{value:(_vm.locationForm.latLong),callback:function ($$v) {_vm.$set(_vm.locationForm, "latLong", $$v)},expression:"locationForm.latLong"}}):_c('span',[_vm._v(" "+_vm._s(("Lat: " + (item.latitude) + " Long: " + (item.longitude)))+" ")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(item.locationId)?_c('v-btn',{attrs:{"icon":"","color":"grey","loading":item.deleting},on:{"click":function($event){return _vm.deleteMouLocation(item, index)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1):_c('div',[_c('v-btn',{attrs:{"disabled":_vm.isLoadingAddLocation,"icon":"","color":"red"},on:{"click":_vm.resetForm}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_c('v-btn',{attrs:{"loading":_vm.isLoadingAddLocation,"icon":"","color":"primary"},on:{"click":_vm.createMouNewLocation}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1)],1)]}}],null,true)})],1)],1),_c('DialogChangeHistoryLocationAndTransportTypes',{ref:"dialogChangeHistory",attrs:{"mouId":_vm.mouId,"service":_vm.getService1}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }