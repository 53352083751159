<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="722"
  >
    <v-card :loading="isLoading">
      <v-row class="ma-0">
        <v-col class="d-flex justify-end">
          <v-toolbar-items>
            <v-btn
              icon
              color="black"
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-col>
      </v-row>
      <v-card-title class="body-1">
        <h4>{{$_strings.mou.AUTO_EXTENDOR}}</h4>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row class="mt-4">
            <v-col class="pt-0" cols="12" sm="3">
              <label class="caption ma-0" for="periodicCooperation">{{$_strings.mou.PERIODIC_COOPERATION}}
                <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
              </label>
            </v-col>
            <v-col cols="12" sm="3" class="pt-0">
              <v-menu
                ref="menuFromDate"
                v-model="menuFromDate"
                :close-on-content-click="false"
                :return-value.sync="data.fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="periodicCooperation"
                    dense
                    outlined
                    v-model="fromDateFormat"
                    :rules="rules.date"
                    class="caption"
                    placeholder="From Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.fromDate"
                  no-title
                  scrollable
                  locale="id"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuFromDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuFromDate.save(data.fromDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="auto" class="pt-0">
              <span class="caption ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
              </span>
            </v-col>
            <v-col cols="12" sm="auto" class="pt-0">
              <v-menu
                ref="menuToDate"
                v-model="menuToDate"
                :close-on-content-click="false"
                :return-value.sync="data.extendedDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="periodicCooperation"
                    dense
                    outlined
                    v-model="toDateFormat"
                    :rules="rules.date"
                    class="caption"
                    placeholder="To Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="data.extendedDate"
                  :min="data.fromDate"
                  no-title
                  scrollable
                  locale="id"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuToDate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuToDate.save(data.extendedDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="3">
              <label class="caption" for="notes">{{$_strings.mou.REASON}}</label> <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
            </v-col>
            <v-col cols="12" sm="9">
              <v-textarea
                no-resize
                outlined
                class="caption mt-2 mb-2"
                id="notes"
                dense
                v-model="data.notes"
                rows="5"
                :counter="255"
                :rules="[(v) => rulesNotes(v, false, 255, $_strings.order.EXPLANATION_OF_REASON)]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          outlined
          small
          color="primary"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          small
          color="primary"
          :disabled="isLoading"
          @click="validate()"
        >
          {{$_strings.mou.EXTEND}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs';
import { rulesNotes } from '../../../helper/commonHelpers';

export default {
  watch: {
    dialogExtendContract() {
      this.dialog = this.dialogExtendContract;
    },
    'data.fromDate': function formatDate() {
      if (this.data.fromDate) {
        this.fromDateFormat = dayjs(this.data.fromDate).format('DD/MM/YYYY');
      } else {
        this.fromDateFormat = '';
      }
    },
    'data.extendedDate': function formatDate() {
      if (this.data.extendedDate) {
        this.toDateFormat = dayjs(this.data.extendedDate).format('DD/MM/YYYY');
      } else {
        this.toDateFormat = '';
      }
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      dialog: false,
      data: {
        id: null,
        notes: '',
        fromDate: '',
        extendedDate: '',
      },
      rules: {
        date: [(v) => !!v || 'harus dipilih'],
      },
      menuFromDate: false,
      fromDateFormat: '',
      menuToDate: false,
      toDateFormat: '',
      isShipper: false,
    };
  },
  methods: {
    rulesNotes,
    validate() {
      const valid = this.$refs.form.validate();
      if (valid) {
        return this.save();
      }
      return this.$dialog.notify.error('Mohon periksa kembali !!');
    },
    async save() {
      try {
        this.isLoading = true;
        // format default
        const extendedDate = dayjs(this.data.extendedDate).format();
        if (this.isShipper) {
          await this.$_services.mou.extendMouShipper({ ...this.data, extendedDate });
        } else {
          await this.$_services.mou.extendMouTransporter({ ...this.data, extendedDate });
        }
        this.dialog = false;
        this.$dialog.notify.success('Berhasil memperpanjang MoU');
        this.$emit('fetchMouDetail', this.data.id);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
