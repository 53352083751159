var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-4"},[(_vm.showBtnChangeHistory)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","loading":_vm.isLoading,"headers":_vm.displayedHeaderLocation,"items":_vm.readonly ? _vm.mouTransportTypeLimit : _vm.mouTransportTypeLimit.concat( [{
          setNewData: true
        }]),"server-items-length":_vm.serverItemsLength,"options":_vm.pagination,"item-class":_vm.itemRowBackground,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.itemsPerPage,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.transportTypeName",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(item.setNewData)?_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"optional":{
              mouId: _vm.mouId,
              mouBy: _vm.mouBy,
              isDelete: false,
            },"type":_vm.getAndCheckTypeAutoCompleteTransportTypes,"searchName":"name","item-text":"name","item-value":"name","dense":"","outlined":"","filter":_vm.pageFilters,"items":_vm.itemsTransportTypesMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateTransportTypesMouRoute', newItems); },"input":function($event){return _vm.handleChangeTypeTransporter(_vm.transportTypeLimitform, index, { type: 'transportTypeLimitform' })}},model:{value:(_vm.transportTypeLimitform.transportTypeName),callback:function ($$v) {_vm.$set(_vm.transportTypeLimitform, "transportTypeName", $$v)},expression:"transportTypeLimitform.transportTypeName"}}):_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"optional":{
              mouId: _vm.mouId,
              mouBy: _vm.mouBy,
              isDelete: false,
            },"type":_vm.getAndCheckTypeAutoCompleteTransportTypes,"searchName":"name","item-text":"name","item-value":"name","dense":"","outlined":"","disabled":_vm.readonly,"filter":_vm.pageFilters,"items":_vm.itemsTransportTypesMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateTransportTypesMouRoute', newItems); },"input":function($event){return _vm.handleChangeTypeTransporter(item, index)}},model:{value:(item.transportTypeName),callback:function ($$v) {_vm.$set(item, "transportTypeName", $$v)},expression:"item.transportTypeName"}})]}},{key:"item.unitLimit",fn:function(ref){
            var item = ref.item;
return [(item.setNewData)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":""},on:{"input":function($event){return _vm.handleChangeUnitLimit(_vm.transportTypeLimitform, { type: 'transportTypeLimitform'})}},model:{value:(_vm.transportTypeLimitform.unitLimit),callback:function ($$v) {_vm.$set(_vm.transportTypeLimitform, "unitLimit", $$v)},expression:"transportTypeLimitform.unitLimit"}}):_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":_vm.readonly},on:{"input":function($event){return _vm.handleChangeUnitLimit(item)}},model:{value:(item.unitLimit),callback:function ($$v) {_vm.$set(item, "unitLimit", $$v)},expression:"item.unitLimit"}})]}},{key:"item.limits",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.setNewData)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","rules":_vm.rules.unitRules,"disabled":+_vm.transportTypeLimitform.unitLimit === 0 ? true : false},model:{value:(_vm.transportTypeLimitform.limits),callback:function ($$v) {_vm.$set(_vm.transportTypeLimitform, "limits", $$v)},expression:"transportTypeLimitform.limits"}}):_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","rules":_vm.rules.unitRules,"disabled":(+item.unitLimit === 0 ? true : false) || _vm.readonly},model:{value:(item.limits),callback:function ($$v) {_vm.$set(item, "limits", $$v)},expression:"item.limits"}})],1)]}},{key:"item.interval",fn:function(ref){
            var item = ref.item;
return [(item.setNewData)?_c('v-select',{staticClass:"body-2 pt-2",attrs:{"item-text":"name","item-value":"value","dense":"","outlined":"","disabled":+_vm.transportTypeLimitform.unitLimit === 0 ? true : false,"items":_vm.itemsInterval},model:{value:(_vm.transportTypeLimitform.interval),callback:function ($$v) {_vm.$set(_vm.transportTypeLimitform, "interval", $$v)},expression:"transportTypeLimitform.interval"}}):_c('v-select',{staticClass:"body-2 pt-2",attrs:{"item-text":"name","item-value":"value","dense":"","outlined":"","disabled":(+item.unitLimit === 0 ? true : false) || _vm.readonly,"items":_vm.itemsInterval},model:{value:(item.interval),callback:function ($$v) {_vm.$set(item, "interval", $$v)},expression:"item.interval"}})]}},{key:"item.price",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(item.setNewData)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'idr-currency', rightAlign: false}),expression:"{'alias': 'idr-currency', rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":+_vm.transportTypeLimitform.unitLimit === 0 ? true : false},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}):_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'idr-currency', rightAlign: false}),expression:"{'alias': 'idr-currency', rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","rules":item.price ? [] : _vm.rules.numberRules,"outlined":"","prefix":"Rp","value":_vm.formatAsCurrency(item.price),"disabled":(+item.unitLimit === 0 ? true : false) || _vm.readonly},on:{"input":function($event){return _vm.changePrice($event, index)}}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(!item.setNewData)?{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"loading":item.deleting,"icon":"","color":"grey"},on:{"click":function($event){return _vm.deleteMouTransportTypeLimit(item,index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]),(_vm.handleAddButton(item, index) && !item.deleting)?_c('div',{staticClass:"d-inline"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"loading":item.isLoading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.updateMouTransportTypeLimit(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.UPDATE))])])],1):(item.setNewData)?_c('div',[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":_vm.resetForm}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_c('v-btn',{attrs:{"loading":item.isLoading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.createMouTransportTypeLimit(item)}}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1)],1):_vm._e()]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [(_vm.userAccess && _vm.userAccess.canCreate)?_c('v-btn',{staticClass:"mb-3",attrs:{"depressed":"","outlined":"","small":""},on:{"click":function($event){return _vm.actionApplyButton(item, _vm.mouBy === 'transporter' ? 'dialogLicensePlate' : 'dialogSetPoliceNumberShipper')}}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.APPLY)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('DialogLicensePlate',{ref:"dialogLicensePlate",attrs:{"mouId":_vm.mouId}}),_c('DialogSetPoliceNumberShipper',{ref:"dialogSetPoliceNumberShipper",attrs:{"mouId":_vm.mouId}}),_c('DialogChangeHistoryFixedPrice',{ref:"dialogChangeHistoryFixedPrice",attrs:{"mouId":_vm.mouId,"mouBy":_vm.mouBy,"getService1":_vm.getService1}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }