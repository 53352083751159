var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.TRANSPORT_TYPE))]),(_vm.showBtnChangeHistory)?_c('div',[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeaderTransporter,"items":_vm.readonly ? _vm.mouTransportTypes : _vm.mouTransportTypes.concat( [{
          setNewData: true
        }]),"server-items-length":_vm.serverItemsLength,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page},"item-key":"id","item-class":_vm.itemRowBackground},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.setNewData)?_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"type":"transportTypes","searchName":"name","item-value":"id","item-text":"name","placeholder":"Masukkan Nama Kendaraan","dense":"","outlined":"","filter":_vm.pageFilters,"items":_vm.itemsTransportTypes},on:{"change":_vm.changeTransportType,"updateItems":function (items) { return _vm.$emit('updateTransportTypesItems', items); }},model:{value:(_vm.transportTypesForm.id),callback:function ($$v) {_vm.$set(_vm.transportTypesForm, "id", $$v)},expression:"transportTypesForm.id"}}):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
        var item = ref.item;
return [(item.setNewData)?_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","disabled":"","outlined":"","placeholder":"Deskripsi"},model:{value:(_vm.transportTypesForm.description),callback:function ($$v) {_vm.$set(_vm.transportTypesForm, "description", $$v)},expression:"transportTypesForm.description"}}):_c('span',[_vm._v(_vm._s(((item.length) + " x " + (item.width) + " x " + (item.height))))])]}},{key:"item.imageUrl",fn:function(ref){
        var item = ref.item;
return [(item.setNewData)?void 0:(!item.imageUrl)?_c('v-icon',{staticClass:"w3",attrs:{"x-large":""}},[_vm._v(" mdi-truck-outline ")]):_c('v-img',{attrs:{"contain":"","alt":"Image Transporter","src":item.imageUrl,"height":150,"width":150,"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [(item.transportTypeId)?_c('v-btn',{attrs:{"icon":"","color":"grey","loading":item.deleting},on:{"click":function($event){return _vm.deleteMouTransportTypes(item,index)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1):_c('div',[_c('v-btn',{attrs:{"disabled":_vm.isLoadingAddTransportTypes,"icon":"","color":"red"},on:{"click":_vm.resetForm}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_c('v-btn',{attrs:{"loading":_vm.isLoadingAddTransportTypes,"icon":"","color":"primary"},on:{"click":_vm.createMouNewTransportTypes}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1)],1)]}}],null,true)})],1)],1),_c('DialogChangeHistoryLocationAndTransportTypes',{ref:"dialogChangeHistory",attrs:{"mouId":_vm.mouId,"service":_vm.getService1}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }