<template>
  <v-container class="pa-0" fluid>
    <v-form
      ref="form"
    >
      <v-row class="mb-4">
        <v-col class="pb-0" v-if="showBtnChangeHistory" cols="12">
          <v-btn
            color="red"
            class="pa-0 caption"
            text
            @click="showDialogChangeHistory"
          >
            {{$_strings.mou.THERE_ARE_CHANGES}}
            <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            item-key="id"
            single-expand
            class="elevation-1"
            show-expand
            :loading="isLoading"
            :server-items-length="serverItemsLength"
            :options.sync="pagination"
            :key="reFetchData"
            :headers="displayedHeaderTableFcl"
            :item-class="itemRowBackground"
            :items="readonly ? mouFclRoute : [ ...mouFclRoute, {
              setNewData: true
            }]"
            :footer-props="{
              showCurrentPage: true,
              showFirstLastPage: true,
              'items-per-page-options': itemsPerPage,
              disablePagination: isLoading,
              disableItemsPerPage: isLoading
            }"
            :expanded.sync="expanded"
            @item-expanded="onExpanded"
          >
            <template v-slot:[`header.isAutoAssign`]="{ header }">
              <p class="text-center mb-0">
                {{header.text}}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      x-small
                      class="mb-4"
                      v-bind="attrs"
                      v-on="on"
                      color="white">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>{{$_strings.mou.A_A}}</span>
                </v-tooltip>
              </p>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
              <v-icon
                v-if="showExpandPickDrop(item)"
                :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
                @click.stop="expand(!isExpanded)"
              >
                $expand
              </v-icon>
            </template>
            <template v-slot:expanded-item="{item}">
              <td :colspan="8">
                <v-data-table
                  :headers="subHeaderTableFCL"
                  :items="item.setNewData && fclForm.mouRoutesMultiLoc ? [ ...fclForm.mouRoutesMultiLoc , {
                    setNewPickDrop: true
                  }]  :  readonly ?
                    item.mouRoutesMultiLoc :
                    item.mouRoutesMultiLoc ? [ ...item.mouRoutesMultiLoc , {
                      setNewPickDrop: true
                    }] :
                    !item.mouRoutesMultiLoc && !readonly ? [{
                      setNewPickDrop: true
                    }] : []"
                  hide-default-footer
                  :items-per-page="100"
                  class="mt-2 mb-4 elevation-1 sub_header"
                >
                  <!-- LOCATION PICK DROP -->
                  <template v-slot:[`item.origin`]=items>
                    <common-auto-complete-items
                      v-if="items.item.setNewPickDrop"
                      :optional="{
                        mouId,
                        mouBy,
                        isDelete: false
                      }"
                      type="mouLocation"
                      class="body-2 pt-2"
                      searchName="locationName"
                      item-value="locationId"
                      item-text="locationName"
                      dense
                      outlined
                      hide-details
                      clearable
                      v-model="formMouRoutesMultiLocFcl.locationId"
                      :filter="pageFiltersLocation"
                      :items="itemsLocationMouRoute"
                      @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
                    />
                    <v-tooltip v-else bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                          class="blue--text"
                        >
                          {{itemsLocationMouRoute.find((location) => location.locationId === items.item.locationId).locationName}}
                        </span>
                      </template>
                      <span>
                        Lokasi {{ items.item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }}
                      </span>
                    </v-tooltip>
                  </template>
                  <!-- TYPE LOCATION -->
                  <template v-slot:[`item.pickDrop`]=items>
                    <div v-if="items.item.setNewPickDrop" class="d-flex">
                      <v-checkbox
                        v-if="(item.priceMultiPick > 0 || (item.setNewData && fclForm.priceMultiPick > 0))"
                        @change="onChangeCheckBoxPickDrop($event, 'drop')"
                        v-model="checkBoxPickDrop.pick"
                        label="Multipick"
                      ></v-checkbox>
                      <v-checkbox
                        v-if="(item.priceMultiDrop > 0 || (item.setNewData && fclForm.priceMultiDrop > 0))"
                        label="Multidrop"
                        @change="onChangeCheckBoxPickDrop($event, 'pick')"
                        class="ml-2"
                        v-model="checkBoxPickDrop.drop"
                      ></v-checkbox>
                    </div>
                    <span v-else>
                      {{ items.item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }}
                    </span>
                  </template>
                  <!-- BUTTONS -->
                  <template v-slot:[`item.action`]="items" v-if="!readonly">
                    <div v-if="items.item.setNewPickDrop">
                      <v-btn @click="clearStateFormMouRoutesMultiLocFcl" icon color="red">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                      <v-btn @click="addPickDrop(item)" icon color="primary">
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                    </div>
                    <v-btn
                      v-else
                      class="mb-4"
                      icon
                      color="grey"
                      @click="removePickDrop(item, items.index, items.item.pickDrop)"
                    >
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:[`item.sequenceOrderNo`]={item}>
              <v-text-field
                dense
                outlined
                disabled
                class="body-2 pt-2"
                :value="item.sequenceOrderNo ? fclMouRouteSequence.find((seqRoute) => seqRoute[item.sequenceOrderNo])[item.sequenceOrderNo] : null"
              ></v-text-field>
            </template>
            <template v-slot:[`item.origin`]={item}>
              <v-tooltip v-if="readonly" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="blue--text"
                  >
                    {{item.originLocationName}}
                  </span>
                </template>
                <span>{{$_strings.order.ORIGIN}}</span>
              </v-tooltip>
              <common-auto-complete-items
                v-else-if="item.setNewData"
                :optional="{
                  mouId,
                  mouBy,
                  isDelete: false
                }"
                :type="getAndCheckTypeAutoCompleteLocation"
                class="body-2 pt-2"
                searchName="locationName"
                item-value="locationId"
                item-text="locationName"
                dense
                outlined
                clearable
                v-model="fclForm.originLocationId"
                :filter="pageFiltersLocation"
                :items="itemsLocationMouRoute"
                @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
              />
              <common-auto-complete-items
                v-else
                :optional="{
                  mouId,
                  mouBy,
                  isDelete: false
                }"
                :type="getAndCheckTypeAutoCompleteLocation"
                class="body-2 pt-2"
                searchName="locationName"
                item-value="locationId"
                item-text="locationName"
                dense
                outlined
                v-model="item.originLocationId"
                :filter="pageFiltersLocation"
                :items="itemsLocationMouRoute"
                @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
              />
            </template>
            <template v-slot:[`item.destination`]={item}>
              <v-tooltip v-if="readonly" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="blue--text"
                  >
                    {{item.destinationLocationName}}
                  </span>
                </template>
                <span>{{$_strings.order.DESTINATION}}</span>
              </v-tooltip>
              <common-auto-complete-items
                v-else-if="item.setNewData"
                :optional="{
                  mouId,
                  mouBy,
                  isDelete: false
                }"
                :type="getAndCheckTypeAutoCompleteLocation"
                class="body-2 pt-2"
                searchName="locationName"
                item-value="locationId"
                item-text="locationName"
                dense
                outlined
                clearable
                v-model="fclForm.destinationLocationId"
                :filter="pageFiltersLocation"
                :items="itemsLocationMouRoute"
                @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
              />
              <common-auto-complete-items
                v-else
                :optional="{
                  mouId,
                  mouBy,
                  isDelete: false
                }"
                :type="getAndCheckTypeAutoCompleteLocation"
                class="body-2 pt-2"
                searchName="locationName"
                item-value="locationId"
                item-text="locationName"
                dense
                outlined
                v-model="item.destinationLocationId"
                :filter="pageFiltersLocation"
                :items="itemsLocationMouRoute"
                @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
              />
            </template>
            <template v-slot:[`item.etaDays`]={item}>
              <div v-if="readonly" class="pa-0 ma-0">
                <span>
                  {{item.etaDays}}
                </span>
              </div>
              <v-text-field
                v-else-if="item.destinationLocationId"
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                v-model="item.etaDays"
                :rules="[($event) => rules.etaDays($event)]"
              ></v-text-field>
              <v-text-field
                v-else
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                v-model="fclForm.etaDays"
              ></v-text-field>
            </template>
            <template v-slot:[`item.price`]={item}>
              <p v-if="readonly">Rp. {{formatAsCurrency(item.price)}}</p>
              <v-text-field
                v-else-if="item.setNewData"
                dense
                outlined
                prefix="Rp"
                v-mask="{'alias': 'idr-currency', rightAlign: false}"
                v-model="price"
                class="body-2 pt-2"
              ></v-text-field>
              <common-text-field-currency
                v-else
                dense
                outlined
                prefix="Rp"
                class="body-2 pt-2"
                v-model="item.price"
              />
            </template>
            <template v-slot:[`item.isAutoAssign`]={item}>
              <p
                v-if="readonly"
                class="text-center"
              >
                {{item.isAutoAssign ? 'Ya' : 'Tidak'}}
              </p>
              <v-select
                v-else-if="item.setNewData"
                v-model="fclForm.isAutoAssign"
                dense
                class="body-2 pt-2"
                outlined
                :items="itemsAssign"
              ></v-select>
              <v-select
                v-else
                v-model="item.isAutoAssign"
                dense
                class="body-2 pt-2"
                outlined
                :items="itemsAssign"
              ></v-select>
            </template>
            <template v-slot:[`item.transporter`]={item}>
              <p
                v-if="readonly"
                dense
                class="body-2"
                outlined
              >
                {{item.transportTypeName}}
              </p>
              <common-auto-complete-items
                v-else-if="item.setNewData"
                :optional="{
                  mouId,
                  mouBy,
                  isDelete: false
                }"
                :type="getAndCheckTypeAutoCompleteTransportTypes"
                class="body-2 pt-2"
                searchName="name"
                item-value="transportTypeId"
                item-text="name"
                dense
                outlined
                v-model="fclForm.transportTypesId"
                :filter="pageFiltersTransportType"
                :items="itemsTransportTypesMouRoute"
                @updateItems="(newItems) => $emit('updateTransportTypesMouRoute', newItems)"
              />
              <common-auto-complete-items
                v-else
                :optional="{
                  mouId,
                  mouBy,
                  isDelete: false
                }"
                :type="getAndCheckTypeAutoCompleteTransportTypes"
                class="body-2 pt-2"
                searchName="name"
                item-value="transportTypeId"
                item-text="name"
                dense
                outlined
                v-model="item.transportTypesId"
                :filter="pageFiltersTransportType"
                :items="itemsTransportTypesMouRoute"
                @updateItems="(newItems) => $emit('updateTransportTypesMouRoute', newItems)"
              />
            </template>
            <template v-slot:[`item.priceMultiPick`]={item}>
              <p v-if="readonly">
                Rp. {{formatAsCurrency(item.priceMultiPick)}}
              </p>
              <v-text-field
                v-else-if="item.setNewData"
                dense
                outlined
                prefix="Rp"
                v-mask="{'alias': 'idr-currency', rightAlign: false}"
                v-model="priceMultiPick"
                class="body-2 pt-2"
              ></v-text-field>
              <common-text-field-currency
                v-else
                dense
                outlined
                prefix="Rp"
                class="body-2 pt-2"
                v-model="item.priceMultiPick"
              />
            </template>
            <template v-slot:[`item.priceMultiDrop`]={item}>
              <p v-if="readonly">Rp. {{formatAsCurrency(item.priceMultiDrop)}}</p>
              <v-text-field
                v-else-if="item.setNewData"
                dense
                outlined
                prefix="Rp"
                v-mask="{'alias': 'idr-currency', rightAlign: false}"
                v-model="priceMultiDrop"
                class="body-2 pt-2"
              ></v-text-field>
              <common-text-field-currency
                v-else
                dense
                outlined
                prefix="Rp"
                class="body-2 pt-2"
                v-model="item.priceMultiDrop"
              />
            </template>
            <template v-slot:[`item.isBackHauling`]={item}>
              <p v-if="readonly">{{item.isBackHauling ? 'Ya' : 'Tidak'}}</p>
              <v-checkbox
                v-else-if="item.setNewData"
                v-model="fclForm.isBackHauling"
                class="mt-0"
              ></v-checkbox>
              <v-checkbox
                v-else
                v-model="item.isBackHauling"
                class="mt-0"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.action`]="{item, index}">
              <div v-if="!item.setNewData">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :loading="item.deleting"
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteMouFclRoute(item, index)"
                      class="mb-4"
                      icon
                      color="grey"
                    >
                      <v-icon>mdi-minus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$_strings.common.DELETE}}</span>
                </v-tooltip>
                <div v-if="isShowCheckButton(item, index) && !item.deleting" class="d-inline">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="item.updating"
                        v-bind="attrs"
                        v-on="on"
                        class="mb-4"
                        icon
                        color="primary"
                        @click="updateMouFclRoute(item, index)"
                      >
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$_strings.common.UPDATE}}</span>
                  </v-tooltip>
                </div>
              </div>
              <div v-else>
                <v-btn
                  @click="clearStateFormFcl()"
                  icon
                  color="red"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <v-btn
                  :loading="item.isLoading"
                  icon
                  color="primary"
                  @click="createMouFclRoute(item)"
                >
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-form>
    <DialogChangeHistoryFcl
      :service="service"
      :mouId="mouId"
      :mouBy="mouBy"
      ref="dialogChangeHistoryFcl"
    />
  </v-container>
</template>

<script>
import axios from 'axios';
import DialogChangeHistoryFcl from '../../Dialog/ChangeHistoryFcl';

const formatNumber = Intl.NumberFormat('id-ID');
const { CancelToken } = axios;
const source = CancelToken.source();

export default {
  name: 'FCL-page',
  props: {
    mouBy: {
      type: String,
      default: '',
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouFclRoute: {
      type: Array,
      default: () => [],
    },
    itemsLocationMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsTransportTypesMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Array,
      default: () => [],
    },
    fclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    checkboxServiceType: {
      type: Object,
      default: () => {},
    },
    pageFiltersLocation: {
      type: Object,
      default: () => {},
    },
    pageFiltersTransportType: {
      type: Object,
      default: () => {},
    },
    reFetchData: {
      type: Number,
      default: 0,
    },
    totalFclRouteData: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    getServices: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    DialogChangeHistoryFcl,
  },
  data() {
    return {
      rules: {
        numberOnlyRules: [(v) => /^(0|[1-9]\d*)(\.\d+)?$/.test(v)],
        etaDays: (value) => {
          if (!value) {
            return 'Eta wajib diisi';
          }
          if (+value === 0) {
            return 'Eta harus lebih dari 0';
          }
          return true;
        },
        price: (value, title) => {
          if (!value) {
            return `${title} wajib diisi`;
          }
          if (+value === 0) {
            return `${title} harus lebih dari 0`;
          }
          return true;
        },
      },
      openedTableId: '',
      expanded: [],
      itemsDestination: [],
      itemsInterval: ['Bulanan', 'Tahunan'],
      itemsAssign: [
        {
          text: 'Ya',
          value: true,
        },
        {
          text: 'Tidak',
          value: false,
        },
      ],
      fclForm: {
        serviceType: 'FCL',
        originLocationId: '',
        destinationLocationId: '',
        etaDays: 0,
        transportTypesId: '',
        isAutoAssign: false,
        price: 0,
        priceMultiPick: 0,
        priceMultiDrop: 0,
        isBackHauling: false,
        sequenceOrderNo: '',
        priceKilo: 0,
        priceVolume: 0,
        priceCarton: 0,
        mouRoutesMultiLoc: [],
      },
      checkBoxPickDrop: {
        pick: false,
        drop: false,
      },
      formMouRoutesMultiLocFcl: {
        sequenceNo: '',
        pickDrop: '',
        locationId: '',
      },
      headerTableFCL: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destination',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.order.ETA,
          value: 'etaDays',
          class: 'white--text primary text-capitalize',
          width: '120px',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Harga (Rp)',
          value: 'price',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '150px',
          sortable: false,
        },
        {
          text: 'A.A',
          value: 'isAutoAssign',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$_strings.mou.TRANSPORTER,
          value: 'transporter',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Multipick (Rp)',
          value: 'priceMultiPick',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Multidrop (Rp)',
          value: 'priceMultiDrop',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: this.$_strings.order.BACKHAULING,
          value: 'isBackHauling',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '120px',
          sortable: false,
        },
      ],
      subHeaderTableFCL: [
        {
          text: 'Nama Lokasi',
          value: 'origin',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: 'Multipick / Multidrop',
          value: 'pickDrop',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      setOldNewDataFclMouRoute: false,
      oldDataFclMouRoute: [],
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  computed: {
    showBtnChangeHistory() {
      if (!this.isPageInbox || !this.readonly) return false;
      if (this.findStatusAddOrDelete) return true;
      return this.isShowBtnChangeHistory;
    },
    findStatusAddOrDelete() {
      const findStatus = this.mouFclRoute.find((x) => x.status === 'DELETE' || x.status === 'ADD');
      return findStatus;
    },
    serverItemsLength() {
      if (!this.readonly && this.totalFclRouteData === 0) return 1;
      return this.totalFclRouteData;
    },
    price: {
      get() {
        return formatNumber.format(this.fclForm.price);
      },
      set(newValue) {
        this.fclForm.price = +(newValue.replaceAll('.', ''));
      },
    },
    priceMultiPick: {
      get() {
        return formatNumber.format(this.fclForm.priceMultiPick);
      },
      set(newValue) {
        this.fclForm.priceMultiPick = +(newValue.replaceAll('.', ''));
      },
    },
    priceMultiDrop: {
      get() {
        return formatNumber.format(this.fclForm.priceMultiDrop);
      },
      set(newValue) {
        this.fclForm.priceMultiDrop = +(newValue.replaceAll('.', ''));
      },
    },
    // AVOID RE-FETCHING
    getAndCheckTypeAutoCompleteLocation() {
      const { totalData, size, page } = this.pageFiltersLocation;
      if (page === 0) return 'mouLocation';
      if ((page * size) < totalData) return 'mouLocation';
      return null;
    },
    getAndCheckTypeAutoCompleteTransportTypes() {
      const { totalData, size, page } = this.pageFiltersTransportType;
      if (page === 0) return 'mouTransportType';
      if ((page * size) < totalData) return 'mouTransportType';
      return null;
    },
    displayedHeaderTableFcl() {
      const { isMultiPick, isMultiDrop, isBackhauling } = this.form;
      return this.headerTableFCL.filter((header) => {
        if (header.value === 'action' && this.readonly) return;
        if (header.value === 'priceMultiPick' && !isMultiPick) return;
        if (header.value === 'priceMultiDrop' && !isMultiDrop) return;
        if (header.value === 'isBackHauling' && !isBackhauling) return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        if (this.isLoading) return;
        this.oldDataFclMouRoute = [];
        this.setOldNewDataFclMouRoute = true;
        this.fetchData(newVal);
      },
      deep: true,
    },
    mouFclRoute: {
      handler(newVal) {
        const find = this.findStatusAddOrDelete;
        if (this.isPageInbox && this.readonly && !find && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
        if (!this.oldDataFclMouRoute.length && this.setOldNewDataFclMouRoute) {
          this.oldDataFclMouRoute = JSON.parse(JSON.stringify(newVal));
          this.setOldNewDataFclMouRoute = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    fetchData(pagination) {
      const isDelete = !this.isPageInbox || !this.readonly ? false : null;
      this.$emit('fetchMouFclRoute', pagination, isDelete, source);
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistoryFcl.dialog = true;
    },
    clearStateFormMouRoutesMultiLocFcl() {
      this.formMouRoutesMultiLocFcl = {
        sequenceNo: '',
        pickDrop: '',
        locationId: '',
      };
      this.checkBoxPickDrop = {
        pick: false,
        drop: false,
      };
    },
    clearStateFormFcl() {
      this.fclForm = {
        serviceType: 'FCL',
        originLocationId: '',
        destinationLocationId: '',
        transportTypesId: '',
        isAutoAssign: false,
        etaDays: 0,
        price: 0,
        priceMultiPick: 0,
        priceMultiDrop: 0,
        isBackHauling: false,
        sequenceOrderNo: '',
        priceKilo: 0,
        priceVolume: 0,
        priceCarton: 0,
        mouRoutesMultiLoc: [],
      };
    },
    showExpandPickDrop(item) {
      const condition1 = !item.setNewData && (+item.priceMultiPick !== 0 || +item.priceMultiDrop !== 0);
      const condition2 = !item.setNewData && (item.mouRoutesMultiLoc && item.mouRoutesMultiLoc.length);
      const condition3 = item.setNewData && (this.fclForm.priceMultiPick || this.fclForm.priceMultiDrop);
      return condition1 || condition2 || condition3;
    },
    formatAsCurrency(value) {
      return formatNumber.format(value) || 0;
    },
    onChangeCheckBoxPickDrop(val, type) {
      // SET CHECKBOX MULTIPICK CANNOT BE THE SAME CHECKBOX MULTIDROP
      if (val) {
        this.checkBoxPickDrop[type] = !val;
      }
    },
    onExpanded({ item, value }) {
      if (value) {
        this.openedTableId = item.sequenceOrderNo;
      }
    },
    getStyle(headerBy) {
      switch (headerBy) {
        case '':
          return 'min-width:80px;';
        default: return 'min-width:120px;';
      }
    },
    isShowCheckButton(item, index) {
      if (!this.oldDataFclMouRoute[index]) return;
      const oldData = JSON.stringify(this.oldDataFclMouRoute[index]).replace(/["]/g, '');
      const newData = JSON.stringify(item).replace(/["]/g, '');
      return oldData !== newData;
    },
    addPickDropNewForm() {
      const {
        locationId,
      } = this.formMouRoutesMultiLocFcl;
      const {
        pick,
        drop,
      } = this.checkBoxPickDrop;
      if (!locationId) {
        this.$dialog.notify.error('Lokasi belum dipilih');
        return;
      }
      if (!pick && !drop) {
        this.$dialog.notify.error('Pilih salah satu MultiPick atau MulitDrop');
        return;
      }
      const filterMouRoutes = this.fclForm.mouRoutesMultiLoc.filter((route) => route.pickDrop === (pick ? 'PICKUP' : 'DROP'));
      let lastIndexItem = [];
      if (filterMouRoutes) lastIndexItem = filterMouRoutes[filterMouRoutes.length - 1];
      if (lastIndexItem) {
        this.fclForm.mouRoutesMultiLoc.push({
          sequenceNo: +lastIndexItem.sequenceNo + 1,
          pickDrop: pick ? 'PICKUP' : 'DROP',
          locationId,
        });
      } else {
        this.fclForm.mouRoutesMultiLoc.push({
          sequenceNo: 1,
          pickDrop: pick ? 'PICKUP' : 'DROP',
          locationId,
        });
      }
      this.clearStateFormMouRoutesMultiLocFcl();
    },
    addPickDrop(item) {
      if (item.setNewData) {
        this.addPickDropNewForm();
        return;
      }
      const findIndexItem = this.mouFclRoute.indexOf(item);
      const {
        locationId,
      } = this.formMouRoutesMultiLocFcl;
      const {
        pick,
        drop,
      } = this.checkBoxPickDrop;
      if (!locationId) {
        this.$dialog.notify.error('Lokasi belum dipilih');
        return;
      }
      if (!pick && !drop) {
        this.$dialog.notify.error('Pilih salah satu MultiPick atau MulitDrop');
        return;
      }
      if (!item.mouRoutesMultiLoc) item.mouRoutesMultiLoc = [];
      const filterMouRoutes = item.mouRoutesMultiLoc.filter((route) => route.pickDrop === (pick ? 'PICKUP' : 'DROP'));
      let lastIndexItem = [];
      if (filterMouRoutes) lastIndexItem = filterMouRoutes[filterMouRoutes.length - 1];
      if (lastIndexItem) {
        item.mouRoutesMultiLoc.push({
          sequenceNo: +lastIndexItem.sequenceNo + 1,
          pickDrop: pick ? 'PICKUP' : 'DROP',
          locationId,
        });
      } else {
        item.mouRoutesMultiLoc.push({
          sequenceNo: 1,
          pickDrop: pick ? 'PICKUP' : 'DROP',
          locationId,
        });
      }
      this.mouFclRoute.splice(findIndexItem, 1, item);
      this.clearStateFormMouRoutesMultiLocFcl();
    },
    removePickDropNewForm(indexPickDrop, pickDrop) {
      this.fclForm.mouRoutesMultiLoc.splice(indexPickDrop, 1);
      // re ordered sequence PICK & DROP
      let newSequence = 0;
      this.fclForm.mouRoutesMultiLoc = this.fclForm.mouRoutesMultiLoc.map((route) => {
        if (route.pickDrop === pickDrop) {
          newSequence += 1;
          return {
            ...route,
            sequenceNo: newSequence,
          };
        }
        return route;
      });
    },
    removePickDrop(item, indexPickDrop, pickDrop) {
      if (item.setNewData) {
        this.removePickDropNewForm(indexPickDrop, pickDrop);
        return;
      }
      const findIndexItem = this.mouFclRoute.indexOf(item);
      item.mouRoutesMultiLoc.splice(indexPickDrop, 1);
      // re ordered sequence PICK & DROP
      let newSequence = 0;
      item.mouRoutesMultiLoc = item.mouRoutesMultiLoc.map((route) => {
        if (route.pickDrop === pickDrop) {
          newSequence += 1;
          return {
            ...route,
            sequenceNo: newSequence,
          };
        }
        return route;
      });
      this.mouFclRoute.splice(findIndexItem, 1, item);
    },
    resetTemporaryOldDataMouFclRoute() {
      this.oldDataFclMouRoute = [];
      this.setOldNewDataFclMouRoute = true;
    },
    async createMouFclRoute(item) {
      const {
        originLocationId,
        destinationLocationId,
        etaDays,
        price,
        transportTypesId,
        isBackHauling,
      } = this.fclForm;
      if (!originLocationId) {
        this.$dialog.notify.error('Lokasi asal belum dipilih');
        return;
      }
      if (!destinationLocationId) {
        this.$dialog.notify.error('Lokasi tujuan belum dipilih');
        return;
      }
      if (!etaDays || etaDays === 0) {
        this.$dialog.notify.error('Eta harus lebih dari 0');
        return;
      }
      if (!price) {
        this.$dialog.notify.error('Harga harus lebih dari 0');
        return;
      }
      if (!transportTypesId) {
        this.$dialog.notify.error('Kendaraan harus dipilih');
        return;
      }
      const serviceType = 'fcl';
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.mou.createMouRoute(this.mouBy, this.mouId, serviceType, this.fclForm);
        this.$dialog.notify.success('Data Berhasil ditambahkan');
        this.clearStateFormFcl();
        this.resetTemporaryOldDataMouFclRoute();
        this.fetchData(this.pagination);
        if (isBackHauling && !this.checkboxServiceType.isBackhauling) {
          this.checkboxServiceType.isBackhauling = true;
        }
        if (this.checkboxServiceType.isBackhauling) {
          this.$emit('fetchFclBackhauling');
        }
        this.expanded = [];
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
    async updateMouFclRoute(item, index) {
      const {
        isMultiPick,
        isMultiDrop,
      } = this.form;
      const { id: routeId, isBackHauling, mouRoutesMultiLoc } = item;
      if (!mouRoutesMultiLoc) item.mouRoutesMultiLoc = [];
      const serviceType = 'fcl';
      try {
        const data = { ...item };
        if (!isMultiPick) {
          data.priceMultiPick = 0;
          data.mouRoutesMultiLoc = data.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'PICKUP');
        }
        if (!isMultiDrop) {
          data.priceMultiDrop = 0;
          data.mouRoutesMultiLoc = data.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'DROP');
        }
        this.$set(item, 'updating', true);
        await this.$_services.mou.updateMouRoute(this.mouBy, this.mouId, routeId, serviceType, data);
        this.$delete(item, 'updating');
        if (!isMultiPick) {
          item.priceMultiPick = 0;
          item.mouRoutesMultiLoc = item.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'PICKUP');
        }
        if (!isMultiDrop) {
          item.priceMultiDrop = 0;
          item.mouRoutesMultiLoc = item.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'DROP');
        }
        const copyItem = JSON.parse(JSON.stringify(item));
        if (isBackHauling || (this.oldDataFclMouRoute[index].isBackHauling !== isBackHauling)) {
          this.checkboxServiceType.isBackhauling = true;
          this.$emit('fetchFclBackhauling');
        }
        this.oldDataFclMouRoute.splice(index, 1, copyItem);
        this.expanded = [];
        this.$dialog.notify.success('Data Berhasil diperbarui');
      } finally {
        this.$delete(item, 'updating');
      }
    },
    async deleteMouFclRoute(item, index) {
      const {
        id: routeId,
        originLocationId,
        destinationLocationId,
        isBackHauling,
      } = item;
      const { locationName: originName } = this.itemsLocationMouRoute.find((location) => location.locationId === originLocationId);
      const { locationName: destinationName } = this.itemsLocationMouRoute.find((location) => location.locationId === destinationLocationId);
      const userRes = await this.$dialog.warning({
        text: `Apakah Anda yakin akan menghapus rute FCL  ${originName} - ${destinationName}?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      });
      if (!userRes) return;
      try {
        this.$set(item, 'deleting', true);
        await this.$_services.mou.deleteMouRoute(this.mouBy, this.mouId, routeId);
        if (this.totalFclRouteData <= 10) {
          this.mouFclRoute.splice(index, 1);
        } else {
          this.fetchData(this.pagination);
        }
        if (isBackHauling) {
          this.$emit('fetchFclBackhauling');
        }
        this.$dialog.notify.success('Data Berhasil dihapus');
        this.expanded = [];
        this.resetTemporaryOldDataMouFclRoute();
      } finally {
        this.$delete(item, 'deleting');
      }
    },
    service(filter) {
      const typeService = 'mouRoute';
      const serviceType = 'FCL';
      return this.getServices(typeService)(filter, { mouBy: this.mouBy, mouId: this.mouId, serviceType }, source);
    },
    async checkDataChanged() {
      if (!this.isPageInbox || !this.readonly) return;
      const filter = {
        page: 0,
        size: 10,
        sort: 'status,asc',
      };
      filter.isDelete = false;
      let result = await this.service(filter);
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filter.isDelete = true;
        result = await this.service(filter);
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox || !this.readonly) return;
      if (item.status === 'DELETE') return 'red lighten-4';
      if (item.status === 'ADD') return 'green lighten-5';
    },
  },
};

</script>

<style lang="scss" scoped>
  ::v-deep {
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(odd) {
      background-color: #fcf6e5;
    }
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(even) {
      background-color: #fffcf5;
    }
    .sub_header {
      .v-data-table-header {
        tr {
          .amber.accent-3 {
            background-color: #f1c231 !important;
          }
        }
      }
    }
  }
  .tooltip-icon {
    height: 10px;
    width: 10px;
  }
</style>
