<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      <h4 class="grey--text">{{$_strings.mou.FILTER}}</h4>
    </v-col>
    <v-col cols="12">
      <v-row align="center">
        <v-col cols="12" sm="auto">
          <common-auto-complete-items
            :optional="{
              mouId,
              mouBy,
              isDelete: false
            }"
            :type="getAndCheckTypeAutoCompleteLocation"
            class="body-2"
            placeholder="Lokasi Asal"
            searchName="locationName"
            item-value="locationId"
            item-text="locationName"
            clearable
            dense
            outlined
            hide-details
            v-model="originId"
            :filter="pageFiltersLocation"
            :items="itemsLocationMouRoute"
            @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
          />
        </v-col>
        <v-col cols="12" sm="auto">
          <common-auto-complete-items
            :optional="{
              mouId,
              mouBy,
              isDelete: false
            }"
            :type="getAndCheckTypeAutoCompleteLocation"
            class="body-2"
            placeholder="Lokasi Tujuan"
            searchName="locationName"
            item-value="locationId"
            item-text="locationName"
            clearable
            dense
            outlined
            hide-details
            v-model="destinationId"
            :filter="pageFiltersLocation"
            :items="itemsLocationMouRoute"
            @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
          />
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn
            small
            color="grey"
            class="white--text"
            @click="setFilters"
          >
            {{$_strings.common.SEARCH}}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    mouBy: {
      type: String,
      default: '',
    },
    mouId: {
      type: Number,
      default: 0,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    itemsLocationMouRoute: {
      type: Array,
      default: () => [],
    },
    pageFiltersLocation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      originId: this.filters.originId || '',
      destinationId: this.filters.destinationId || '',
    };
  },
  computed: {
    getAndCheckTypeAutoCompleteLocation() {
      const { totalData, size, page } = this.pageFiltersLocation;
      if (page === 0) return 'mouLocation';
      if ((page * size) < totalData) return 'mouLocation';
      return null;
    },
  },
  methods: {
    setFilters() {
      this.filters.originId = this.originId;
      this.filters.destinationId = this.destinationId;
      this.$emit('fetchData');
    },
  },
};
</script>
