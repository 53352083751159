<template>
  <v-container fluid>
    <common-error
      v-if="errors.isErrorMou"
      @fetchData="$emit('fetchMouDetail')"
    />
    <section v-else-if="loaders.isLoadingMou">
      <v-row v-for="row in 2" :key="row+'row'">
        <v-col cols="12">
          <v-skeleton-loader
            type="heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col v-for="col in 9" :key="col+'col'" cols="4">
          <v-skeleton-loader
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <main v-else>
      <h4>{{$_strings.mou.PRICE_SETTING}}</h4>
      <!-- FIXED PRICE -->
      <section v-if="(readonly && form.isPriceFixed) || !readonly">
        <common-error
          v-if="errors.isErrorTransportTypeLimit"
          @fetchData="errors.isErrorTransportTypeLimit = false"
        />
        <section v-else>
          <v-checkbox
            :label="$_strings.mou.FIXED_PRICE"
            :disabled="!!mouTransportTypeLimit.length || readonly"
            v-model="form.isPriceFixed"
            class="w-30"
          ></v-checkbox>
          <FixedPrice
            v-if="form.isPriceFixed"
            :isPageInbox="isPageInbox"
            :getService1="getService1"
            :mouBy="mouBy"
            :mouId="mouId"
            :action="action"
            :isLoading="loaders.isLoadingMouTransportTypeLimit"
            :readonly="readonly"
            :form="form"
            :itemsTransportTypesMouRoute="itemsTransportTypesMouRoute"
            :itemsPerPage="itemsPerPage"
            :mouTransportTypeLimit="mouTransportTypeLimit"
            :totalTransportTypeLimitData="totalTransportTypeLimitData"
            :pageFilters="pageFilters.mouRouteTransportType"
            @fetchMouTransportTypeLimit="(pagination, isDelete) => $emit('fetchMouTransportTypeLimit', pagination, isDelete)"
            @updateTransportTypesMouRoute="(items) => $emit('updateTransportTypesMouRoute', items)"
          />
        </section>
      </section>
      <!-- FCL -->
      <section v-if="(readonly && checkboxServiceType.isFCL) || !readonly">
        <common-error
          v-if="errors.isErrorFclMouRoute"
          @fetchData="errors.isErrorFclMouRoute = false"
        />
        <section v-else>
          <v-checkbox
            :disabled="!!mouFclRoute.length || readonly"
            label="FCL"
            v-model="checkboxServiceType.isFCL"
            class="w-30"
          ></v-checkbox>
          <FilterData
            v-if="checkboxServiceType.isFCL"
            :filters="filterMouRoute.fcl"
            :mouBy="mouBy"
            :mouId="mouId"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            @fetchData="fetchFcl"
            @updateMouRouteLocationItems="(items) => $emit('updateMouRouteLocationItems', items)"
          />
          <FCL
            v-if="checkboxServiceType.isFCL"
            :checkboxServiceType="checkboxServiceType"
            :reFetchData="reFetchFcl"
            :isPageInbox="isPageInbox"
            :getServices="getServices"
            :mouBy="mouBy"
            :mouId="mouId"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :pageFiltersTransportType="pageFilters.mouRouteTransportType"
            :mouFclRoute="mouFclRoute"
            :totalFclRouteData="totalFclRouteData"
            :readonly="readonly"
            :form="form"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            :itemsTransportTypesMouRoute="itemsTransportTypesMouRoute"
            :isLoading="loaders.isLoadingFclMouRoute"
            :itemsPerPage="itemsPerPage"
            :fclMouRouteSequence="fclMouRouteSequence"
            @fetchFclBackhauling="fetchFclBackhauling"
            @fetchMouFclRoute="(pagination, isDelete, controller) => $emit('fetchMouFclRoute', pagination, isDelete, controller)"
            @fetchMouFclBackhaulingRoute="$emit('fetchMouFclBackhaulingRoute')"
            @updateTransportTypesMouRoute="(items) => $emit('updateTransportTypesMouRoute', items)"
            @updateMouRouteLocationItems="(items) => $emit('updateMouRouteLocationItems', items)"
          />
        </section>
      </section>
      <!-- Backhauling -->
      <section v-if="checkboxServiceType.isBackhauling">
        <common-error
          v-if="errors.isErrorFclBackhaulingMouRoute"
          @fetchData="errors.isErrorFclBackhaulingMouRoute = false"
        />
        <section v-else>
          <v-checkbox
            :label="$_strings.order.BACKHAULING"
            disabled
            v-model="checkboxServiceType.isBackhauling"
            class="w-30"
          ></v-checkbox>
          <FilterData
            v-if="checkboxServiceType.isFCL"
            :filters="filterMouRoute.fclBackhauling"
            :mouBy="mouBy"
            :mouId="mouId"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            @fetchData="fetchFclBackhauling"
            @updateMouRouteLocationItems="(items) => $emit('updateMouRouteLocationItems', items)"
          />
          <Backhauling
            v-if="checkboxServiceType.isBackhauling"
            :reFetchData="reFetchFclBackhauling"
            :mouBy="mouBy"
            :mouId="mouId"
            :isPageInbox="isPageInbox"
            :getServices="getServices"
            :mouFclBackhaulingRoute="mouFclBackhaulingRoute"
            :totalFclBackhaulingRouteData="totalFclBackhaulingRouteData"
            :readonly="readonly"
            :form="form"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            :itemsTransportTypesMouRoute="itemsTransportTypesMouRoute"
            :isLoading="loaders.isLoadingFclBackhaulingMouRoute"
            :itemsPerPage="itemsPerPage"
            :fclMouRouteSequence="fclMouRouteSequence"
            @fetchMouFclRoute="$emit('fetchMouFclRoute')"
            @fetchMouFclBackhaulingRoute="(pagination, isDelete) => $emit('fetchMouFclBackhaulingRoute', pagination, isDelete)"
            @updateMouRouteLocationItems="(items) => $emit('updateMouRouteLocationItems', items)"
          />
        </section>
      </section>
      <!-- LCL -->
      <section v-if="(readonly && checkboxServiceType.isLCL) || !readonly">
        <common-error
          v-if="errors.isErrorLclMouRoute"
          @fetchData="errors.isErrorLclMouRoute = false"
        />
        <section v-else>
          <v-checkbox
            :disabled="!!mouLclRoute.length || readonly"
            label="LCL"
            v-model="checkboxServiceType.isLCL"
            class="w-30"
          ></v-checkbox>
          <FilterData
            v-if="checkboxServiceType.isLCL"
            :filters="filterMouRoute.lcl"
            :mouBy="mouBy"
            :mouId="mouId"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            @fetchData="fetchLcl"
            @updateMouRouteLocationItems="(items) => $emit('updateMouRouteLocationItems', items)"
          />
          <LCL
            v-if="checkboxServiceType.isLCL"
            :reFetchData="reFetchLcl"
            :mouBy="mouBy"
            :mouId="mouId"
            :isPageInbox="isPageInbox"
            :getServices="getServices"
            :pageFilters="pageFilters.mouRouteLocation"
            :mouLclRoute="mouLclRoute"
            :totalLclRouteData="totalLclRouteData"
            :readonly="readonly"
            :form="form"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            :isLoading="loaders.isLoadingLclMouRoute"
            :itemsPerPage="itemsPerPage"
            :lclMouRouteSequence="lclMouRouteSequence"
            @fetchMouLclRoute="(pagination, isDelete) => $emit('fetchMouLclRoute', pagination, isDelete)"
            @updateMouRouteLocationItems="(items) => $emit('updateMouRouteLocationItems', items)"
          />
        </section>
      </section>
      <v-row class="mt-8 mb-8">
        <v-col cols="12" class="d-flex">
          <div>
            <v-btn
              outlined
              color="primary"
              @click="handleBackButton"
            >
              {{$_strings.common.BACK}}
            </v-btn>
          </div>
          <v-spacer></v-spacer>
          <div v-if="!readonly">
            <v-btn
              v-if="showDraftButton"
              color="grey"
              @click="$emit('submit','DRAFT')"
              class="no-text-transform mr-2"
            >
              <span class="text-none">
                {{$_strings.common.SAVE_AS_DRAFT}}
              </span>
            </v-btn>
            <v-btn
              color="primary"
              @click="$emit('submit','INACTIVE')"
            >
              {{$_strings.common.SUBMIT}}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </main>
  </v-container>
</template>

<script>
import FixedPrice from './FixedPrice';
import Backhauling from './Backhauling';
import LCL from './LCL';
import FCL from './FCL';
import FilterData from './FilterData.vue';

export default {
  name: 'price-list-page',
  components: {
    FixedPrice,
    FCL,
    Backhauling,
    LCL,
    FilterData,
  },
  props: {
    form: {
      typeof: Object,
      default: {},
    },
    loaders: {
      typeof: Object,
      default: {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
    filterMouRoute: {
      type: Object,
      default: () => {},
    },
    mouTransportTypeLimit: {
      type: Array,
      default: () => [],
    },
    mouLclRoute: {
      type: Array,
      default: () => [],
    },
    mouFclRoute: {
      type: Array,
      default: () => [],
    },
    mouFclBackhaulingRoute: {
      type: Array,
      default: () => [],
    },
    itemsTransporter: {
      type: Array,
      default: () => [],
    },
    mouBy: {
      type: String,
      default: '',
    },
    mouId: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: '',
    },
    itemsLocationMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsTransportTypesMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsFclServiceType: {
      type: Array,
      default: () => [],
    },
    itemsFclBackhaulingServiceType: {
      type: Array,
      default: () => [],
    },
    itemsLclServiceType: {
      type: Array,
      default: () => [],
    },
    fclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    lclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    checkboxServiceType: {
      type: Object,
      default: () => {},
    },
    reCreatedPriceListSetting: {
      type: Number,
      default: 0,
    },
    totalTransportTypeLimitData: {
      type: Number,
      default: 0,
    },
    totalLclRouteData: {
      type: Number,
      default: 0,
    },
    totalFclRouteData: {
      type: Number,
      default: 0,
    },
    totalFclBackhaulingRouteData: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    getServices: {
      type: Function,
      default: () => {},
    },
    getService1: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      reFetchLcl: 0,
      reFetchFcl: 0,
      reFetchFclBackhauling: 0,
    };
  },
  computed: {
    showDraftButton() {
      const actions = ['create', 'edit', 'addendum'];
      return actions.includes(this.action);
    },
    itemsPerPage() {
      if (this.readonly) return [5, 10, 25, 50, 100];
      return [5, 10, 25];
    },
  },
  watch: {
    'form.isMultiPick': function checkMultiPick() {
      this.fetchLcl();
    },
  },
  methods: {
    fetchLcl() {
      this.reFetchLcl += 1;
    },
    fetchFcl() {
      this.reFetchFcl += 1;
    },
    fetchFclBackhauling() {
      this.reFetchFclBackhauling += 1;
    },
    handleBackButton() {
      if (this.action === 'set-vehicle-number') {
        this.$router.go(-1);
        return;
      }
      this.$emit('changeStep', 1);
    },
  },
};

</script>
