<template>
  <v-container fluid>
    <common-error
      v-if="errors.isErrorMou"
      @fetchData="$emit('fetchMouDetail')"
    />
    <section v-else-if="loaders.isLoadingMou">
      <v-row v-for="skeleton in 10" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <v-form
      v-else
      ref="form"
      lazy-validation
      :disabled="readonly"
    >
      <v-row>
        <v-col>
          <h4>{{$_strings.mou.COOPERATION_INFORMATION}}</h4>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="companyId" class="body-2 ma-0" for="selectCompany">{{$_strings.mou.COMPANY_NAME_TABLE_HEADER_LABEL}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <common-auto-complete-items
            :type="typeGetCompanyItemsList"
            placeholder="Nama Perusahaan"
            dense
            outlined
            class="caption"
            clearable
            searchName="companyName"
            item-value="id"
            item-text="name"
            :disabled="action !== 'create' || readonly"
            v-model="form.companyId"
            :rules="rules.selectCompany"
            :filter="pageFilters.listCompanyItems"
            :items="itemsCompany"
            @updateItems="(items) => $emit('updateCompanyItems', items)"
            @change="onChangeCompany"
          />
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="companyAbbreviation" class="body-2 ma-0" for="textInitial">{{$_strings.mou.ALIAS_INITIAL_TABLE_HEADER_LABEL}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="textInitial"
            disabled
            class="body-2"
            v-model="form.companyAbbreviation"
            :rules="rules.aliasInitial"
            required
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="contractNo" class="body-2 ma-0" for="noContract">{{$_strings.mou.NO_CONTRACT}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="noContract"
            class="body-2"
            v-model="form.contractNo"
            placeholder="Autogenerate"
            required
            disabled
            dense
            outlined
            filled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="mouNo" class="body-2 ma-0" for="mouNumber">{{$_strings.mou.MOU_NUMBER_TABLE_HEADER_LABEL}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="mouNumber"
            class="body-2"
            v-model="form.mouNo"
            placeholder="Autogenerate"
            required
            disabled
            dense
            outlined
            filled
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="action === 'addendum'" class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="parentMouNo" class="body-2 ma-0" for="mouParentNumber">{{$_strings.mou.MOU_PARENT_NUMBER_TABLE_HEADER_LABEL}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="mouParentNumber"
            class="body-2"
            :value="form.parentMouNo || '-'"
            required
            disabled
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_periodicCooperation" class="body-2 ma-0" for="periodicCooperation">{{$_strings.mou.PERIODIC_COOPERATION}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            ref="menuFromDate"
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="periodicCooperation"
                dense
                outlined
                :value="form.mouPeriodStart ? dateFormat(form.mouPeriodStart) : ''"
                :rules="rules.fromDate"
                class="body-2"
                placeholder="Dari tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.mouPeriodStart"
              no-title
              :min="dayjs().format('YYYY-MM-DD')"
              :max="form.mouPeriodEnd ? dayjs(form.mouPeriodEnd).format('YYYY-MM-DD') : ''"
              scrollable
              :disabled="readonly"
              @change="menuFromDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="auto" class="pt-0">
          <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </span>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            ref="menuToDate"
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                :value="form.mouPeriodEnd ? dateFormat(form.mouPeriodEnd) : ''"
                :rules="rules.toDate"
                class="body-2"
                placeholder="Sampai tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.mouPeriodEnd"
              no-title
              :min="form.mouPeriodStart ? dayjs(form.mouPeriodStart).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')"
              scrollable
              locale="id"
              @change="menuToDate = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col v-if="isShowMouExtendButton" cols="12" sm="auto" class="pt-1">
          <v-btn small @click="setDialogExtendContract(form)" color="primary" outlined>
            <span class="caption">{{$_strings.mou.EXTEND}}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_mouPeriodStartDoc" class="body-2 ma-0" for="periodicCooperation">{{$_strings.mou.PERIODE_START_DOC}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            ref="menuMouPeriodStartDoc"
            v-model="menuMouPeriodStartDoc"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                :value="form.mouPeriodStartDoc ? dateFormat(form.mouPeriodStartDoc) : ''"
                :rules="rules.mouPeriodStartDoc"
                class="body-2"
                placeholder="Dari tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.mouPeriodStartDoc"
              no-title
              scrollable
              locale="id"
              @change="menuMouPeriodStartDoc = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="auto" class="pt-0">
          <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </span>
        </v-col>
        <v-col cols="auto" sm="3" class="pt-0">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                outlined
                :value="form.mouPeriodEnd ? dateFormat(form.mouPeriodEnd) : ''"
                disabled
                class="body-2"
                placeholder="Sampai tanggal"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              disabled
              v-model="form.mouPeriodEnd"
              no-title
              scrollable
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="isAutoExtend" class="body-2 ma-0" for="autoExtendMonth">{{$_strings.mou.AUTO_EXTENDOR}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="auto" class="mt-0 pt-0">
          <v-radio-group @change="onChangeIsAutoExtend" class="mt-0 pa-0" row v-model="form.isAutoExtend">
            <v-radio
              :key="1"
              :value="true"
              label="Ya"
            ></v-radio>
            <v-radio
              :key="2"
              :value="false"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="pt-0">
          <v-select
            v-if="form.isAutoExtend"
            placeholder="Pilih Bulan"
            v-model="form.autoExtendMonth"
            :items="monthsItems"
            item-value="value"
            item-text="name"
            class="body-2"
            outlined
            dense
            :rules="rules.autoExtendMonth"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="termOfPayment" class="body-2 ma-0" for="termOfPayment">{{$_strings.mou.TERM_OF_PAYMENT}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <common-auto-complete-items
            type="listTopMou"
            dense
            outlined
            clearable
            class="caption"
            searchName="paramValue"
            item-value="top"
            item-text="top"
            v-model="form.termOfPayment"
            :rules="rules.termOfPayment"
            :filter="pageFilters.top"
            :items="itemsTop"
            @updateItems="(newItems) => $emit('updateTopItems', newItems)"
          />
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="paymentMethod" class="body-2 ma-0" for="paymentMethod">{{$_strings.mou.PAYMENT_METHOD}}
          </label>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-0 pt-0">
          <v-radio-group
            class="mt-0 pa-0"
            row
            v-model="radioButtonPaymentMethod"
            @change="onChangePaymentMethodVia"
          >
            <v-radio
              :value="1"
              label="Ya"
            ></v-radio>
            <v-radio
              :value="2"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            v-if="radioButtonPaymentMethod === 1"
            v-model="form.paymentMethod"
            id="paymentMethod"
            class="body-2"
            dense
            disabled
            outlined
          >
          </v-text-field>
          <v-text-field
            v-else
            id="paymentMethod"
            class="body-2"
            v-model="form.paymentMethod"
            dense
            outlined
            @input="upperCasePaymentMethodText($event)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_paymentPic" class="body-2 ma-0" for="paymentPic">{{$_strings.mou.PAYMENT_PIC}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-0 pt-0">
          <v-select
            v-model="form.paymentPicSalutation"
            :rules="rules.require"
            :items="picTitleItems"
            outlined
            class="body-2"
            dense
          ></v-select>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="paymentPic"
            v-model="form.paymentPic"
            :rules="rules.paymentPic"
            class="body-2"
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="paymentPicMsisdn" class="body-2 ma-0" for="paymentPicMsisdn">{{$_strings.mou.PAYMENT_PIC_NUMBER_PHONE}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <phone-number-format
            id="paymentPicMsisdn"
            v-model="form.paymentPicMsisdn"
            dense
            class="body-2"
            outlined
            :disabled="readonly"
            :required="readonly ? false : true"
          >
          </phone-number-format>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_operationalPic" class="body-2 ma-0" for="paymentOperational">{{$_strings.mou.OPERATIONAL_PIC}}
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-0 pt-0">
          <v-select
            v-model="form.operationalPicSalutation"
            :items="picTitleItems"
            :rules="rules.require"
            outlined
            class="body-2"
            dense
          ></v-select>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="paymentOperational"
            v-model="form.operationalPic"
            class="body-2"
            dense
            outlined
            :rules="rules.operationalPic"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="operationalPicMsisdn" class="body-2 ma-0" for="operationalPicMsisdn">No Telepon PIC Operasional
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <phone-number-format
            id="operationalPicMsisdn"
            v-model="form.operationalPicMsisdn"
            dense
            class="body-2"
            outlined
            :disabled="readonly"
            :rules="rules.operationalPicMsisdn"
            :required="readonly ? false : true"
          >
          </phone-number-format>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="operationalPicJobTitle" class="body-2 ma-0" for="operationalPicJobTitle">Posisi PIC Operasional
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="operationalPicJobTitle"
            v-model="form.operationalPicJobTitle"
            dense
            class="body-2"
            outlined
            :rules="rules.operationalPicJobTitle"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="isInsurance" class="body-2 ma-0" for="clientInsurance">{{$_strings.mou.CLIENTS_USING_INSURANCE}}
          </label>
        </v-col>
        <v-col cols="auto" class="mt-0 pt-0">
          <v-radio-group
            id="clientInsurance"
            class="mt-0 pa-0"
            row
            v-model="form.isInsurance"
            :rules="rules.isInsurance"
          >
            <v-radio
              :value="true"
              label="Ya"
            ></v-radio>
            <v-radio
              :value="false"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="isAuditedByKap" class="body-2 ma-0" for="isAuditedByKap">{{$_strings.mou.FINANCIAL_STATEMENTS_KAP}}
          </label>
        </v-col>
        <v-col cols="auto" class="mt-0 pt-0">
          <v-radio-group
            id="isAuditedByKap"
            class="mt-0 pa-0"
            row
            v-model="form.isAuditedByKap"
            :rules="rules.isAuditedByKap"
          >
            <v-radio
              :value="true"
              label="Ya"
            ></v-radio>
            <v-radio
              :value="false"
              label="Tidak"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="mouBy === 'shipper'" class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="averageShipment" class="body-2 ma-0" for="averageShipment">Rata-rata Nilai Barang Per Pengiriman
            <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            prefix="Rp"
            v-mask="{'alias': 'idr-currency',rightAlign: false}"
            id="averageShipment"
            dense
            outlined
            :rules="rules.averageShipment"
            :value="formatAsCurrency(form.averageShipment)"
            @input="($event) => currencyToNumber($event, 'averageShipment')"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="totalAsset" class="body-2 ma-0" for="totalAsset">{{$_strings.mou.TOTAL_ASSET}}</label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="totalAsset"
            v-mask="{'alias': 'idr-currency',rightAlign: false}"
            prefix="Rp"
            @input="($event) => currencyToNumber($event, 'totalAsset')"
            :value="formatAsCurrency(form.totalAsset)"
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="ventureCapital" class="body-2 ma-0" for="ventureCapital">{{$_strings.mou.STARTUP_CAPITAL}}</label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            prefix="Rp"
            id="ventureCapital"
            v-mask="{'alias': 'idr-currency',rightAlign: false}"
            @input="($event) => currencyToNumber($event, 'ventureCapital')"
            :value="formatAsCurrency(form.ventureCapital)"
            dense
            outlined>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="shareholder" class="body-2 ma-0" for="shareHolder">{{$_strings.mou.SHAREHOLDERS}}
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="shareHolder"
            v-model="form.shareholder"
            dense
            outlined
            :rules="rules.shareHolder"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="bankName" class="body-2 ma-0" for="bankName">{{$_strings.mou.BANK_NAME}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="bankName"
            v-model="form.bankName"
            dense
            outlined
            :rules="readonly ? [] : radioButtonPaymentMethod === 1 ? rules.bankName : []"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="bankBranch" class="body-2 ma-0" for="bankBranch">{{$_strings.mou.DISTRIBUTARY}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="bankBranch"
            v-model="form.bankBranch"
            dense
            outlined
            :rules="readonly ? [] : radioButtonPaymentMethod === 1 ? rules.bankBranch : []"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_bankAccountName" class="body-2 ma-0" for="accountOwner">{{$_strings.mou.ACCOUNT_OWNER}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-0 pt-0">
          <v-select
            v-model="form.bankAccountNameSalutation"
            outlined
            class="body-2"
            dense
            :items="accountOwnerItems"
            :rules="readonly ? [] : radioButtonPaymentMethod === 1 ? rules.require : []"
          ></v-select>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="bankAccountName"
            v-model="form.bankAccountName"
            class="body-2"
            dense
            outlined
            :rules="readonly ? [] : radioButtonPaymentMethod === 1 ? rules.bankAccountName : []"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="bankAccountNo" class="body-2 ma-0" for="accountNumber">{{$_strings.mou.ACCOUNT_NUMBER}}
            <v-icon v-if="radioButtonPaymentMethod === 1" color="red" class="icon_important">mdi-asterisk</v-icon>
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-text-field
            id="accountNumber"
            v-mask="{'mask': '9', 'repeat': 30, allowMinus: false, rightAlign: false}"
            v-model="form.bankAccountNo"
            dense
            outlined
            :rules="readonly ? [] : radioButtonPaymentMethod === 1 ? rules.bankAccountNo : []"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" cols="12" sm="3">
          <label ref="_typeOfService" class="body-2 ma-0" for="typeOfService">Service Types
          </label>
        </v-col>
        <v-col class="pt-0">
          <v-checkbox
            class="ma-0"
            label="Intergrated Service"
            color="primary"
            v-model="form.isIntegratedService"
            hide-details
          ></v-checkbox>
          <v-checkbox
            label="Transportation"
            color="primary"
            v-model="form.isTransportation"
            hide-details
          ></v-checkbox>
          <div class="pl-8" v-if="form.isTransportation">
            <v-checkbox
              v-model="form.isMultiPick"
              label="Multipick"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.isMultiDrop"
              label="Multidrop"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.isBackhauling"
              label="Backhauling"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              :disabled="readonly"
              v-model="form.isMultiModa"
              label="Multimoda"
              color="primary"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="form.isMultiShipper"
              label="Multishipper"
              color="primary"
              hide-details
            ></v-checkbox>
          </div>
          <v-checkbox
            label="Warehousing"
            color="primary"
            v-model="form.isWarehousing"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <!-- P2P_LANDING -->
      <v-row v-if="mouBy === 'transporter' || form.p2pLending.length > 0">
        <v-col class="pt-1" cols="12" sm="3">
          <label ref="_p2pLending" class="body-2" for="isP2pLending">{{$_strings.mou.P2P_LENDING}}
          </label>
        </v-col>
        <v-col cols="auto" class="pt-0">
          <v-checkbox
            class="mt-0"
            v-model="form.isP2pLending" />
        </v-col>
        <v-col v-if="form.isP2pLending" cols="12" sm="8" class="pl-0">
          <v-data-table
            :headers="displayedHeaderP2PLanding"
            :items="readonly ? form.p2pLending : [ ...form.p2pLending, {
              top: '',
              adminRate: '',
              interestRate: '',
            }]"
            item-key="id"
            hide-default-footer
          >
            <template v-slot:[`item.top`]=items>
              <span v-if="items.item.top">{{items.item.top}} Hari</span>
              <common-auto-complete-items
                v-else
                type="listTopMou"
                dense
                outlined
                clearable
                class="caption"
                searchName="paramValue"
                item-value="top"
                item-text="top"
                v-model="initP2PLanding.top"
                :filter="pageFilters.top"
                :items="itemsTop"
                @updateItems="(newItems) => $emit('updateTopItems', newItems)"
              />
            </template>
            <template v-slot:[`item.adminRate`]=items>
              <span v-if="items.item.top">{{replaceDotToComma(items.item.adminRate)}} %</span>
              <v-text-field
                suffix="%"
                v-model="initP2PLanding.adminRate"
                v-mask="{'alias': 'custom-decimal', rightAlign: false}"
                outlined
                v-else
                dense
                class="body-2 pt-2">
              </v-text-field>
            </template>
            <template v-slot:[`item.interestRate`]=items>
              <span v-if="items.item.top">{{replaceDotToComma(items.item.interestRate)}} %</span>
              <v-text-field
                suffix="%"
                v-model="initP2PLanding.interestRate"
                v-mask="{'alias': 'custom-decimal', rightAlign: false}"
                outlined
                v-else
                dense
                class="body-2 pt-2">
              </v-text-field>
            </template>
            <template v-slot:[`item.action`]="items">
              <v-btn @click="removeP2PLanding(items.index)" class="mb-4" v-if="items.item.top" icon color="grey">
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
              <div v-else>
                <v-btn @click="clearP2PLanding()" icon color="red">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <v-btn @click="pushP2PLanding()" icon color="primary">
                  <v-icon>mdi-check-circle</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            outlined
            color="primary"
            class="mr-4"
            @click="$router.go(-1)"
          >
            {{$_strings.common.BACK}}
          </v-btn>
          <v-btn
            color="primary"
            class="mr-4"
            @click="navigateToFormLocationAndTransporter"
            :loading="isLoadingSaveDetailMouInfomation"
          >
            {{$_strings.common.NEXT}}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <DialogExtendContract
      ref="dialogExtendContract"
    />
    <DialogChangeHistory
      :form="form"
      :formMouPrevVersion="formMouPrevVersion"
      ref="dialogChangeHistory"
    />
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat } from '@/helper/commonHelpers';
import DialogExtendContract from '../Dialog/ExtendContract';
import DialogChangeHistory from '../Dialog/ChangeHistoryCooperationInformation';

export default {
  name: 'cooperation-information',
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    workflowId: {
      type: Number,
      default: 0,
    },
    workflowDetailId: {
      type: Number,
      default: 0,
    },
    loaders: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
    mouBy: {
      type: String,
      default: '',
    },
    mouId: {
      type: Number,
      default: 0,
    },
    typeGetCompanyItemsList: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    actionType: {
      type: String,
      default: '',
    },
    isShowMouExtendButton: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    monthsItems: {
      type: Array,
      default: () => [],
    },
    itemsCompany: {
      type: Array,
      default: () => [],
    },
    itemsTop: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DialogExtendContract,
    DialogChangeHistory,
  },
  mounted() {
    this.handleBtnFromShowChangeHistory();
    if (this.readonly) {
      this.removeAllRulesForm();
      this.headersP2PLanding.pop();
    }
  },
  data() {
    return {
      showBottomButton: true,
      isLoadingSaveDetailMouInfomation: false,
      rules: {
        selectCompany: [(v) => !!v || `${this.$_strings.mou.COMPANY_NAME_TABLE_HEADER_LABEL} harus dipilih`],
        aliasInitial: [(v) => !!v || `${this.$_strings.mou.ALIAS_INITIAL_TABLE_HEADER_LABEL} harus diisi`],
        fromDate: [(v) => !!v || `${this.$_strings.mou.PERIODIC_COOPERATION} harus diisi`],
        mouPeriodStartDoc: [(v) => !!v || `${this.$_strings.mou.PERIODE_START_DOC} harus diisi`],
        toDate: [(v) => !!v || 'Sampai tanggal? harus diisi'],
        termOfPayment: [(v) => !!v || `${this.$_strings.mou.TERM_OF_PAYMENT} harus dipilih`],
        autoExtend: [(v) => !!v || `${this.$_strings.mou.AUTO_EXTENDOR} harus dipilih`],
        autoExtendMonth: [(v) => !!v || `${this.$_strings.mou.AUTO_EXTENDOR} harus dipilih`],
        paymentPic: [(v) => !!v || 'Nama Pic Pembayaran harus diisi'],
        paymentPicMsisdn: [(v) => !!v || 'No Tlp Pic Pembayaran harus diisi'],
        operationalPic: [(v) => !!v || 'Nama Pic Operasional harus diisi'],
        operationalPicMsisdn: [(v) => !!v || 'No Tlp Pic Operasional harus diisi'],
        averageShipment: [(v) => !!v || 'Average value per shipment harus diisi'],
        totalAsset: [(v) => !!v || 'Total aset harus diisi'],
        ventureCapital: [(v) => !!v || 'Modal Usaha harus diisi'],
        shareholder: [(v) => !!v || 'Pemegang Saham harus diisi'],
        bankName: [(v) => !!v || 'Nama Bank harus diisi'],
        bankBranch: [(v) => !!v || 'Cabang harus diisi'],
        require: [(v) => !!v || 'Wajib dipilih'],
        bankAccountName: [(v) => !!v || 'Nama Akun Bank harus diisi'],
        bankAccountNo: [(v) => !!v || 'Nomor Akun Bank harus diisi'],
        operationalPicJobTitle: [(v) => !!v || 'Posisi PIC harus diisi'],
      },
      // radioButtonPaymentMethod: this.form.paymentMethod === 'TRANSFER' ? 1 : 2,
      menuFromDate: false,
      menuToDate: false,
      menuMouPeriodStartDoc: false,
      fromDate: '',
      toDate: '',
      picTitleItems: ['Bpk', 'Ibu'],
      accountOwnerItems: ['Bpk', 'Ibu', 'PT', 'CV'],
      // temporary value p2pLending before to push
      initP2PLanding: {
        top: '',
        adminRate: '',
        interestRate: '',
      },
      headersP2PLanding: [
        {
          text: this.$_strings.mou.TOP,
          value: 'top',
          sortable: false,
        },
        {
          text: this.$_strings.mou.ADMIN_RATE,
          value: 'adminRate',
          sortable: false,
        },
        {
          text: this.$_strings.mou.INTEREST_RATE,
          value: 'interestRate',
          sortable: false,
        },
        {
          text: '',
          width: 120,
          value: 'action',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    formMouPrevVersion() {
      const _distinctions = this.form.distinctions || {};
      return {
        ...this.form,
        ..._distinctions,
      };
    },
    displayedHeaderP2PLanding() {
      return this.headersP2PLanding.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
    radioButtonPaymentMethod: {
      get() {
        const { paymentMethod } = this.form;
        if (paymentMethod === 'TRANSFER') return 1;
        return 2;
      },
      set() {},
    },
  },
  methods: {
    dayjs,
    dateFormat,
    showDialogChangeHistory(event, type) {
      this.$refs.dialogChangeHistory.type = type;
      this.$refs.dialogChangeHistory.dialog = true;
      event.preventDefault();
    },
    createBtnChangeHistory(type) {
      const div = document.createElement('div');
      const btn = document.createElement('button');
      btn.onclick = (event) => this.showDialogChangeHistory(event, type);
      btn.setAttribute('class', 'caption red--text pa-0 ma-0 v-btn v-btn--text theme--light v-size--default');
      const span = document.createElement('span');
      span.setAttribute('class', 'v-btn__content');
      span.textContent = 'Ada Perubahan';
      const i = document.createElement('i');
      i.setAttribute('aria-hidden', 'true');
      i.setAttribute('class', 'v-icon notranslate ml-4 mdi mdi-information-outline theme--light red--text');
      i.setAttribute('style', 'font-size: 15px;');
      btn.appendChild(span);
      btn.appendChild(i);
      div.appendChild(btn);
      return div;
    },
    handleBtnFromShowChangeHistory() {
      if (!this.isPageInbox || !this.readonly) return;
      // check _periodicCooperation
      if (dateFormat(this.form.mouPeriodStart)
        !== dateFormat(this.formMouPrevVersion.mouPeriodStart)
        || dateFormat(this.form.mouPeriodEnd)
        !== dateFormat(this.formMouPrevVersion.mouPeriodEnd)) {
        const btn = this.createBtnChangeHistory('_periodicCooperation');
        this.$refs._periodicCooperation.insertBefore(btn, this.$refs._periodicCooperation.nextSibling);
      }
      // check _mouPeriodStartDoc
      if (dateFormat(this.form.mouPeriodStartDoc)
        !== dateFormat(this.formMouPrevVersion.mouPeriodStartDoc)
        || dateFormat(this.form.mouPeriodEnd)
        !== dateFormat(this.formMouPrevVersion.mouPeriodEnd)) {
        const btn = this.createBtnChangeHistory('_mouPeriodStartDoc');
        this.$refs._mouPeriodStartDoc.insertBefore(btn, this.$refs._mouPeriodStartDoc.nextSibling);
      }
      // check _paymentPic
      if (this.form.paymentPicSalutation
        !== this.formMouPrevVersion.paymentPicSalutation
        || this.form.paymentPic
        !== this.formMouPrevVersion.paymentPic) {
        const btn = this.createBtnChangeHistory('_paymentPic');
        this.$refs._paymentPic.insertBefore(btn, this.$refs._paymentPic.nextSibling);
      }
      // check _operationalPic
      if (this.form.operationalPicSalutation
        !== this.formMouPrevVersion.operationalPicSalutation
        || this.form.operationalPic
        !== this.formMouPrevVersion.operationalPic) {
        const btn = this.createBtnChangeHistory('_operationalPic');
        this.$refs._operationalPic.insertBefore(btn, this.$refs._operationalPic.nextSibling);
      }
      // check _bankAccountName
      if (this.form.bankAccountNameSalutation
        !== this.formMouPrevVersion.bankAccountNameSalutation
        || this.form.bankAccountName
        !== this.formMouPrevVersion.bankAccountName) {
        const btn = this.createBtnChangeHistory('_bankAccountName');
        this.$refs._bankAccountName.insertBefore(btn, this.$refs._bankAccountName.nextSibling);
      }
      // check _typeOfService
      const obj1 = {
        isIntegratedService: this.form.isIntegratedService,
        isTransportation: this.form.isTransportation,
        isMultiPick: this.form.isMultiPick,
        isMultiDrop: this.form.isMultiDrop,
        isBackhauling: this.form.isBackhauling,
        isMultiModa: this.form.isMultiModa,
        isMultiShipper: this.form.isMultiShipper,
        isWarehousing: this.form.isWarehousing,
      };
      const obj2 = {
        isIntegratedService: this.formMouPrevVersion.isIntegratedService,
        isTransportation: this.formMouPrevVersion.isTransportation,
        isMultiPick: this.formMouPrevVersion.isMultiPick,
        isMultiDrop: this.formMouPrevVersion.isMultiDrop,
        isBackhauling: this.formMouPrevVersion.isBackhauling,
        isMultiModa: this.formMouPrevVersion.isMultiModa,
        isMultiShipper: this.formMouPrevVersion.isMultiShipper,
        isWarehousing: this.formMouPrevVersion.isWarehousing,
      };
      if (JSON.stringify(obj1) !== JSON.stringify(obj2)) {
        const btn = this.createBtnChangeHistory('_typeOfService');
        this.$refs._typeOfService.insertBefore(btn, this.$refs._typeOfService.nextSibling);
      }

      // _p2pLending
      if (this.form.p2pLending.length
      && this.form.isP2pLending
      && JSON.stringify(this.form.p2pLending)
      !== JSON.stringify(this.formMouPrevVersion.p2pLending)
      ) {
        const btn = this.createBtnChangeHistory('_p2pLending');
        if (this.$refs._p2pLending) this.$refs._p2pLending.insertBefore(btn, this.$refs._p2pLending.nextSibling);
      }

      Object.keys(this.formMouPrevVersion).forEach((key) => {
        if (this.formMouPrevVersion[key] !== this.form[key]) {
          if (this.$refs[key]) {
            const btn = this.createBtnChangeHistory(key);
            this.$refs[key].insertBefore(btn, this.$refs[key].nextSibling);
          }
        }
      });
    },
    upperCasePaymentMethodText(value) {
      this.form.paymentMethod = value.toUpperCase();
    },
    async onChangeCompany(val) {
      this.form.companyAbbreviation = this.itemsCompany.find((el) => el.id === val).abbreviation;
      try {
        this.$root.$loading.show();
        // check if already has a mou
        await this.$_services.mou.checkMou(val);
      } finally {
        this.$root.$loading.hide();
      }
    },
    removeAllRulesForm() {
      Object.keys(this.rules).forEach((rule) => {
        this.rules[rule] = [];
      });
    },
    setDialogExtendContract(form) {
      const isShipper = this.mouBy === 'shipper';
      const { mouId } = this.$route.params;
      const mouPeriodEnd = dayjs(form.mouPeriodEnd).format('YYYY-MM-DD');
      this.$refs.dialogExtendContract.dialog = true;
      this.$refs.dialogExtendContract.data.id = mouId;
      this.$refs.dialogExtendContract.isShipper = isShipper;
      this.$refs.dialogExtendContract.data.fromDate = mouPeriodEnd;
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    currencyToNumber(event, type) {
      const newValue = event.replace(/[A-Za-z]/g, 0).replace(/[^0-9]/g, '');
      this.form[type] = +newValue;
    },
    onChangePaymentMethodVia(val) {
      if (val === 1) {
        this.form.paymentMethod = 'TRANSFER';
      } else {
        this.form.paymentMethod = '';
      }
    },
    onChangeIsAutoExtend(val) {
      if (!val) {
        this.form.autoExtendMonth = '';
      }
    },
    removeP2PLanding(val) {
      this.form.p2pLending.splice(val, 1);
    },
    clearP2PLanding() {
      this.initP2PLanding = {
        top: '',
        adminRate: '',
        interestRate: '',
      };
    },
    replaceDotToComma(number) {
      const _number = this.twoDigitComma(number);
      const numberToString = _number.toString();
      return numberToString && numberToString.toString().indexOf('.') > -1 ? numberToString.toString().replace('.', ',') : numberToString;
    },
    twoDigitComma(number) {
      const splitNumber = String(number).split('.');
      let _number = number;
      if (splitNumber.length > 1) {
        if (splitNumber[1].length > 2) {
          _number = parseFloat(number).toFixed(2);
        }
      }
      return parseFloat(_number);
    },
    pushP2PLanding() {
      const { adminRate, interestRate, top } = this.initP2PLanding;
      if (this.form.p2pLending.find((p2p) => +p2p.top === +top)) {
        return this.$dialog.notify.error(`Data Top ${top} sudah ditambahkan!`);
      }
      const _adminRate = adminRate && adminRate.indexOf(',') > -1 ? adminRate.replace(',', '.') : adminRate;
      const _interestRate = interestRate && interestRate.indexOf(',') > -1 ? interestRate.replace(',', '.') : interestRate;

      if (adminRate && interestRate && top) {
        const data = {
          adminRate: this.twoDigitComma(_adminRate),
          interestRate: this.twoDigitComma(_interestRate),
          top,
        };
        this.form.p2pLending.push(data);
        this.clearP2PLanding();
      } else {
        this.$dialog.notify.error('Data Tidak Lengkap');
      }
    },
    async navigateToFormLocationAndTransporter() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        if (!this.$refs.form.validate()) {
          this.$nextTick(() => {
            const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
            this.$vuetify.goTo(el, { offset: 50 });
          });
        }
        return this.$dialog.notify.error('Mohon Periksa Kembali Kolom yang Anda Isi.');
      }
      if (!this.readonly) {
        this.saveDetailMouInformation();
        return;
      }
      const step = 1; // => LOCATION AND TRANSPORTER
      this.$emit('changeStep', step);
    },
    getService() {
      if (this.workflowId) {
        return this.$_services.mou.reviseMouInformatioin(this.mouBy, this.workflowId, this.workflowDetailId, this.form);
      }
      if (this.mouId) {
        if (this.action === 'addendum') {
          return this.$_services.mou.updateAddendumMouInformation(this.mouBy, this.mouId, this.form);
        }
      }
      return this.$_services.mou.saveMouInformation(this.mouBy, this.form);
    },
    async saveDetailMouInformation() {
      try {
        this.isLoadingSaveDetailMouInfomation = true;
        const result = await this.getService();
        this.$dialog.notify.success('Berhasil menyimpan');
        const newMouId = result.data;
        if (typeof newMouId === 'number' && (+newMouId !== this.mouId)) {
          if (!this.form.id) this.form.id = newMouId;
          this.$router.replace({
            query: {
              ...this.$route.query,
            },
            params: {
              ...this.$route.params,
              mouId: newMouId,
            },
          });
        }
        this.$emit('changeStep', 1);
      } finally {
        this.isLoadingSaveDetailMouInfomation = false;
      }
    },
  },
};
</script>
