var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0"},[(_vm.showBtnChangeHistory)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.reFetchData,staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeaderLCL,"items":_vm.readonly ? _vm.mouLclRoute : _vm.mouLclRoute.concat( [{
          setNewData: true
        }]),"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.itemsPerPage,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        },"item-class":_vm.itemRowBackground,"item-key":String(Math.random()),"server-items-length":_vm.serverItemsLength,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header.priceKilo",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(header.text))]),_c('span',[_c('v-checkbox',{staticClass:"d-inline",attrs:{"color":"white","hide-details":"","disabled":_vm.readonly},model:{value:(_vm.form.isPriceKilo),callback:function ($$v) {_vm.$set(_vm.form, "isPriceKilo", $$v)},expression:"form.isPriceKilo"}})],1)])]}},{key:"header.priceVolume",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(header.text))]),_c('span',[_c('v-checkbox',{staticClass:"d-inline",attrs:{"color":"white","disabled":_vm.readonly,"hide-details":""},model:{value:(_vm.form.isPriceVolume),callback:function ($$v) {_vm.$set(_vm.form, "isPriceVolume", $$v)},expression:"form.isPriceVolume"}})],1)])]}},{key:"header.priceCarton",fn:function(ref){
        var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(header.text))]),_c('span',[_c('v-checkbox',{staticClass:"d-inline",attrs:{"color":"white","disabled":_vm.readonly,"hide-details":""},model:{value:(_vm.form.isPriceCarton),callback:function ($$v) {_vm.$set(_vm.form, "isPriceCarton", $$v)},expression:"form.isPriceCarton"}})],1)])]}},{key:"item.sequenceOrderNo",fn:function(ref){
        var item = ref.item;
return [_c('v-text-field',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","disabled":"","value":item.sequenceOrderNo ? _vm.lclMouRouteSequence.find(function (seqRoute) { return seqRoute[item.sequenceOrderNo]; })[item.sequenceOrderNo]: null}})]}},{key:"item.origin",fn:function(ref){
        var item = ref.item;
return [(_vm.readonly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.originLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.ORIGIN))])]):(item.setNewData)?_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"optional":{
              mouId: _vm.mouId,
              mouBy: _vm.mouBy,
              isDelete: false
            },"type":_vm.getAndCheckTypeAutoCompleteLocation,"searchName":"locationName","item-value":"locationId","item-text":"locationName","dense":"","outlined":"","clearable":"","filter":_vm.pageFilters,"items":_vm.itemsLocationMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateMouRouteLocationItems', newItems); }},model:{value:(_vm.lclForm.originLocationId),callback:function ($$v) {_vm.$set(_vm.lclForm, "originLocationId", $$v)},expression:"lclForm.originLocationId"}}):_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"optional":{
              mouId: _vm.mouId,
              mouBy: _vm.mouBy,
              isDelete: false
            },"type":_vm.getAndCheckTypeAutoCompleteLocation,"searchName":"locationName","item-value":"locationId","item-text":"locationName","dense":"","outlined":"","filter":_vm.pageFilters,"items":_vm.itemsLocationMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateMouRouteLocationItems', newItems); }},model:{value:(item.originLocationId),callback:function ($$v) {_vm.$set(item, "originLocationId", $$v)},expression:"item.originLocationId"}})]}},{key:"item.destination",fn:function(ref){
            var item = ref.item;
return [(_vm.readonly)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.destinationLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.DESTINATION))])]):(item.setNewData)?_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"optional":{
              mouId: _vm.mouId,
              mouBy: _vm.mouBy,
              isDelete: false
            },"type":_vm.getAndCheckTypeAutoCompleteLocation,"searchName":"locationName","item-value":"locationId","item-text":"locationName","dense":"","outlined":"","clearable":"","filter":_vm.pageFilters,"items":_vm.itemsLocationMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateMouRouteLocationItems', newItems); }},model:{value:(_vm.lclForm.destinationLocationId),callback:function ($$v) {_vm.$set(_vm.lclForm, "destinationLocationId", $$v)},expression:"lclForm.destinationLocationId"}}):_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"optional":{
              mouId: _vm.mouId,
              mouBy: _vm.mouBy,
              isDelete: false
            },"type":_vm.getAndCheckTypeAutoCompleteLocation,"searchName":"locationName","item-value":"locationId","item-text":"locationName","dense":"","outlined":"","filter":_vm.pageFilters,"items":_vm.itemsLocationMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateMouRouteLocationItems', newItems); }},model:{value:(item.destinationLocationId),callback:function ($$v) {_vm.$set(item, "destinationLocationId", $$v)},expression:"item.destinationLocationId"}})]}},{key:"item.etaDays",fn:function(ref){
            var item = ref.item;
return [(_vm.readonly)?_c('div',{staticClass:"pa-0 ma-0"},[_c('span',[_vm._v(" "+_vm._s(item.etaDays)+" ")])]):(item.setNewData)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":""},model:{value:(_vm.lclForm.etaDays),callback:function ($$v) {_vm.$set(_vm.lclForm, "etaDays", $$v)},expression:"lclForm.etaDays"}}):_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'integer', allowMinus: false, rightAlign: false}),expression:"{'alias': 'integer', allowMinus: false, rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","rules":[function ($event) { return _vm.rules.etaDays($event); }]},model:{value:(item.etaDays),callback:function ($$v) {_vm.$set(item, "etaDays", $$v)},expression:"item.etaDays"}})]}},{key:"item.priceKilo",fn:function(ref){
            var item = ref.item;
return [(_vm.readonly)?_c('p',{staticClass:"text-center"},[_vm._v(" Rp. "+_vm._s(_vm.formatAsCurrency(item.priceKilo))+" ")]):(item.setNewData)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'idr-currency', rightAlign: false}),expression:"{'alias': 'idr-currency', rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":!!(_vm.lclForm.priceVolume || _vm.lclForm.priceCarton)},on:{"change":function () {
              _vm.lclForm.priceVolume = 0;
              _vm.lclForm.priceCarton = 0;
            }},model:{value:(_vm.priceKilo),callback:function ($$v) {_vm.priceKilo=$$v},expression:"priceKilo"}}):_c('common-text-field-currency',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":!!(item.priceVolume || item.priceCarton)},on:{"change":function () {
              item.priceVolume = 0;
              item.priceCarton = 0;
            }},model:{value:(item.priceKilo),callback:function ($$v) {_vm.$set(item, "priceKilo", $$v)},expression:"item.priceKilo"}})]}},{key:"item.priceVolume",fn:function(ref){
            var item = ref.item;
return [(_vm.readonly)?_c('p',{staticClass:"text-center"},[_vm._v(" Rp. "+_vm._s(_vm.formatAsCurrency(item.priceVolume))+" ")]):(item.setNewData)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'idr-currency', rightAlign: false}),expression:"{'alias': 'idr-currency', rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":!!(_vm.lclForm.priceCarton || _vm.lclForm.priceKilo)},on:{"change":function () {
              _vm.lclForm.priceKilo = 0;
              _vm.lclForm.priceCarton = 0;
            }},model:{value:(_vm.priceVolume),callback:function ($$v) {_vm.priceVolume=$$v},expression:"priceVolume"}}):_c('common-text-field-currency',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":!!(item.priceCarton || item.priceKilo)},on:{"change":function () {
              item.priceKilo = 0;
              item.priceCarton = 0;
            }},model:{value:(item.priceVolume),callback:function ($$v) {_vm.$set(item, "priceVolume", $$v)},expression:"item.priceVolume"}})]}},{key:"item.priceCarton",fn:function(ref){
            var item = ref.item;
return [(_vm.readonly)?_c('p',{staticClass:"text-center"},[_vm._v(" Rp. "+_vm._s(_vm.formatAsCurrency(item.priceCarton))+" ")]):(item.setNewData)?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:({'alias': 'idr-currency', rightAlign: false}),expression:"{'alias': 'idr-currency', rightAlign: false}"}],staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":!!(_vm.lclForm.priceVolume || _vm.lclForm.priceKilo)},on:{"change":function () {
              _vm.lclForm.priceKilo = 0;
              _vm.lclForm.priceVolume = 0;
            }},model:{value:(_vm.priceCarton),callback:function ($$v) {_vm.priceCarton=$$v},expression:"priceCarton"}}):_c('common-text-field-currency',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":!!(item.priceVolume || item.priceKilo)},on:{"change":function () {
              item.priceKilo = 0;
              item.priceVolume = 0;
            }},model:{value:(item.priceCarton),callback:function ($$v) {_vm.$set(item, "priceCarton", $$v)},expression:"item.priceCarton"}})]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(!item.setNewData)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"loading":item.deleting,"icon":"","color":"grey"},on:{"click":function($event){return _vm.deleteMouRoute(item,index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]),(_vm.isShowCheckButton(item, index) && !item.deleting)?_c('div',{staticClass:"d-inline"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"loading":item.updating,"icon":"","color":"primary"},on:{"click":function($event){return _vm.updateMouRoute(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.UPDATE))])])],1):_vm._e()],1):_c('div',[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":_vm.clearStateLclForm}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_c('v-btn',{attrs:{"loading":item.isLoading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.createMouRoute(item)}}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1)],1)]}}],null,true)})],1)],1),_c('DialogChangeHistoryLcl',{ref:"dialogChangeHistoryLcl",attrs:{"form":_vm.form,"service":_vm.service,"mouBy":_vm.mouBy,"mouId":_vm.mouId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }