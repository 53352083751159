<template>
  <v-container fluid class="pa-0">
    <span>{{$_strings.mou.TRANSPORT_TYPE}}</span>
    <div v-if="showBtnChangeHistory">
      <v-btn
        color="red"
        class="pa-0 caption"
        text
        @click="showDialogChangeHistory"
      >
        {{$_strings.mou.THERE_ARE_CHANGES}}
        <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
      </v-btn>
    </div>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-data-table
          :loading="isLoading"
          :headers="displayedHeaderTransporter"
          :items="readonly ? mouTransportTypes : [ ...mouTransportTypes, {
            setNewData: true
          }]"
          :server-items-length="serverItemsLength"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          item-key="id"
          :item-class="itemRowBackground"
          class="elevation-1"
        >
          <template v-slot:[`item.name`]={item}>
            <common-auto-complete-items
              v-if="item.setNewData"
              type="transportTypes"
              searchName="name"
              item-value="id"
              item-text="name"
              v-model="transportTypesForm.id"
              placeholder="Masukkan Nama Kendaraan"
              dense
              outlined
              class="body-2 pt-2"
              :filter="pageFilters"
              :items="itemsTransportTypes"
              @change="changeTransportType"
              @updateItems="(items) => $emit('updateTransportTypesItems', items)"
            />
            <span v-else>{{item.name}}</span>
          </template>
          <template v-slot:[`item.description`]={item}>
            <v-text-field
              v-if="item.setNewData"
              dense
              disabled
              v-model="transportTypesForm.description"
              outlined
              class="body-2 pt-2"
              placeholder="Deskripsi"
            ></v-text-field>
            <span v-else>{{`${item.length} x ${item.width} x ${item.height}`}}</span>
          </template>
          <template v-slot:[`item.imageUrl`]={item}>
            <template v-if="item.setNewData"></template>
            <v-icon
              v-else-if="!item.imageUrl"
              x-large
              class="w3"
            >
              mdi-truck-outline
            </v-icon>
            <v-img
              v-else
              contain
              alt="Image Transporter"
              :src="item.imageUrl"
              :height="150"
              :width="150"
              aspect-ratio="1"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </template>
          <template v-slot:[`item.action`]="{item,index}">
            <v-btn
              v-if="item.transportTypeId"
              icon
              color="grey"
              :loading="item.deleting"
              @click="deleteMouTransportTypes(item,index)"
            >
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
            <div v-else>
              <v-btn :disabled="isLoadingAddTransportTypes" @click="resetForm" icon color="red">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
              <v-btn :loading="isLoadingAddTransportTypes" @click="createMouNewTransportTypes" icon color="primary">
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeHistoryLocationAndTransportTypes
      :mouId="mouId"
      :service="getService1"
      ref="dialogChangeHistory"
    />
  </v-container>
</template>

<script>
import DialogChangeHistoryLocationAndTransportTypes from '../../Dialog/ChangeHistoryLocationAndTransportTypes/index.vue';

export default {
  name: 'detail-mou-transport-types-page',
  components: {
    DialogChangeHistoryLocationAndTransportTypes,
  },
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    getService1: {
      type: Function,
      default: () => {},
    },
    form: {
      type: Object,
      default: () => {},
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouBy: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    itemsTransportTypes: {
      type: Array,
      default: () => [],
    },
    mouTransportTypes: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
    totalDataTransportTypes: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      transportTypesForm: {
        id: null,
        name: '',
        description: '',
        imageUrl: '',
      },
      headersTableTransportTypes: [
        {
          text: this.$_strings.mou.TRANSPORTER_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.DESCRIPTION,
          value: 'description',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.mou.IMAGE,
          value: 'imageUrl',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          width: 150,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      isLoadingAddTransportTypes: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.fetchData(newVal);
      },
      deep: true,
    },
    mouTransportTypes: {
      handler() {
        const find = this.findStatusAddOrDelete;
        if (this.isPageInbox && !this.readonly && !find && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
      },
      deep: true,
    },
  },
  computed: {
    findStatusAddOrDelete() {
      const findStatus = this.mouTransportTypes.find((x) => x.status === 'DELETE' || x.status === 'ADD');
      return findStatus;
    },
    showBtnChangeHistory() {
      if (!this.isPageInbox || !this.readonly) return false;
      if (this.findStatusAddOrDelete) return true;
      return this.isShowBtnChangeHistory;
    },
    serverItemsLength() {
      if (!this.readonly && this.totalDataTransportTypes === 0) return 1;
      return this.totalDataTransportTypes;
    },
    displayedHeaderTransporter() {
      return this.headersTableTransportTypes.filter((header) => {
        if (this.readonly && header.value === 'action') return;
        return header;
      });
    },
  },
  methods: {
    fetchData(pagination) {
      const isDelete = !this.isPageInbox || !this.readonly ? false : null;
      this.$emit('fetchMouTransportType', pagination, isDelete);
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistory.type = 'transportType';
      this.$refs.dialogChangeHistory.dialog = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox || !this.readonly) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    async checkDataChanged() {
      if (!this.isPageInbox || !this.readonly) return;
      const filter = {
        page: 0,
        size: 10,
        sort: 'status,asc',
      };
      filter.isDelete = false;
      const typeService = 'transportType';
      let result = await this.getService1(typeService, filter);
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filter.isDelete = true;
        result = await this.getService1(typeService, filter);
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    changeTransportType(val) {
      const transportType = this.itemsTransportTypes.find((type) => type.id === val);
      this.transportTypesForm = {
        id: val,
        name: transportType.name,
        description: `${transportType.length} x ${transportType.width} x ${transportType.height}`,
        imageUrl: transportType.imageUrl,
      };
    },
    resetForm() {
      this.transportTypesForm = {
        id: null,
        name: '',
        description: '',
        imageUrl: '',
      };
    },
    async createMouNewTransportTypes() {
      const { id: transportTypeId } = this.transportTypesForm;
      if (!transportTypeId) return this.$dialog.notify.error('Silahkan pilih kendaraan terlebih dahulu');
      try {
        this.isLoadingAddTransportTypes = true;
        await this.$_services.mou.createMouNewTrasportTypes(this.mouBy, this.mouId, transportTypeId);
        this.resetForm('transportTypesForm');
        this.fetchData(this.pagination);
        this.$emit('setIsCreatedPriceListSetting');
        this.$dialog.notify.success('Berhasil menambah tipe kendaraan');
      } finally {
        this.isLoadingAddTransportTypes = false;
      }
    },
    async deleteMouTransportTypes(item, index) {
      const userRes = await this.$dialog.warning({
        text: 'Apakah Anda yakin akan menghapus kendaraan ini? semua rute terkait dengan kendaraan tersebut akan dihapus',
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      });
      if (!userRes) return;
      const { transportTypeId, name } = item;
      try {
        this.$set(item, 'deleting', true);
        await this.$_services.mou.deleteMouTransportTypes(this.mouBy, this.mouId, transportTypeId);
        if (this.totalDataTransportTypes <= 10) {
          this.mouTransportTypes.splice(index, 1);
        } else {
          this.fetchData(this.pagination);
        }
        this.$emit('setIsCreatedPriceListSetting');
        this.$dialog.notify.success(`Berhasil menghapus kendaraan ${name}`);
      } finally {
        this.$delete(item, 'deleting');
      }
    },
  },
};

</script>
