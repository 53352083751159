<template>
  <v-container class="pa-0" fluid>
    <v-row class="mb-0">
      <v-col class="pb-0" v-if="showBtnChangeHistory" cols="12">
        <v-btn
          color="red"
          class="pa-0 caption"
          text
          @click="showDialogChangeHistory"
        >
          {{$_strings.mou.THERE_ARE_CHANGES}}
          <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          item-key="id"
          calculate-widths
          single-expand
          show-expand
          class="elevation-1"
          :loading="isLoading"
          :server-items-length="serverItemsLength"
          :options.sync="pagination"
          :key="reFetchData"
          :headers="displayedHeaderBackhauling"
          :items="mouFclBackhaulingRoute"
          :item-class="itemRowBackground"
          :expanded.sync="expanded"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`header.isAutoAssign`]="{ header }">
            <p class="ma-0">
              {{header.text}}
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    x-small
                    class="mb-4"
                    v-bind="attrs"
                    v-on="on"
                    color="white">
                    mdi-information
                  </v-icon>
                </template>
                <span>{{$_strings.mou.A_A}}</span>
              </v-tooltip>
            </p>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }">
            <v-icon
              v-if="showExpandPickDrop(item)"
              :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"
              @click.stop="expand(!isExpanded)"
            >
              $expand
            </v-icon>
          </template>
          <template v-slot:[`item.origin`]={item}>
            <v-tooltip v-if="item.originLocationId" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.originLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.ORIGIN}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.destination`]={item}>
            <v-tooltip v-if="item.destinationLocationId" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.destinationLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.DESTINATION}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.price`]={item}>
            <p v-if="readonly">
              Rp. {{formatAsCurrency(item.price)}}
            </p>
            <common-text-field-currency
              v-else
              dense
              outlined
              prefix="Rp"
              class="body-2 pt-2"
              v-model="item.price"
            />
          </template>
          <template v-slot:[`item.isAutoAssign`]={item}>
            <p>
              {{item.isAutoAssign ? 'Ya' : 'Tidak'}}
            </p>
          </template>
          <template v-slot:[`item.transportTypeId`]={item}>
            <p>
              {{item.transportTypeName}}
            </p>
          </template>
          <template v-slot:[`item.priceMultiPick`]={item}>
            <p v-if="readonly">
              Rp. {{formatAsCurrency(item.priceMultiPick)}}
            </p>
            <common-text-field-currency
              v-else
              dense
              outlined
              prefix="Rp"
              class="body-2 pt-2"
              v-model="item.priceMultiPick"
              :disabled="item.backHaulingType === 'ORIGIN'"
            />
          </template>
          <template v-slot:[`item.priceMultiDrop`]={item}>
            <p v-if="readonly">
              Rp. {{formatAsCurrency(item.priceMultiDrop)}}
            </p>
            <common-text-field-currency
              v-else
              :disabled="item.backHaulingType === 'ORIGIN' || readonly"
              dense
              outlined
              prefix="Rp"
              class="body-2 pt-2"
              v-model="item.priceMultiDrop"
            />
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="8">
              <v-data-table
                :headers="subHeaderTable"
                :items="readonly ?
                  item.mouRoutesMultiLoc : item.mouRoutesMultiLoc ? [ ...item.mouRoutesMultiLoc , {
                    setNewPickDrop: true
                  }] :
                    !item.mouRoutesMultiLoc && !readonly ? [{
                      setNewPickDrop: true
                    }] : []"
                :items-per-page="1000"
                hide-default-footer
                class="mt-2 mb-4 elevation-1 sub_header"
              >
                <template v-slot:[`item.origin`]=items>
                  <common-auto-complete-items
                    v-if="items.item.setNewPickDrop"
                    :optional="{
                      mouId,
                      mouBy,
                      isDelete: false
                    }"
                    type="mouLocation"
                    class="body-2 pt-2"
                    searchName="locationName"
                    item-value="locationId"
                    item-text="locationName"
                    dense
                    outlined
                    hide-details
                    clearable
                    v-model="formMouRoutesMultiLoc.locationId"
                    :filter="pageFiltersLocation"
                    :items="itemsLocationMouRoute"
                    @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
                  />
                  <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="blue--text"
                      >
                        {{itemsLocationMouRoute.find((location) => location.locationId === items.item.locationId).locationName}}
                      </span>
                    </template>
                    <span>Lokasi {{ items.item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }} </span>
                  </v-tooltip>
                </template>
                <!-- TYPE LOCATION -->
                <template v-slot:[`item.pickDrop`]=items>
                  <div v-if="items.item.setNewPickDrop" class="d-flex">
                    <v-checkbox
                      @change="onChangeCheckBoxPickDrop($event, 'drop')"
                      v-model="checkBoxPickDrop.pick"
                      label="Multipick"
                    ></v-checkbox>
                    <v-checkbox
                      label="Multidrop"
                      @change="onChangeCheckBoxPickDrop($event, 'pick')"
                      class="ml-2"
                      v-model="checkBoxPickDrop.drop"
                    ></v-checkbox>
                  </div>
                  <span v-else>
                    {{ items.item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop' }}
                  </span>
                </template>
                <!-- BUTTONS -->
                <template v-slot:[`item.action`]="items" v-if="!readonly">
                  <div v-if="items.item.setNewPickDrop">
                    <v-btn
                      icon
                      color="red"
                      @click="clearStateFormMouRoutesMultiLoc"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="primary"
                      @click="addPickDrop(item)"
                    >
                      <v-icon>mdi-check-circle</v-icon>
                    </v-btn>
                  </div>
                  <v-btn
                    v-else
                    class="mb-4"
                    icon
                    color="grey"
                    @click="removePickDrop(item, items.index, items.item.pickDrop)"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:[`item.action`]="{item, index}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteMouFclBackhaulingRoute(item, index)"
                  class="mb-4"
                  icon
                  color="grey"
                  :loading="item.deleting"
                >
                  <v-icon>mdi-minus-circle</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
            <div v-if="isShowCheckButton(item, index) && !item.deleting" class="d-inline">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="mb-4"
                    icon
                    color="primary"
                    @click="updateMouFclBackhaulingRoute(item, index)"
                    :loading="item.updating"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{$_strings.common.UPDATE}}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeHistoryFclBackhauling
      ref="dialogChangeHistory"
      :service="service"
      :mouBy="mouBy"
      :mouId="mouId"
    />
  </v-container>
</template>

<script>
import DialogChangeHistoryFclBackhauling from '../../Dialog/ChangeHistoryFclBackhauling';

export default {
  props: {
    mouBy: {
      type: String,
      default: '',
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouFclBackhaulingRoute: {
      type: Array,
      default: () => [],
    },
    itemsLocationMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsTransportTypesMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Array,
      default: () => [],
    },
    fclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    pageFiltersLocation: {
      type: Object,
      default: () => {},
    },
    reFetchData: {
      type: Number,
      default: 0,
    },
    totalFclBackhaulingRouteData: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    getServices: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    DialogChangeHistoryFclBackhauling,
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.oldDataFclBackhaulingMouRoute = [];
        this.setOldNewDataFclBackhaulingMouRoute = true;
        this.fetchData(newVal);
      },
      deep: true,
    },
    mouFclBackhaulingRoute: {
      handler(newVal) {
        const find = this.findStatusAddOrDelete;
        if (this.isPageInbox && this.readonly && !find && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
        if (!this.oldDataFclBackhaulingMouRoute.length && this.setOldNewDataFclBackhaulingMouRoute) {
          this.oldDataFclBackhaulingMouRoute = JSON.parse(JSON.stringify(newVal));
          this.setOldNewDataFclBackhaulingMouRoute = false;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      itemsInterval: ['Bulanan', 'Tahunan'],
      itemsAssign: [
        {
          text: 'Ya',
          value: true,
        },
        {
          text: 'Tidak',
          value: false,
        },
      ],
      headerTableBackhauling: [
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destination',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Harga (Rp)',
          value: 'price',
          width: '150px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'A.A',
          value: 'isAutoAssign',
          width: '100px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.mou.TRANSPORTER,
          value: 'transportTypeId',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Multipick (Rp)',
          value: 'priceMultiPick',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: 'Multidrop (Rp)',
          value: 'priceMultiDrop',
          width: '200px',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: this.$_strings.order.BACKHAULING,
          value: 'backHaulingType',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          width: '120px',
          sortable: false,
        },
        // POSTION ROW EXPAND
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      subHeaderTable: [
        {
          text: 'Nama Lokasi',
          value: 'origin',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: 'Multipick / Multidrop',
          value: 'pickDrop',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          class: 'black--text amber accent-3',
          sortable: false,
        },
      ],
      checkBoxPickDrop: {
        pick: false,
        drop: false,
      },
      expanded: [],
      formMouRoutesMultiLoc: {
        sequenceNo: '',
        pickDrop: '',
        locationId: '',
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      setOldNewDataFclBackhaulingMouRoute: false,
      oldDataFclBackhaulingMouRoute: [],
      rules: {
        price: (items) => true,
        priceMultiPick: (items) => true,
        priceMultiDrop: (items) => true,
      },
      validPrice: true,
      itemFalsePriceBackhauling: [],
      itemFalsePriceMultipick: [],
      itemFalsePriceMultidrop: [],
      tempPrice: null,
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  computed: {
    showBtnChangeHistory() {
      if (!this.isPageInbox || !this.readonly) return false;
      if (this.findStatusAddOrDelete) return true;
      return this.isShowBtnChangeHistory;
    },
    findStatusAddOrDelete() {
      const findStatus = this.mouFclBackhaulingRoute.find((x) => x.status === 'DELETE' || x.status === 'ADD');
      return findStatus;
    },
    serverItemsLength() {
      if (!this.readonly && this.totalFclBackhaulingRouteData === 0) return 1;
      return this.totalFclBackhaulingRouteData;
    },
    displayedHeaderBackhauling() {
      const { isMultiPick, isMultiDrop } = this.form;
      return this.headerTableBackhauling.filter((header) => {
        if (header.value === 'action' && this.readonly) return;
        if (header.value === 'priceMultiPick' && !isMultiPick) return;
        if (header.value === 'priceMultiDrop' && !isMultiDrop) return;
        return header;
      });
    },
  },
  methods: {
    fetchData(pagination) {
      const isDelete = !this.isPageInbox || !this.readonly ? false : null;
      this.$emit('fetchMouFclBackhaulingRoute', pagination, isDelete);
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistory.dialog = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox || !this.readonly) return;
      if (item.status === 'DELETE') return 'red lighten-4';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    service(filter) {
      const typeService = 'mouRoute';
      const serviceType = 'FCL_BACKHAULING';
      return this.getServices(typeService)(filter, { mouBy: this.mouBy, mouId: this.mouId, serviceType });
    },
    async checkDataChanged() {
      if (!this.isPageInbox || !this.readonly) return;
      const filter = {
        page: 0,
        size: 10,
        sort: 'status,asc',
      };
      filter.isDelete = false;
      let result = await this.service(filter);
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filter.isDelete = true;
        result = await this.service(filter);
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    getSequence(sequence, index) {
      const findSeqFcl = this.fclMouRouteSequence.find((seqRoute) => seqRoute[sequence]);
      let subSequence = 0;
      if (this.mouFclBackhaulingRoute[index - 1]) {
        if (this.mouFclBackhaulingRoute[index - 1].sequenceOrderNo === sequence) {
          subSequence = 1;
        }
      }
      if (this.mouFclBackhaulingRoute[index + 1]) {
        if (this.mouFclBackhaulingRoute[index + 1].sequenceOrderNo === sequence) {
          subSequence = 0;
        }
      }
      if (findSeqFcl) {
        return `${findSeqFcl[sequence]}.${subSequence}`;
      }
      return sequence;
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
    getStyle(headerBy) {
      switch (headerBy) {
        case '':
          return 'min-width:80px;';
        default: return 'min-width:120px;';
      }
    },
    onChangeCheckBoxPickDrop(val, type) {
      if (val) {
        this.checkBoxPickDrop[type] = !val;
      }
    },
    clearStateFormMouRoutesMultiLoc() {
      this.formMouRoutesMultiLoc = {
        sequenceNo: '',
        pickDrop: '',
        locationId: '',
      };
      this.checkBoxPickDrop = {
        pick: false,
        drop: false,
      };
    },
    showExpandPickDrop(item) {
      const condition1 = +item.priceMultiPick !== 0 || +item.priceMultiDrop !== 0;
      const condition2 = item.mouRoutesMultiLoc && item.mouRoutesMultiLoc.length;
      return condition1 || condition2;
    },
    isShowCheckButton(item, index) {
      if (!this.oldDataFclBackhaulingMouRoute[index]) return;
      const oldData = JSON.stringify(this.oldDataFclBackhaulingMouRoute[index]).replace(/["]/g, '');
      const newData = JSON.stringify(item).replace(/["]/g, '');
      return oldData !== newData;
    },
    addPickDrop(item) {
      const findIndexItem = this.mouFclBackhaulingRoute.indexOf(item);
      const {
        locationId,
      } = this.formMouRoutesMultiLoc;
      const {
        pick,
        drop,
      } = this.checkBoxPickDrop;
      if (!locationId) {
        this.$dialog.notify.error('Lokasi belum dipilih');
        return;
      }
      if (!pick && !drop) {
        this.$dialog.notify.error('Pilih salah satu MultiPick atau MultiDrop');
        return;
      }
      if (!item.mouRoutesMultiLoc) item.mouRoutesMultiLoc = [];
      const filterMouRoutes = item.mouRoutesMultiLoc.filter((route) => route.pickDrop === (pick ? 'PICKUP' : 'DROP'));
      let lastIndexItem = [];
      if (filterMouRoutes) lastIndexItem = filterMouRoutes[filterMouRoutes.length - 1];
      if (lastIndexItem) {
        item.mouRoutesMultiLoc.push({
          sequenceNo: +lastIndexItem.sequenceNo + 1,
          pickDrop: pick ? 'PICKUP' : 'DROP',
          locationId,
        });
      } else {
        item.mouRoutesMultiLoc.push({
          sequenceNo: 1,
          pickDrop: pick ? 'PICKUP' : 'DROP',
          locationId,
        });
      }
      this.mouFclBackhaulingRoute.splice(findIndexItem, 1, item);
      this.clearStateFormMouRoutesMultiLoc();
    },
    removePickDrop(item, indexPickDrop, pickDrop) {
      const findIndexItem = this.mouFclBackhaulingRoute.indexOf(item);
      item.mouRoutesMultiLoc.splice(indexPickDrop, 1);
      // re ordered sequence PICK & DROP
      let newSequence = 0;
      item.mouRoutesMultiLoc = item.mouRoutesMultiLoc.map((route) => {
        if (route.pickDrop === pickDrop) {
          newSequence += 1;
          return {
            ...route,
            sequenceNo: newSequence,
          };
        }
        return route;
      });
      this.mouFclBackhaulingRoute.splice(findIndexItem, 1, item);
    },
    resetTemporaryOldDataMouFclBackhaulingRoute() {
      this.oldDataFclBackhaulingMouRoute = [];
      this.setOldNewDataFclBackhaulingMouRoute = true;
    },
    async updateMouFclBackhaulingRoute(item, index) {
      const {
        isMultiPick,
        isMultiDrop,
      } = this.form;
      const { id: routeId, mouRoutesMultiLoc } = item;
      if (!mouRoutesMultiLoc) item.mouRoutesMultiLoc = [];
      const serviceType = 'fcl_backhauling';
      try {
        const data = { ...item };
        if (!isMultiPick) {
          data.priceMultiPick = 0;
          data.mouRoutesMultiLoc = data.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'PICKUP');
        }
        if (!isMultiDrop) {
          data.priceMultiDrop = 0;
          data.mouRoutesMultiLoc = data.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'DROP');
        }
        this.$set(item, 'updating', true);
        await this.$_services.mou.updateMouRoute(this.mouBy, this.mouId, routeId, serviceType, data);
        this.$delete(item, 'updating');
        if (!isMultiPick) {
          item.priceMultiPick = 0;
          item.mouRoutesMultiLoc = item.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'PICKUP');
        }
        if (!isMultiDrop) {
          item.priceMultiDrop = 0;
          item.mouRoutesMultiLoc = item.mouRoutesMultiLoc.filter((route) => route.pickDrop !== 'DROP');
        }
        const copyItem = JSON.parse(JSON.stringify(item));
        this.oldDataFclBackhaulingMouRoute.splice(index, 1, copyItem);
        this.$dialog.notify.success('Data Berhasil diperbarui');
        this.expanded = [];
      } finally {
        this.$delete(item, 'updating');
      }
    },
    async deleteMouFclBackhaulingRoute(item, index) {
      const { id: routeId, originLocationId, destinationLocationId } = item;
      const { locationName: originName } = this.itemsLocationMouRoute.find((location) => location.locationId === originLocationId);
      const { locationName: destinationName } = this.itemsLocationMouRoute.find((location) => location.locationId === destinationLocationId);
      const serviceType = 'fcl_backhauling';
      const userRes = await this.$dialog.warning({
        text: `Apakah Anda yakin akan menghapus rute FCL Backahauling  ${originName} - ${destinationName}?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      });
      if (!userRes) return;
      try {
        this.$set(item, 'deleting', true);
        await this.$_services.mou.deleteMouRoute(this.mouBy, this.mouId, routeId);
        if (this.totalFclBackhaulingRouteData <= 10) {
          this.mouFclBackhaulingRoute.splice(index, 1);
        } else {
          this.fetchData(this.pagination);
        }
        this.expanded = [];
        this.$dialog.notify.success('Data Berhasil dihapus');
        this.resetTemporaryOldDataMouFclBackhaulingRoute();
      } finally {
        this.$delete(item, 'deleting');
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  ::v-deep {
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(odd) {
      background-color: #fcf6e5;
    }
    .sub_header.theme--light.v-data-table tbody tr:nth-of-type(even) {
      background-color: #fffcf5;
    }
    .sub_header {
      .v-data-table-header {
        tr {
          .amber.accent-3 {
            background-color: #f1c231 !important;
          }
        }
      }
    }
  }
  .tooltip-icon {
    height: 10px;
    width: 10px;
  }
</style>
