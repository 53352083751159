<template>
  <v-container fluid>
    <v-tabs-items v-model="step">
      <v-tab-item>
        <cooperation-information
          :form="mouForm"
          :isPageInbox="isPageInbox"
          :action="action"
          :actionType="actionType"
          :itemsCompany="itemsCompany"
          :itemsTop="itemsTop"
          :monthsItems="monthsItems"
          :pageFilters="pageFilters"
          :mouBy="mouBy"
          :mouId="mouId"
          :workflowId="workflowId"
          :workflowDetailId="workflowDetailId"
          :typeGetCompanyItemsList="typeGetCompanyItemsList"
          :readonly="readonly"
          :isShowMouExtendButton="isShowMouExtendButton"
          :loaders="loaders"
          :errors="errors"
          @updateCompanyItems="updateCompanyItems"
          @fetchMouDetail="handleData"
          @updateTopItems="updateTopItems"
          @changeStep="changeStep"
        />
      </v-tab-item>
      <v-tab-item>
        <location-and-transport-types
          :isPageInbox="isPageInbox"
          :getService1="getService1"
          :pageFilters="pageFilters"
          :form="mouForm"
          :mouBy="mouBy"
          :mouId="mouId"
          :mouLocations="mouLocations"
          :mouTransportTypes="mouTransportTypes"
          :itemsLocation="itemsLocation"
          :itemsTransportTypes="itemsTransportTypes"
          :readonly="readonly"
          :loaders="loaders"
          :errors="errors"
          :totalDataLocation="totalDataLocation"
          :totalDataTransportTypes="totalDataTransportTypes"
          :step="step"
          :checkboxServiceType="checkboxServiceType"
          @counterReCreatedPriceListSetting="counterReCreatedPriceListSetting"
          @fetchMouLocation="fetchMouLocation"
          @fetchMouTransportType="fetchMouTransportType"
          @changeStep="changeStep"
          @updateLocationItems="updateLocationItems"
          @updateTransportTypesItems="updateTransportTypesItems"
        />
      </v-tab-item>
      <v-tab-item>
        <price-list-setting
          :isPageInbox="isPageInbox"
          :reCreatedPriceListSetting="reCreatedPriceListSetting"
          :action="action"
          :getServices="getServices"
          :getService1="getService1"
          :form="mouForm"
          :mouBy="mouBy"
          :mouId="mouId"
          :readonly="readonly"
          :loaders="loaders"
          :errors="errors"
          :itemsLocation="itemsLocation"
          :itemsTransportTypesMouRoute="itemsTransportTypesMouRoute"
          :itemsLocationMouRoute="itemsLocationMouRoute"
          :itemsFclServiceType="itemsFclServiceType"
          :itemsFclBackhaulingServiceType="itemsFclBackhaulingServiceType"
          :itemsLclServiceType="itemsLclServiceType"
          :mouTransportTypeLimit="mouTransportTypeLimit"
          :mouLclRoute="mouLclRoute"
          :mouFclRoute="mouFclRoute"
          :mouFclBackhaulingRoute="mouFclBackhaulingRoute"
          :totalTransportTypeLimitData="totalTransportTypeLimitData"
          :totalLclRouteData="totalLclRouteData"
          :totalFclRouteData="totalFclRouteData"
          :totalFclBackhaulingRouteData="totalFclBackhaulingRouteData"
          :checkboxServiceType="checkboxServiceType"
          :pageFilters="pageFilters"
          :filterMouRoute="filterMouRoute"
          :fclMouRouteSequence="fclMouRouteSequence"
          :lclMouRouteSequence="lclMouRouteSequence"
          @fetchMouDetail="fetchMouDetail"
          @fetchMouLclRoute="fetchMouLclRoute"
          @fetchMouFclRoute="fetchMouFclRoute"
          @fetchMouFclBackhaulingRoute="fetchMouFclBackhaulingRoute"
          @changeStep="changeStep"
          @fetchMouTransportTypeLimit="fetchMouTransportTypeLimit"
          @updateTransportTypesMouRoute="updateTransportTypesMouRoute"
          @updateMouRouteLocationItems="updateMouRouteLocationItems"
          @updateFclServiceTypeItems="updateFclServiceTypeItems"
          @updateFclBackhaulingServiceTypeItems="updateFclBackhaulingServiceTypeItems"
          @updateLclServiceTypeItems="updateLclServiceTypeItems"
          @submit="submit"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import dayjs from 'dayjs';
import { skipEmptyStringObject } from '@/helper/commonHelpers';
import CooperationInformation from './CooperationInformation.vue';
import LocationAndTransportTypes from './LocationAndTransportTypes';
import PriceListSetting from './PriceSetting/PriceListSetting.vue';

export default {
  name: 'mou-detail',
  props: {
    workflowId: {
      type: Number,
      default: 0,
    },
    workflowDetailId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CooperationInformation,
    LocationAndTransportTypes,
    PriceListSetting,
  },
  created() {
    const { data } = this.$route.params;
    if (data && this.defWorkflow) {
      const { status } = data;
      this.isRevise = status === 'REVISE' && this.fromPath === 'approval-inbox';
      this.setFormData(data.data);
    } else if (data) {
      this.setFormData(data);
    } else {
      this.handleData();
    }
  },
  data() {
    return {
      step: +this.$route.query.step || 0,
      reCreatedPriceListSetting: 0,
      mouForm: {
        id: null,
        activeStatus: 'INACTIVE',
        workflowId: null,
        companyId: null,
        companyName: '',
        companyAbbreviation: '',
        companyType: '',
        serviceTypes: '',
        mouNo: '',
        mouType: '',
        contractNo: null,
        parentMouId: null,
        parentMouNo: null,
        effectiveStartDate: '',
        effectiveEndDate: '',
        mouPeriodStart: '',
        mouPeriodEnd: '',
        isAutoExtend: false,
        autoExtendMonth: 12,
        termOfPayment: '',
        isP2pLending: false,
        paymentMethod: '',
        paymentPicSalutation: 'Bpk',
        paymentPic: '',
        paymentPicMsisdn: '',
        operationalPicSalutation: 'Bpk',
        operationalPic: '',
        operationalPicJobTitle: '',
        operationalPicMsisdn: '',
        isInsurance: false,
        isAuditedByKap: false,
        averageShipment: 0,
        totalAsset: 0,
        ventureCapital: 0,
        shareholder: '',
        bankName: '',
        bankBranch: '',
        bankAccountNameSalutation: 'Bpk',
        bankAccountName: '',
        bankAccountNo: '',
        isIntegratedService: false,
        isTransportation: false,
        isMultiPick: false,
        isMultiDrop: false,
        isBackhauling: false,
        isMultiModa: false,
        isMultiShipper: false,
        isWarehousing: false,
        otherServices: null,
        isPriceFixed: false,
        isPriceKilo: false,
        isPriceVolume: false,
        isPriceCarton: false,
        isPriceOnCall: false,
        approvalStatus: '',
        mouLocations: [],
        mouTransportTypes: [],
        mouTransportTypeLimit: [],
        p2pLending: [],
        createdBy: '',
        createdAt: null,
        updatedBy: '',
        updatedAt: null,
        mouDocument: null,
        mouPeriodStartDoc: null,
      },
      isShowMouExtendButton: false,
      // GLOBAL ITEMS
      monthsItems: [],
      itemsCompany: [],
      itemsTop: [],
      itemsLocation: [], // GET ALL LOCATION
      itemsTransportTypes: [], // GET ALL TRANSPORT
      itemsFclServiceType: [],
      itemsFclBackhaulingServiceType: [],
      itemsLclServiceType: [],
      // ITEMS FOR ROUTE
      itemsLocationMouRoute: [], // FOR ADD NEW MOU ROUTE
      itemsTransportTypesMouRoute: [], // FOR ADD NEW MOU TRANSPORT
      // MOU DATA
      mouLocations: [],
      mouTransportTypes: [],
      mouTransportTypeLimit: [],
      mouLclRoute: [],
      mouFclRoute: [],
      mouFclBackhaulingRoute: [],
      // ITEMS PAGINATION
      pageFilters: {
        listCompanyItems: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        top: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        locationFilterItems: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        transportTypesFilterItems: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        mouRouteLocation: {
          search: '',
          page: 0,
          size: 50,
          totalData: 0,
        },
        mouRouteTransportType: {
          search: '',
          page: 0,
          size: 50,
          totalData: 0,
        },
        fclServiceType: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        fclBackhaulingServiceType: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
        lclServiceType: {
          search: '',
          page: 0,
          size: 25,
          totalData: 0,
        },
      },
      // model
      checkboxServiceType: {
        isFCL: false,
        isBackhauling: false,
        isLCL: false,
      },
      // loaders
      loaders: {
        isLoadingMou: false,
        isLoadingMouLocation: false,
        isLoadingMouTransportTypes: false,
        isLoadingTransportTypeLimit: false,
        isLoadingMouTransportTypeLimit: false,
        isLoadingLclMouRoute: false,
        isLoadingFclMouRoute: false,
        isLoadingFclBackhaulingMouRoute: false,
      },
      // errors
      errors: {
        isErrorMou: false,
        isErrorMouLocation: false,
        isErrorMouTransportTypes: false,
        isErrorTransportTypeLimit: false,
        isErrorLclMouRoute: false,
        isErrorFclMouRoute: false,
        isErrorFclBackhaulingMouRoute: false,
      },
      // sequence
      fclMouRouteSequence: [],
      lclMouRouteSequence: [],
      totalDataLocation: 0,
      totalDataTransportTypes: 0,
      totalTransportTypeLimitData: 0,
      totalLclRouteData: 0,
      totalFclRouteData: 0,
      totalFclBackhaulingRouteData: 0,
      defaultFilters: {
        page: 1,
        itemsPerPage: 10,
      },
      filterMouRoute: {
        fcl: {
          originId: '',
          destinationId: '',
        },
        fclBackhauling: {
          originId: '',
          destinationId: '',
        },
        lcl: {
          originId: '',
          destinationId: '',
        },
      },
      isRevise: false,
    };
  },
  watch: {
    userAccess(newVal) {
      const { canCreate, canUpdate } = this.userAccess;
      if (this.action === 'create' && !canCreate) this.$router.go(-1);
      if (this.action === 'addendum' && !canUpdate) this.$router.go(-1);
      if (this.action === 'edit' && !canUpdate) this.$router.go(-1);
    },
  },
  computed: {
    isPageInbox() {
      return this.fromPath === 'approval-inbox';
    },
    fromPath() {
      // approval-inbox, approval-process, complete-approval
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    action() {
      return this.$route.params.action;// => create, view, edit, addendum
    },
    mouId() {
      const { mouId } = this.$route.params;
      return +mouId || +this.mouForm.id || 0;
    },
    defWorkflow() {
      const { defWorkflow } = this.$route.params;
      return +defWorkflow || 0;
    },
    mouBy() {
      if (this.defWorkflow) {
        return this.mouForm.companyType.toLowerCase();// => shipper, transporter
      }
      const [,,, mouBy] = this.$route.path.split('/');
      return mouBy; // => shipper, transporter
    },
    actionType() {
      const mouType = this.mouForm.mouType.toLowerCase();
      // DRAFT, CREATE NEW MOU, EDIT MOU => SHIPPER
      if (this.mouBy === 'shipper'
      && (this.action === 'create'
      || (mouType === 'mou' && this.action === 'edit'))) {
        return 'createNewMouShipper';
      }

      // DRAFT, CREATE NEW MOU, EDIT MOU => TRANSPORTER
      if (this.action === 'create'
      && (this.mouBy === 'transporter'
      || (mouType === 'mou' && this.action === 'edit'))) {
        return 'createNewMouTransporter';
      }

      // DRAFT, CREATE ADDENDUM, EDIT ADDENDUM => SHIPPER
      if (this.mouBy === 'shipper'
      && (this.action === 'addendum'
        || this.action === 'edit')) {
        return 'addendumShipper';
      }

      // DRAFT, CREATE ADDENDUM, EDIT ADDENDUM => TRANSPORTER
      if (this.mouBy === 'transporter'
      && (this.action === 'addendum'
        || this.action === 'edit')) {
        return 'addendumTransporter';
      }

      // REVISE MOU SHIPPER
      if (this.mouForm.companyType === 'SHIPPER') return 'reviseMouShipper';

      // REVISE MOU
      return 'reviseMouTransporter';
    },
    typeGetCompanyItemsList() {
      if (this.actionType === 'createNewMouShipper') return 'listShipperCompanyCanCreateMou';
      if (this.actionType === 'createNewMouTransporter') return 'listTransporterCompanyCanCreateMou';
      if (this.mouBy === 'shipper') return 'listShipperCompanyIsReadyMou';
      return 'listTransporterCompanyIsReadyMou';
    },
    readonly() {
      let isReadOnly = false;
      const readOnlyPath = ['view', 'set-vehicle-number'];
      if (readOnlyPath.includes(this.action) || !this.action) isReadOnly = true;
      if (this.isRevise) isReadOnly = false;
      return isReadOnly;
    },
  },
  methods: {
    skipEmptyStringObject,
    updateCompanyItems(newItems) {
      this.itemsCompany = [...this.itemsCompany, ...newItems];
    },
    handleData() {
      if (+this.$route.query.step > 2) {
        this.step = 0;
        this.$router.replace({
          query: {
            ...this.$route.query,
            step: 0,
          },
        });
      }
      this.setMonth();
      if (!this.mouId) return;
      if (this.defWorkflow) {
        this.fetchWorkFlowData();
      } else {
        this.fetchMouDetail();
      }
    },
    counterReCreatedPriceListSetting() {
      this.mouTransportTypeLimit = [];
      this.mouFclRoute = [];
      this.mouFclBackhaulingRoute = [];
      this.mouLclRoute = [];
      this.itemsLocationMouRoute = [];
      this.itemsTransportTypesMouRoute = [];
      this.reCreatedPriceListSetting += 1;
      this.pageFilters.mouRouteLocation = {
        search: '',
        page: 0,
        size: 50,
        totalData: 0,
      };
      this.pageFilters.mouRouteTransportType = {
        search: '',
        page: 0,
        size: 50,
        totalData: 0,
      };
    },
    updateTopItems(newItems) {
      this.itemsTop = [...this.itemsTop, ...newItems];
    },
    updateLocationItems(newItems) {
      this.itemsLocation = [...this.itemsLocation, ...newItems];
    },
    updateTransportTypesItems(newItems) {
      this.itemsTransportTypes = [...this.itemsTransportTypes, ...newItems];
    },
    updateTransportTypesMouRoute(newItems) {
      this.itemsTransportTypesMouRoute = [...this.itemsTransportTypesMouRoute, ...newItems];
    },
    updateFclServiceTypeItems(newItems) {
      const newLocation = [...this.itemsFclServiceType];
      newItems.forEach((newLocationData) => {
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
          newLocation.push({
            locationId: newLocationData.originLocationId,
            locationName: newLocationData.originLocationName,
          });
        }
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
          newLocation.push({
            locationId: newLocationData.destinationLocationId,
            locationName: newLocationData.destinationLocationName,
          });
        }
      });
      this.itemsFclServiceType = [...this.itemsFclServiceType, ...newLocation];
    },
    updateFclBackhaulingServiceTypeItems(newItems) {
      const newLocation = [...this.itemsFclBackhaulingServiceType];
      newItems.forEach((newLocationData) => {
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
          newLocation.push({
            locationId: newLocationData.originLocationId,
            locationName: newLocationData.originLocationName,
          });
        }
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
          newLocation.push({
            locationId: newLocationData.destinationLocationId,
            locationName: newLocationData.destinationLocationName,
          });
        }
      });
      this.itemsFclBackhaulingServiceType = [...this.itemsFclBackhaulingServiceType, ...newLocation];
    },
    updateLclServiceTypeItems(newItems) {
      const newLocation = [...this.itemsLclServiceType];
      newItems.forEach((newLocationData) => {
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
          newLocation.push({
            locationId: newLocationData.originLocationId,
            locationName: newLocationData.originLocationName,
          });
        }
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
          newLocation.push({
            locationId: newLocationData.destinationLocationId,
            locationName: newLocationData.destinationLocationName,
          });
        }
      });
      this.itemsLclServiceType = [...this.itemsLclServiceType, ...newLocation];
    },
    updateMouRouteLocationItems(items) {
      this.itemsLocationMouRoute = [...this.itemsLocationMouRoute, ...items];
    },
    changeStep(step) {
      this.step = step;
      this.$router.replace({
        query: {
          ...this.$route.query,
          step,
        },
      });
    },
    setMonth() {
      for (let month = 1; month <= 12; month += 1) {
        this.monthsItems.push({
          name: `Bulan ${month}`,
          value: month,
        });
      }
    },
    getServices(typeService) {
      if (this.mouBy === 'shipper') {
        switch (typeService) {
          case 'mouDetail': return this.$_services.mou.fetchDetailMouShipper;
          case 'location': return this.$_services.mou.getMouLocation;
          case 'transportType': return this.$_services.mou.getMouTransportType;
          case 'mouRoute': return this.$_services.mou.getMouRoute;
          case 'transportTypeLimit': return this.$_services.mou.getMouTransportTypeLimit;
          default: return null;
        }
      }
      if (this.mouBy === 'transporter') {
        switch (typeService) {
          case 'mouDetail': return this.$_services.mou.fetchDetailMouTransporter;
          case 'location': return this.$_services.mou.getMouLocation;
          case 'transportType': return this.$_services.mou.getMouTransportType;
          case 'mouRoute': return this.$_services.mou.getMouRoute;
          case 'transportTypeLimit': return this.$_services.mou.getMouTransportTypeLimit;
          default: return null;
        }
      }
      return null;
    },
    checkMouExtend(mouPeriodEnd) {
      if (this.action !== 'create') return false;
      const currentDate = dayjs().format();
      const _mouPeriodEnd = dayjs(mouPeriodEnd);
      const diff = _mouPeriodEnd.diff(currentDate, 'day');
      // show button extend if <= 60 day from now
      if (diff <= 60) {
        this.isShowMouExtendButton = true;
      }
    },
    setFormData(resultData) {
      this.mouForm = {
        ...this.mouForm,
        ...resultData,
        termOfPayment: +resultData.termOfPayment,
        mouPeriodStart: dayjs(resultData.mouPeriodStart).format(),
        mouPeriodEnd: dayjs(resultData.mouPeriodEnd).format(),
        mouPeriodStartDoc: resultData.mouPeriodStartDoc ? dayjs(resultData.mouPeriodStartDoc).format() : null,
        p2pLending: resultData.p2pLending ? resultData.p2pLending : [], // must array
      };
      // SET ITEMS
      this.itemsCompany = [{
        id: this.mouForm.companyId,
        name: this.mouForm.companyName,
      }];
      this.itemsTop = [{
        top: this.mouForm.termOfPayment,
      }];
      const { serviceTypes } = this.mouForm;
      // set checkbox
      this.checkboxServiceType.isFCL = serviceTypes && serviceTypes.split(',').includes('FCL');
      this.checkboxServiceType.isBackhauling = serviceTypes && serviceTypes.split(',').includes('FCL_BACKHAULING');
      this.checkboxServiceType.isLCL = serviceTypes && serviceTypes.split(',').includes('LCL');
      this.checkMouExtend(this.mouForm.mouPeriodEnd);
      this.setMonth();
    },
    setSequenceFclMouRoute(routes, page, size) {
      routes.forEach((route, index) => {
        if (!this.fclMouRouteSequence.find((x) => x[route.sequenceOrderNo])) {
          this.fclMouRouteSequence.push({
            [route.sequenceOrderNo]: (size * page) - (size - (index + 1)),
          });
        }
      });
    },
    setSequenceLclMouRoute(routes, page, size) {
      routes.forEach((route, index) => {
        if (!this.lclMouRouteSequence.find((x) => x[route.sequenceOrderNo])) {
          this.lclMouRouteSequence.push({
            [route.sequenceOrderNo]: (size * page) - (size - (index + 1)),
          });
        }
      });
    },
    async fetchWorkFlowData() {
      this.$root.$loading.show();
      try {
        const result = await this.$_services.mou.getDetailWorkflowMou(this.mouId);
        const { data } = result;
        this.setFormData(data.data);
        this.$emit('setWorkflowData', data);
        const { status } = data;
        this.isRevise = status === 'REVISE' && this.fromPath === 'approval-inbox';
      } finally {
        this.$root.$loading.hide();
      }
    },
    async fetchMouDetail() {
      const typeService = 'mouDetail';
      if (!this.getServices(typeService)) return this.$dialog.notify.error('Service tidak ditemukan');
      try {
        this.loaders.isLoadingMou = true;
        this.errors.isErrorMou = false;
        const result = await this.getServices(typeService)(this.mouId);
        this.setFormData(result.data);
      } catch (e) {
        this.errors.isErrorMou = true;
      } finally {
        this.loaders.isLoadingMou = false;
      }
    },
    getSorting(pagination) {
      const { sortBy, sortDesc } = pagination;
      let sort = '';
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        sort = `${sortBy[0]},${isDesc}`;
      }
      return sort;
    },
    getService1(typeService, filters) {
      return this.getServices(typeService)(filters, { mouBy: this.mouBy, mouId: this.mouId });
    },
    async fetchMouLocation(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const filters = {
        page: page - 1,
        size,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      if (this.getSorting(pagination)) filters.sort = this.getSorting(pagination);
      const typeService = 'location';
      try {
        this.loaders.isLoadingMouLocation = true;
        this.errors.isErrorMouLocation = false;
        const result = await this.getService1(typeService, filters);
        this.mouLocations = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
        this.totalDataLocation = result.data.totalData;
      } catch {
        this.errors.isErrorMouLocation = true;
      } finally {
        this.loaders.isLoadingMouLocation = false;
      }
    },
    async fetchMouTransportType(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const filters = {
        page: page - 1,
        size,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      if (this.getSorting(pagination)) filters.sort = this.getSorting(pagination);
      const typeService = 'transportType';
      try {
        this.loaders.isLoadingMouTransportTypes = true;
        this.errors.isErrorMouTransportTypes = false;
        const result = await this.getService1(typeService, filters);
        this.mouTransportTypes = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
        this.totalDataTransportTypes = result.data.totalData;
      } catch {
        this.errors.isErrorMouTransportTypes = true;
      } finally {
        this.loaders.isLoadingMouTransportTypes = false;
      }
    },
    async fetchMouTransportTypeLimit(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const filters = {
        page: page - 1,
        size,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      const typeService = 'transportTypeLimit';
      try {
        this.loaders.isLoadingMouTransportTypeLimit = true;
        this.errors.isErrorTransportTypeLimit = false;
        const result = await this.getService1(typeService, filters);
        this.totalTransportTypeLimitData = result.data.totalData;
        const newItemsTransportTypesMouRoute = [...this.itemsTransportTypesMouRoute];
        result.data.contents.forEach((newTransport) => {
          if (!newItemsTransportTypesMouRoute.find((oldTransport) => oldTransport.transportTypeId === newTransport.transportTypeId)) {
            newItemsTransportTypesMouRoute.push({
              transportTypeId: newTransport.transportTypeId,
              name: newTransport.transportTypeName,
            });
          }
        });
        this.itemsTransportTypesMouRoute = [...this.itemsTransportTypesMouRoute, ...newItemsTransportTypesMouRoute];
        this.mouTransportTypeLimit = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
      } catch {
        this.errors.isErrorTransportTypeLimit = true;
      } finally {
        this.loaders.isLoadingMouTransportTypeLimit = false;
      }
    },
    async fetchMouFclRoute(pagination, isDelete = null, controller) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const { mouBy, mouId } = this;
      const filters = {
        page: page - 1,
        size,
        ...this.filterMouRoute.fcl,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      const typeService = 'mouRoute';
      const serviceType = 'FCL';
      try {
        this.loaders.isLoadingFclMouRoute = true;
        this.errors.isErrorFclMouRoute = false;
        const result = await this.getServices(typeService)(this.skipEmptyStringObject(filters), { mouBy, mouId, serviceType }, controller);
        this.mouFclRoute = result.data.contents;
        this.totalFclRouteData = result.data.totalData;
        const newLocation = [...this.itemsLocationMouRoute];
        const newItemsTransportTypesMouRoute = [...this.itemsTransportTypesMouRoute];
        result.data.contents.forEach((newLocationData) => {
          if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
            newLocation.push({
              locationId: newLocationData.originLocationId,
              locationName: newLocationData.originLocationName,
            });
          }
          if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
            newLocation.push({
              locationId: newLocationData.destinationLocationId,
              locationName: newLocationData.destinationLocationName,
            });
          }
          if (newLocationData.mouRoutesMultiLoc) {
            newLocationData.mouRoutesMultiLoc.forEach((routeMultiLoc) => {
              if (!newLocation.find((oldLocation) => oldLocation.locationId === routeMultiLoc.locationId)) {
                newLocation.push({
                  locationId: routeMultiLoc.locationId,
                  locationName: routeMultiLoc.locationName,
                });
              }
            });
          }
        });
        result.data.contents.forEach((newTransport) => {
          if (!newItemsTransportTypesMouRoute.find((oldTransport) => oldTransport.transportTypeId === newTransport.transportTypesId)) {
            newItemsTransportTypesMouRoute.push({
              transportTypeId: newTransport.transportTypesId,
              name: newTransport.transportTypeName,
            });
          }
        });
        this.itemsTransportTypesMouRoute = newItemsTransportTypesMouRoute;
        this.itemsLocationMouRoute = newLocation;
        // this.itemsTransportTypesMouRoute = [...this.itemsTransportTypesMouRoute, ...newItemsTransportTypesMouRoute];
        // this.itemsLocationMouRoute = [...this.itemsLocationMouRoute, ...newLocation];
        // this.mouFclRoute = result.data.contents;
        // this.totalFclRouteData = result.data.totalData;
        // this.setSequenceFclMouRoute(this.mouFclRoute, page, size);
      } catch {
        this.errors.isErrorFclMouRoute = true;
      } finally {
        this.loaders.isLoadingFclMouRoute = false;
      }
    },
    async fetchMouFclBackhaulingRoute(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const { mouBy, mouId } = this;
      const filters = {
        page: page - 1,
        size,
        ...this.filterMouRoute.fclBackhauling,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      const typeService = 'mouRoute';
      const serviceType = 'FCL_BACKHAULING';
      try {
        this.loaders.isLoadingFclBackhaulingMouRoute = true;
        this.errors.isErrorFclBackhaulingMouRoute = false;
        const result = await this.getServices(typeService)(this.skipEmptyStringObject(filters), { mouBy, mouId, serviceType });
        this.totalFclBackhaulingRouteData = result.data.totalData;
        this.mouFclBackhaulingRoute = result.data.contents;
        const newLocation = [...this.itemsLocationMouRoute];
        const newItemsTransportTypesMouRoute = [...this.itemsTransportTypesMouRoute];
        result.data.contents.forEach((newLocationData) => {
          if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
            newLocation.push({
              locationId: newLocationData.originLocationId,
              locationName: newLocationData.originLocationName,
            });
          }
          if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
            newLocation.push({
              locationId: newLocationData.destinationLocationId,
              locationName: newLocationData.destinationLocationName,
            });
          }
          if (newLocationData.mouRoutesMultiLoc) {
            newLocationData.mouRoutesMultiLoc.forEach((routeMultiLoc) => {
              if (!newLocation.find((oldLocation) => oldLocation.locationId === routeMultiLoc.locationId)) {
                newLocation.push({
                  locationId: routeMultiLoc.locationId,
                  locationName: routeMultiLoc.locationName,
                });
              }
            });
          }
        });
        result.data.contents.forEach((newTransport) => {
          if (!newItemsTransportTypesMouRoute.find((oldTransport) => oldTransport.transportTypeId === newTransport.transportTypesId)) {
            newItemsTransportTypesMouRoute.push({
              transportTypeId: newTransport.transportTypesId,
              name: newTransport.transportTypeName,
            });
          }
        });
        this.itemsTransportTypesMouRoute = newItemsTransportTypesMouRoute;
        this.itemsLocationMouRoute = newLocation;
        if (result.data.contents.length) {
          this.checkboxServiceType.isBackhauling = true;
        }
        if (result.data.contents.length === 0 && page === 1 && !this.filterMouRoute.fclBackhauling.originId && !this.filterMouRoute.fclBackhauling.destinationId) {
          this.checkboxServiceType.isBackhauling = false;
        }
        // this.mouFclBackhaulingRoute = result.data.contents;
      } catch {
        this.errors.isErrorFclBackhaulingMouRoute = true;
      } finally {
        this.loaders.isLoadingFclBackhaulingMouRoute = false;
      }
    },
    async fetchMouLclRoute(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const { mouBy, mouId } = this;
      const filters = {
        page: page - 1,
        size,
        ...this.filterMouRoute.lcl,
      };
      const typeService = 'mouRoute';
      const serviceType = 'LCL';
      if (isDelete !== null) filters.isDelete = isDelete;
      try {
        this.loaders.isLoadingLclMouRoute = true;
        this.errors.isErrorLclMouRoute = false;
        const result = await this.getServices(typeService)(this.skipEmptyStringObject(filters), { mouBy, mouId, serviceType });
        this.totalLclRouteData = result.data.totalData;
        this.mouLclRoute = result.data.contents;
        const newLocation = [...this.itemsLocationMouRoute];
        result.data.contents.forEach((newLocationData) => {
          if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
            newLocation.push({
              locationId: newLocationData.originLocationId,
              locationName: newLocationData.originLocationName,
            });
          }
          if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
            newLocation.push({
              locationId: newLocationData.destinationLocationId,
              locationName: newLocationData.destinationLocationName,
            });
          }
        });
        this.itemsLocationMouRoute = newLocation;
        // this.mouLclRoute = result.data.contents;
        // this.setSequenceLclMouRoute(this.mouLclRoute, page, size);
      } catch (e) {
        this.errors.isErrorLclMouRoute = true;
      } finally {
        this.loaders.isLoadingLclMouRoute = false;
      }
    },
    getSubmitService(activeStatus) {
      if (activeStatus === 'DRAFT' && this.action === 'addendum') return this.$_services.mou.updateAddendumMouInformation;
      if (activeStatus === 'DRAFT') return this.$_services.mou.saveMouAsDraft;
      return this.$_services.mou.saveMou;
    },
    async submit(activeStatus) {
      if (activeStatus === 'DRAFT') {
        this.$dialog.notify.success('Berhasil menyimpan Draft');
        this.$router.go(-1);
        return;
      }
      const form = {
        ...this.mouForm,
        activeStatus,
      };
      if (this.isRevise) {
        this.$emit('submitRevise', this.mouBy, form);
        return;
      }
      try {
        this.$root.$loading.show();
        await this.getSubmitService(activeStatus)(this.mouBy, this.mouId, form);
        this.$dialog.notify.success('Berhasil');
        this.$router.go(-1);
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
