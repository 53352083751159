<template>
  <v-container class="pa-0" fluid>
    <v-row class="mb-0">
      <v-col class="pb-0" v-if="showBtnChangeHistory" cols="12">
        <v-btn
          color="red"
          class="pa-0 caption"
          text
          @click="showDialogChangeHistory"
        >
          {{$_strings.mou.THERE_ARE_CHANGES}}
          <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :key="reFetchData"
          :loading="isLoading"
          :headers="displayedHeaderLCL"
          :items="readonly ? mouLclRoute : [ ...mouLclRoute, {
            setNewData: true
          }]"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
          :item-class="itemRowBackground"
          :item-key="String(Math.random())"
          :server-items-length="serverItemsLength"
          :options.sync="pagination"
          class="elevation-1"
        >
          <template v-slot:[`header.priceKilo`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceKilo"
                  hide-details
                  :disabled="readonly"
                />
              </span>
            </div>
          </template>
          <template v-slot:[`header.priceVolume`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceVolume"
                  :disabled="readonly"
                  hide-details
                />
              </span>
            </div>
          </template>
          <template v-slot:[`header.priceCarton`]="{ header }">
            <div class="d-flex align-center">
              <span class="text-center">{{header.text}}</span>
              <span>
                <v-checkbox
                  color="white"
                  class="d-inline"
                  v-model="form.isPriceCarton"
                  :disabled="readonly"
                  hide-details
                />
              </span>
            </div>
          </template>
          <template v-slot:[`item.sequenceOrderNo`]={item}>
            <v-text-field
              dense
              outlined
              disabled
              class="body-2 pt-2"
              :value="item.sequenceOrderNo ? lclMouRouteSequence.find((seqRoute) => seqRoute[item.sequenceOrderNo])[item.sequenceOrderNo]: null"
            ></v-text-field>
          </template>
          <template v-slot:[`item.origin`]={item}>
            <v-tooltip v-if="readonly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.originLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.ORIGIN}}</span>
            </v-tooltip>
            <common-auto-complete-items
              v-else-if="item.setNewData"
              :optional="{
                mouId,
                mouBy,
                isDelete: false
              }"
              :type="getAndCheckTypeAutoCompleteLocation"
              class="body-2 pt-2"
              searchName="locationName"
              item-value="locationId"
              item-text="locationName"
              dense
              outlined
              clearable
              v-model="lclForm.originLocationId"
              :filter="pageFilters"
              :items="itemsLocationMouRoute"
              @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
            />
            <common-auto-complete-items
              v-else
              :optional="{
                mouId,
                mouBy,
                isDelete: false
              }"
              :type="getAndCheckTypeAutoCompleteLocation"
              class="body-2 pt-2"
              searchName="locationName"
              item-value="locationId"
              item-text="locationName"
              dense
              outlined
              v-model="item.originLocationId"
              :filter="pageFilters"
              :items="itemsLocationMouRoute"
              @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
            />
          </template>
          <template v-slot:[`item.destination`]={item}>
            <v-tooltip v-if="readonly" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="blue--text"
                >
                  {{item.destinationLocationName}}
                </span>
              </template>
              <span>{{$_strings.order.DESTINATION}}</span>
            </v-tooltip>
            <common-auto-complete-items
              v-else-if="item.setNewData"
              :optional="{
                mouId,
                mouBy,
                isDelete: false
              }"
              :type="getAndCheckTypeAutoCompleteLocation"
              class="body-2 pt-2"
              searchName="locationName"
              item-value="locationId"
              item-text="locationName"
              dense
              outlined
              clearable
              v-model="lclForm.destinationLocationId"
              :filter="pageFilters"
              :items="itemsLocationMouRoute"
              @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
            />
            <common-auto-complete-items
              v-else
              :optional="{
                mouId,
                mouBy,
                isDelete: false
              }"
              :type="getAndCheckTypeAutoCompleteLocation"
              class="body-2 pt-2"
              searchName="locationName"
              item-value="locationId"
              item-text="locationName"
              dense
              outlined
              v-model="item.destinationLocationId"
              :filter="pageFilters"
              :items="itemsLocationMouRoute"
              @updateItems="(newItems) => $emit('updateMouRouteLocationItems', newItems)"
            />
          </template>
          <template v-slot:[`item.etaDays`]={item}>
            <div v-if="readonly" class="pa-0 ma-0">
              <span>
                {{item.etaDays}}
              </span>
            </div>
            <v-text-field
              v-else-if="item.setNewData"
              dense
              outlined
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              class="body-2 pt-2"
              v-model="lclForm.etaDays"
            ></v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              :rules="[($event) => rules.etaDays($event)]"
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              class="body-2 pt-2"
              v-model="item.etaDays"
            ></v-text-field>
          </template>
          <template v-slot:[`item.priceKilo`]={item}>
            <p class="text-center" v-if="readonly">
              Rp. {{formatAsCurrency(item.priceKilo)}}
            </p>
            <v-text-field
              v-else-if="item.setNewData"
              dense
              outlined
              prefix="Rp"
              v-mask="{'alias': 'idr-currency', rightAlign: false}"
              @change="() => {
                lclForm.priceVolume = 0;
                lclForm.priceCarton = 0;
              }"
              :disabled="!!(lclForm.priceVolume || lclForm.priceCarton)"
              class="body-2 pt-2"
              v-model="priceKilo"
            ></v-text-field>
            <common-text-field-currency
              v-else
              dense
              outlined
              prefix="Rp"
              @change="() => {
                item.priceVolume = 0;
                item.priceCarton = 0;
              }"
              :disabled="!!(item.priceVolume || item.priceCarton)"
              class="body-2 pt-2"
              v-model="item.priceKilo"
            />
          </template>
          <template v-slot:[`item.priceVolume`]={item}>
            <p class="text-center" v-if="readonly">
              Rp. {{formatAsCurrency(item.priceVolume)}}
            </p>
            <v-text-field
              v-else-if="item.setNewData"
              dense
              outlined
              prefix="Rp"
              v-mask="{'alias': 'idr-currency', rightAlign: false}"
              v-model="priceVolume"
              class="body-2 pt-2"
              @change="() => {
                lclForm.priceKilo = 0;
                lclForm.priceCarton = 0;
              }"
              :disabled="!!(lclForm.priceCarton || lclForm.priceKilo)"
            ></v-text-field>
            <common-text-field-currency
              v-else
              dense
              outlined
              prefix="Rp"
              v-model="item.priceVolume"
              class="body-2 pt-2"
              @change="() => {
                item.priceKilo = 0;
                item.priceCarton = 0;
              }"
              :disabled="!!(item.priceCarton || item.priceKilo)"
            />
          </template>
          <template v-slot:[`item.priceCarton`]={item}>
            <p class="text-center" v-if="readonly">
              Rp. {{formatAsCurrency(item.priceCarton)}}
            </p>
            <v-text-field
              v-else-if="item.setNewData"
              dense
              outlined
              prefix="Rp"
              v-mask="{'alias': 'idr-currency', rightAlign: false}"
              v-model="priceCarton"
              class="body-2 pt-2"
              @change="() => {
                lclForm.priceKilo = 0;
                lclForm.priceVolume = 0;
              }"
              :disabled="!!(lclForm.priceVolume || lclForm.priceKilo)"
            ></v-text-field>
            <common-text-field-currency
              v-else
              dense
              outlined
              prefix="Rp"
              v-model="item.priceCarton"
              class="body-2 pt-2"
              @change="() => {
                item.priceKilo = 0;
                item.priceVolume = 0;
              }"
              :disabled="!!(item.priceVolume || item.priceKilo)"
            />
          </template>
          <template v-slot:[`item.action`]="{item,index}">
            <div v-if="!item.setNewData">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="item.deleting"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-4"
                    icon
                    color="grey"
                    @click="deleteMouRoute(item,index)"
                  >
                    <v-icon>mdi-minus-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{$_strings.common.DELETE}}</span>
              </v-tooltip>
              <div v-if="isShowCheckButton(item, index) && !item.deleting" class="d-inline">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :loading="item.updating"
                      v-bind="attrs"
                      v-on="on"
                      class="mb-4"
                      icon
                      color="primary"
                      @click="updateMouRoute(item, index)"
                    >
                      <v-icon>mdi-check-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$_strings.common.UPDATE}}</span>
                </v-tooltip>
              </div>
            </div>
            <div v-else>
              <v-btn
                @click="clearStateLclForm"
                icon
                color="red"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
              <v-btn
                :loading="item.isLoading"
                icon
                color="primary"
                @click="createMouRoute(item)"
              >
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeHistoryLcl
      :form="form"
      :service="service"
      :mouBy="mouBy"
      :mouId="mouId"
      ref="dialogChangeHistoryLcl"
    />
  </v-container>
</template>

<script>
import DialogChangeHistoryLcl from '../../Dialog/ChangeHistoryLcl';

export default {
  name: 'lcl-page',
  props: {
    mouBy: {
      type: String,
      default: '',
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouLclRoute: {
      type: Array,
      default: () => [],
    },
    itemsLocationMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Array,
      default: () => [],
    },
    lclMouRouteSequence: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
    reFetchData: {
      type: Number,
      default: 0,
    },
    totalLclRouteData: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    getServices: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    DialogChangeHistoryLcl,
  },
  data() {
    return {
      itemsDestination: [],
      headerTableLCL: [
        // {
        //   text: '',
        //   value: 'sequenceOrderNo',
        //   width: '100px',
        //   sortable: false,
        // },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'origin',
          sortable: false,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'destination',
          sortable: false,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.ETA,
          value: 'etaDays',
          sortable: false,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: 'Tonase(kg) dalam (Rp)',
          value: 'priceKilo',
          sortable: false,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: 'Kubikasi(m3) dalam (Rp)',
          value: 'priceVolume',
          sortable: false,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: 'Karton(ktn) dalam (Rp)',
          value: 'priceCarton',
          sortable: false,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: '',
          value: 'action',
          width: '120px',
          sortable: false,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
      ],
      lclForm: {
        serviceType: 'LCL',
        originLocationId: '',
        destinationLocationId: '',
        etaDays: 0,
        transportTypesId: 0,
        isAutoAssign: false,
        price: 0,
        priceMultiPick: 0.0,
        priceMultiDrop: 0.0,
        isBackHauling: false,
        sequenceOrderNo: '',
        priceKilo: 0,
        priceVolume: 0,
        priceCarton: 0,
      },
      rules: {
        etaDays: (value) => {
          if (!value) {
            return 'Eta wajib diisi';
          }
          if (+value === 0) {
            return 'Eta harus lebih dari 0';
          }
          return true;
        },
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      setOldNewDataLclMouRoute: false,
      oldDataLclMouRoute: [],
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.oldDataLclMouRoute = [];
        this.setOldNewDataLclMouRoute = true;
        this.fetchData(newVal);
      },
      deep: true,
    },
    mouLclRoute: {
      handler(newVal) {
        const find = this.findStatusAddOrDelete;
        if (this.isPageInbox && this.readonly && !find && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
        if (!this.oldDataLclMouRoute.length && this.setOldNewDataLclMouRoute) {
          this.oldDataLclMouRoute = JSON.parse(JSON.stringify(newVal));
          this.setOldNewDataLclMouRoute = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    showBtnChangeHistory() {
      if (!this.isPageInbox || !this.readonly) return false;
      if (this.findStatusAddOrDelete) return true;
      return this.isShowBtnChangeHistory;
    },
    findStatusAddOrDelete() {
      const findStatus = this.mouLclRoute.find((x) => x.status === 'DELETE' || x.status === 'ADD');
      return findStatus;
    },
    displayedHeaderLCL() {
      return this.headerTableLCL.filter((header) => {
        if (header.value === 'action' && this.readonly) return;
        return header;
      });
    },
    serverItemsLength() {
      if (!this.readonly && this.totalLclRouteData === 0) return 1;
      return this.totalLclRouteData;
    },
    priceKilo: {
      get() {
        return this.formatAsCurrency(this.lclForm.priceKilo);
      },
      set(newValue) {
        this.lclForm.priceKilo = +(newValue.replaceAll('.', ''));
      },
    },
    priceVolume: {
      get() {
        return this.formatAsCurrency(this.lclForm.priceVolume);
      },
      set(newValue) {
        this.lclForm.priceVolume = +(newValue.replaceAll('.', ''));
      },
    },
    priceCarton: {
      get() {
        return this.formatAsCurrency(this.lclForm.priceCarton);
      },
      set(newValue) {
        this.lclForm.priceCarton = +(newValue.replaceAll('.', ''));
      },
    },
    // AVOID RE-FETCHING
    getAndCheckTypeAutoCompleteLocation() {
      const { totalData, size, page } = this.pageFilters;
      if (page === 0) return 'mouLocation';
      if ((page * size) < totalData) return 'mouLocation';
      return null;
    },
  },
  methods: {
    fetchData(pagination) {
      const isDelete = !this.isPageInbox || !this.readonly ? false : null;
      this.$emit('fetchMouLclRoute', pagination, isDelete);
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistoryLcl.dialog = true;
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    clearStateLclForm() {
      this.lclForm = {
        serviceType: 'LCL',
        originLocationId: '',
        destinationLocationId: '',
        transportTypesId: 0,
        etaDays: 0,
        isAutoAssign: false,
        price: 0,
        priceMultiPick: 0.0,
        priceMultiDrop: 0.0,
        isBackHauling: false,
        sequenceOrderNo: '',
        priceKilo: 0,
        priceVolume: 0,
        priceCarton: 0,
      };
    },
    // inputChanged({ item }, type, event) {
    //   switch (type) {
    //     case 'originLocationId':
    //     case 'destinationLocationId': {
    //       const targetIndex = this.mouLclRoute.indexOf(item);
    //       const newData = {};
    //       Object.keys(this.lclForm).forEach((form) => {
    //         newData[form] = item[form];
    //       });
    //       this.mouLclRoute.splice(targetIndex, 1, newData);
    //       break;
    //     }
    //     default:
    //       break;
    //   }
    // },
    isShowCheckButton(item, index) {
      if (!this.oldDataLclMouRoute[index]) return;
      const oldData = JSON.stringify(this.oldDataLclMouRoute[index]).replace(/["]/g, '');
      const newData = JSON.stringify(item).replace(/["]/g, '');
      return oldData !== newData;
    },
    getStyle(headerBy) {
      switch (headerBy) {
        case '':
          return 'min-width:80px;';
        default: return 'min-width:120px;';
      }
    },
    resetTemporaryOldDataMouLclRoute() {
      this.oldDataLclMouRoute = [];
      this.setOldNewDataLclMouRoute = true;
    },
    async createMouRoute(item) {
      const {
        originLocationId,
        destinationLocationId,
        etaDays,
      } = this.lclForm;
      if (!originLocationId) {
        this.$dialog.notify.error('Lokasi asal belum dipilih');
        return;
      }
      if (!destinationLocationId) {
        this.$dialog.notify.error('Lokasi tujuan belum dipilih');
        return;
      }
      if (!etaDays || etaDays === 0) {
        this.$dialog.notify.error('Eta harus lebih dari 0');
        return;
      }
      const serviceType = 'lcl';
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.mou.createMouRoute(this.mouBy, this.mouId, serviceType, this.lclForm);
        this.$dialog.notify.success('Data Berhasil ditambahkan');
        this.clearStateLclForm();
        this.resetTemporaryOldDataMouLclRoute();
        this.fetchData(this.pagination);
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
    async updateMouRoute(item, index) {
      const { id: routeId } = item;
      const serviceType = 'lcl';
      try {
        const data = { ...item };
        this.$set(item, 'updating', true);
        await this.$_services.mou.updateMouRoute(this.mouBy, this.mouId, routeId, serviceType, data);
        this.$delete(item, 'updating');
        this.oldDataLclMouRoute.splice(index, 1, { ...item });
        this.$dialog.notify.success('Data Berhasil diperbarui');
      } finally {
        this.$delete(item, 'updating');
      }
    },
    async deleteMouRoute(item, index) {
      const { id: routeId, originLocationId, destinationLocationId } = item;
      const { locationName: originName } = this.itemsLocationMouRoute.find((location) => location.locationId === originLocationId);
      const { locationName: destinationName } = this.itemsLocationMouRoute.find((location) => location.locationId === destinationLocationId);
      const userRes = await this.$dialog.warning({
        text: `Apakah Anda yakin akan menghapus rute LCL  ${originName} - ${destinationName}?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      });
      if (!userRes) return;
      try {
        this.$set(item, 'deleting', true);
        await this.$_services.mou.deleteMouRoute(this.mouBy, this.mouId, routeId);
        if (this.totalLclRouteData <= 10) {
          this.mouLclRoute.splice(index, 1);
        } else {
          this.fetchData(this.pagination);
        }
        this.$dialog.notify.success('Data Berhasil dihapus');
        this.resetTemporaryOldDataMouLclRoute();
      } finally {
        this.$delete(item, 'deleting');
      }
    },
    service(filter) {
      const typeService = 'mouRoute';
      const serviceType = 'LCL';
      return this.getServices(typeService)(filter, { mouBy: this.mouBy, mouId: this.mouId, serviceType });
    },
    async checkDataChanged() {
      if (!this.isPageInbox || !this.readonly) return;
      const filter = {
        page: 0,
        size: 10,
        sort: 'status,asc',
      };
      filter.isDelete = false;
      let result = await this.service(filter);
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filter.isDelete = true;
        result = await this.service(filter);
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox || !this.readonly) return;
      if (item.status === 'DELETE') return 'red lighten-4';
      if (item.status === 'ADD') return 'green lighten-5';
    },
  },
};

</script>

<style lang="scss" scoped>
  .tooltip-icon {
    height: 10px;
    width: 10px;
  }
</style>
