var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-0"},[(_vm.showBtnChangeHistory)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pa-0 caption",attrs:{"color":"red","text":""},on:{"click":_vm.showDialogChangeHistory}},[_vm._v(" "+_vm._s(_vm.$_strings.mou.THERE_ARE_CHANGES)+" "),_c('v-icon',{staticClass:"pl-4",attrs:{"size":"15"}},[_vm._v("mdi-information-outline")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.reFetchData,staticClass:"elevation-1",attrs:{"item-key":"id","calculate-widths":"","single-expand":"","show-expand":"","loading":_vm.isLoading,"server-items-length":_vm.serverItemsLength,"options":_vm.pagination,"headers":_vm.displayedHeaderBackhauling,"items":_vm.mouFclBackhaulingRoute,"item-class":_vm.itemRowBackground,"expanded":_vm.expanded,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.itemsPerPage,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header.isAutoAssign",fn:function(ref){
        var header = ref.header;
return [_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"x-small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.mou.A_A))])])],1)]}},{key:"item.data-table-expand",fn:function(ref){
        var item = ref.item;
        var isExpanded = ref.isExpanded;
        var expand = ref.expand;
return [(_vm.showExpandPickDrop(item))?_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" $expand ")]):_vm._e()]}},{key:"item.origin",fn:function(ref){
        var item = ref.item;
return [(item.originLocationId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.originLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.ORIGIN))])]):_vm._e()]}},{key:"item.destination",fn:function(ref){
        var item = ref.item;
return [(item.destinationLocationId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.destinationLocationName)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.order.DESTINATION))])]):_vm._e()]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [(_vm.readonly)?_c('p',[_vm._v(" Rp. "+_vm._s(_vm.formatAsCurrency(item.price))+" ")]):_c('common-text-field-currency',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp"},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]}},{key:"item.isAutoAssign",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.isAutoAssign ? 'Ya' : 'Tidak')+" ")])]}},{key:"item.transportTypeId",fn:function(ref){
        var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.transportTypeName)+" ")])]}},{key:"item.priceMultiPick",fn:function(ref){
        var item = ref.item;
return [(_vm.readonly)?_c('p',[_vm._v(" Rp. "+_vm._s(_vm.formatAsCurrency(item.priceMultiPick))+" ")]):_c('common-text-field-currency',{staticClass:"body-2 pt-2",attrs:{"dense":"","outlined":"","prefix":"Rp","disabled":item.backHaulingType === 'ORIGIN'},model:{value:(item.priceMultiPick),callback:function ($$v) {_vm.$set(item, "priceMultiPick", $$v)},expression:"item.priceMultiPick"}})]}},{key:"item.priceMultiDrop",fn:function(ref){
        var item = ref.item;
return [(_vm.readonly)?_c('p',[_vm._v(" Rp. "+_vm._s(_vm.formatAsCurrency(item.priceMultiDrop))+" ")]):_c('common-text-field-currency',{staticClass:"body-2 pt-2",attrs:{"disabled":item.backHaulingType === 'ORIGIN' || _vm.readonly,"dense":"","outlined":"","prefix":"Rp"},model:{value:(item.priceMultiDrop),callback:function ($$v) {_vm.$set(item, "priceMultiDrop", $$v)},expression:"item.priceMultiDrop"}})]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('td',{attrs:{"colspan":8}},[_c('v-data-table',{staticClass:"mt-2 mb-4 elevation-1 sub_header",attrs:{"headers":_vm.subHeaderTable,"items":_vm.readonly ?
                item.mouRoutesMultiLoc : item.mouRoutesMultiLoc ? item.mouRoutesMultiLoc.concat( [{
                  setNewPickDrop: true
                }]) :
                  !item.mouRoutesMultiLoc && !_vm.readonly ? [{
                    setNewPickDrop: true
                  }] : [],"items-per-page":1000,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.origin",fn:function(items){return [(items.item.setNewPickDrop)?_c('common-auto-complete-items',{staticClass:"body-2 pt-2",attrs:{"optional":{
                    mouId: _vm.mouId,
                    mouBy: _vm.mouBy,
                    isDelete: false
                  },"type":"mouLocation","searchName":"locationName","item-value":"locationId","item-text":"locationName","dense":"","outlined":"","hide-details":"","clearable":"","filter":_vm.pageFiltersLocation,"items":_vm.itemsLocationMouRoute},on:{"updateItems":function (newItems) { return _vm.$emit('updateMouRouteLocationItems', newItems); }},model:{value:(_vm.formMouRoutesMultiLoc.locationId),callback:function ($$v) {_vm.$set(_vm.formMouRoutesMultiLoc, "locationId", $$v)},expression:"formMouRoutesMultiLoc.locationId"}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"blue--text"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsLocationMouRoute.find(function (location) { return location.locationId === items.item.locationId; }).locationName)+" ")])]}}],null,true)},[_c('span',[_vm._v("Lokasi "+_vm._s(items.item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop')+" ")])])]}},{key:"item.pickDrop",fn:function(items){return [(items.item.setNewPickDrop)?_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{attrs:{"label":"Multipick"},on:{"change":function($event){return _vm.onChangeCheckBoxPickDrop($event, 'drop')}},model:{value:(_vm.checkBoxPickDrop.pick),callback:function ($$v) {_vm.$set(_vm.checkBoxPickDrop, "pick", $$v)},expression:"checkBoxPickDrop.pick"}}),_c('v-checkbox',{staticClass:"ml-2",attrs:{"label":"Multidrop"},on:{"change":function($event){return _vm.onChangeCheckBoxPickDrop($event, 'pick')}},model:{value:(_vm.checkBoxPickDrop.drop),callback:function ($$v) {_vm.$set(_vm.checkBoxPickDrop, "drop", $$v)},expression:"checkBoxPickDrop.drop"}})],1):_c('span',[_vm._v(" "+_vm._s(items.item.pickDrop === 'PICKUP' ? 'Multipick' : 'Multidrop')+" ")])]}},(!_vm.readonly)?{key:"item.action",fn:function(items){return [(items.item.setNewPickDrop)?_c('div',[_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":_vm.clearStateFormMouRoutesMultiLoc}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.addPickDrop(item)}}},[_c('v-icon',[_vm._v("mdi-check-circle")])],1)],1):_c('v-btn',{staticClass:"mb-4",attrs:{"icon":"","color":"grey"},on:{"click":function($event){return _vm.removePickDrop(item, items.index, items.item.pickDrop)}}},[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)]}}:null],null,true)})],1)]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"icon":"","color":"grey","loading":item.deleting},on:{"click":function($event){return _vm.deleteMouFclBackhaulingRoute(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-minus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]),(_vm.isShowCheckButton(item, index) && !item.deleting)?_c('div',{staticClass:"d-inline"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"icon":"","color":"primary","loading":item.updating},on:{"click":function($event){return _vm.updateMouFclBackhaulingRoute(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.UPDATE))])])],1):_vm._e()]}}],null,true)})],1)],1),_c('DialogChangeHistoryFclBackhauling',{ref:"dialogChangeHistory",attrs:{"service":_vm.service,"mouBy":_vm.mouBy,"mouId":_vm.mouId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }