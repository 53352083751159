<template>
  <v-container class="pa-0" fluid>
    <v-row class="mb-4">
      <v-col class="pb-0" v-if="showBtnChangeHistory" cols="12">
        <v-btn
          color="red"
          class="pa-0 caption"
          text
          @click="showDialogChangeHistory"
        >
          {{$_strings.mou.THERE_ARE_CHANGES}}
          <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          item-key="id"
          class="elevation-1"
          :loading="isLoading"
          :headers="displayedHeaderLocation"
          :items="readonly ? mouTransportTypeLimit : [...mouTransportTypeLimit, {
            setNewData: true
          }]"
          :server-items-length="serverItemsLength"
          :options.sync="pagination"
          :item-class="itemRowBackground"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`item.transportTypeName`]="{item, index}">
            <common-auto-complete-items
              v-if="item.setNewData"
              :optional="{
                mouId,
                mouBy,
                isDelete: false,
              }"
              :type="getAndCheckTypeAutoCompleteTransportTypes"
              class="body-2 pt-2"
              searchName="name"
              item-text="name"
              item-value="name"
              dense
              outlined
              v-model="transportTypeLimitform.transportTypeName"
              :filter="pageFilters"
              :items="itemsTransportTypesMouRoute"
              @updateItems="(newItems) => $emit('updateTransportTypesMouRoute', newItems)"
              @input="handleChangeTypeTransporter(transportTypeLimitform, index, { type: 'transportTypeLimitform' })"
            />
            <common-auto-complete-items
              v-else
              :optional="{
                mouId,
                mouBy,
                isDelete: false,
              }"
              :type="getAndCheckTypeAutoCompleteTransportTypes"
              class="body-2 pt-2"
              searchName="name"
              item-text="name"
              item-value="name"
              dense
              outlined
              v-model="item.transportTypeName"
              :disabled="readonly"
              :filter="pageFilters"
              :items="itemsTransportTypesMouRoute"
              @updateItems="(newItems) => $emit('updateTransportTypesMouRoute', newItems)"
              @input="handleChangeTypeTransporter(item, index)"
            />
          </template>
          <template v-slot:[`item.unitLimit`]="{item}">
            <v-text-field
              v-if="item.setNewData"
              dense
              outlined
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              class="body-2 pt-2"
              v-model="transportTypeLimitform.unitLimit"
              @input="handleChangeUnitLimit(transportTypeLimitform, { type: 'transportTypeLimitform'})"
            ></v-text-field>
            <v-text-field
              v-else
              dense
              outlined
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              class="body-2 pt-2"
              v-model="item.unitLimit"
              :disabled="readonly"
              @input="handleChangeUnitLimit(item)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.limits`]="{item}">
            <div class="d-flex">
              <v-text-field
                v-if="item.setNewData"
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                v-model="transportTypeLimitform.limits"
                :rules="rules.unitRules"
                :disabled="+transportTypeLimitform.unitLimit === 0 ? true : false"
              ></v-text-field>
              <v-text-field
                v-else
                dense
                outlined
                v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
                class="body-2 pt-2"
                v-model="item.limits"
                :rules="rules.unitRules"
                :disabled="(+item.unitLimit === 0 ? true : false) || readonly"
              ></v-text-field>
            </div>
          </template>
          <template v-slot:[`item.interval`]="{item}">
            <v-select
              v-if="item.setNewData"
              item-text="name"
              item-value="value"
              v-model="transportTypeLimitform.interval"
              dense
              class="body-2 pt-2"
              outlined
              :disabled ="+transportTypeLimitform.unitLimit === 0 ? true : false"
              :items="itemsInterval"
            ></v-select>
            <v-select
              v-else
              item-text="name"
              item-value="value"
              v-model="item.interval"
              dense
              class="body-2 pt-2"
              outlined
              :disabled="(+item.unitLimit === 0 ? true : false) || readonly"
              :items="itemsInterval"
            ></v-select>
          </template>
          <template v-slot:[`item.price`]="{item, index}">
            <v-text-field
              v-if="item.setNewData"
              dense
              v-mask="{'alias': 'idr-currency', rightAlign: false}"
              outlined
              prefix="Rp"
              class="body-2 pt-2"
              v-model="price"
              :disabled ="+transportTypeLimitform.unitLimit === 0 ? true : false"
            ></v-text-field>
            <v-text-field
              v-else
              dense
              v-mask="{'alias': 'idr-currency', rightAlign: false}"
              :rules="item.price ? [] : rules.numberRules"
              outlined
              prefix="Rp"
              class="body-2 pt-2"
              :value="formatAsCurrency(item.price)"
              :disabled="(+item.unitLimit === 0 ? true : false) || readonly"
              @input="changePrice($event, index)"
            ></v-text-field>
          </template>
          <template v-slot:[`item.actions`]="{item, index}">
            <v-tooltip bottom>
              <template v-if="!item.setNewData" v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="item.deleting"
                  v-bind="attrs"
                  v-on="on"
                  class="mb-4"
                  icon
                  color="grey"
                  @click="deleteMouTransportTypeLimit(item,index)"
                >
                  <v-icon>mdi-minus-circle</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
            <div v-if="handleAddButton(item, index) && !item.deleting" class="d-inline">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="item.isLoading"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-4"
                    icon
                    color="primary"
                    @click="updateMouTransportTypeLimit(item, index)"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{$_strings.common.UPDATE}}</span>
              </v-tooltip>
            </div>
            <div v-else-if="item.setNewData">
              <v-btn
                @click="resetForm"
                icon
                color="red"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
              <v-btn
                :loading="item.isLoading"
                icon
                color="primary"
                @click="createMouTransportTypeLimit(item)"
              >
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <v-btn
              v-if="userAccess && userAccess.canCreate"
              depressed
              outlined
              small
              class="mb-3"
              @click="actionApplyButton(item, mouBy === 'transporter' ? 'dialogLicensePlate' : 'dialogSetPoliceNumberShipper')"
            >
              {{$_strings.mou.APPLY}}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogLicensePlate
      ref="dialogLicensePlate"
      :mouId="mouId"
    />
    <DialogSetPoliceNumberShipper
      ref="dialogSetPoliceNumberShipper"
      :mouId="mouId"
    />
    <DialogChangeHistoryFixedPrice
      ref="dialogChangeHistoryFixedPrice"
      :mouId="mouId"
      :mouBy="mouBy"
      :getService1="getService1"
    />
  </v-container>
</template>

<script>
import DialogLicensePlate from '../../Dialog/LicensePlate.vue';
import DialogSetPoliceNumberShipper from '../../Dialog/SetPoliceNumberShipper.vue';
import DialogChangeHistoryFixedPrice from '../../Dialog/ChangeHistoryFixedPrice';

export default {
  name: 'fixed-price-page',
  props: {
    action: {
      type: String,
      default: '',
    },
    mouBy: {
      type: String,
      default: '',
    },
    mouId: {
      type: Number,
      default: 0,
    },
    form: {
      typeof: Object,
      default: {},
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
    mouTransportTypeLimit: {
      type: Array,
      default: () => [],
    },
    itemsTransportTypesMouRoute: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Array,
      default: () => [],
    },
    totalTransportTypeLimitData: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    isPageInbox: {
      type: Boolean,
      default: () => false,
    },
    getService1: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    DialogLicensePlate,
    DialogSetPoliceNumberShipper,
    DialogChangeHistoryFixedPrice,
  },
  mounted() {
    this.filterHeaders();
  },
  data() {
    return {
      itemsInterval: [
        {
          name: 'Bulanan',
          value: 'MONTHLY',
        },
        {
          name: 'Mingguan',
          value: 'WEEKLY',
        },
      ],
      headersTableFixedPrice: [
        {
          text: this.$_strings.mou.TRANSPORT_TYPE,
          value: 'transportTypeName',
          width: '20vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.NUMBER_OF_UNIT,
          value: 'unitLimit',
          width: '10vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.NUMBER_OF_LIMIT,
          value: 'limits',
          width: '10vw',
          sortable: false,
        },
        {
          text: this.$_strings.mou.INTERVAL,
          value: 'interval',
          sortable: false,
        },
        {
          text: this.$_strings.mou.PRICE_INTERVAL,
          value: 'price',
          width: '15vw',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      rules: {
        unitRules: [(v) => /^(0|[1-9]\d*)?$/.test(v)],
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      transportTypeLimitform: {
        transportTypeId: null,
        transportTypeName: '',
        unitLimit: 0,
        limits: 0,
        price: 0,
        limitBy: 'FIXED_PRICE',
        interval: 'MONTHLY',
      },
      setOldNewDataMouTransportTypeLimit: false,
      oldDataMouTransportTypeLimit: [],
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.oldDataMouTransportTypeLimit = [];
        this.setOldNewDataMouTransportTypeLimit = true;
        this.fetchData(newVal);
      },
      deep: true,
    },
    mouTransportTypeLimit: {
      handler(newVal) {
        const find = this.findStatusAddOrDelete;
        if (this.isPageInbox && !this.readonly && !find && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
        if (!this.oldDataMouTransportTypeLimit.length && this.setOldNewDataMouTransportTypeLimit) {
          this.oldDataMouTransportTypeLimit = JSON.parse(JSON.stringify(newVal));
          this.setOldNewDataMouTransportTypeLimit = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    showBtnChangeHistory() {
      if (!this.isPageInbox || !this.readonly) return false;
      if (this.findStatusAddOrDelete) return true;
      return this.isShowBtnChangeHistory;
    },
    findStatusAddOrDelete() {
      const findStatus = this.mouTransportTypeLimit.find((x) => x.status === 'DELETE' || x.status === 'ADD');
      return findStatus;
    },
    serverItemsLength() {
      if (!this.readonly && this.totalTransportTypeLimitData === 0) return 1;
      return this.totalTransportTypeLimitData;
    },
    price: {
      set(newVal) {
        if (+newVal.charAt(0) === 0) {
          this.transportTypeLimitform.price = +newVal.charAt(1);
          return;
        }
        this.transportTypeLimitform.price = newVal.replaceAll('.', '');
      },
      get() {
        return this.formatAsCurrency(this.transportTypeLimitform.price);
      },
    },
    // AVOID RE-FETCHING
    getAndCheckTypeAutoCompleteTransportTypes() {
      const { totalData, size, page } = this.pageFilters;
      if (page === 0) return 'mouTransportType';
      if ((page * size) < totalData) return 'mouTransportType';
      return null;
    },
    displayedHeaderLocation() {
      return this.headersTableFixedPrice.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  methods: {
    fetchData(pagination) {
      const isDelete = !this.isPageInbox || !this.readonly ? false : null;
      this.$emit('fetchMouTransportTypeLimit', pagination, isDelete);
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistoryFixedPrice.dialog = true;
    },
    resetForm() {
      this.transportTypeLimitform = {
        transportTypeId: null,
        transportTypeName: '',
        unitLimit: 0,
        limits: 0,
        price: 0,
        limitBy: 'FIXED_PRICE',
        interval: 'MONTHLY',
      };
    },
    filterHeaders() {
      this.headersTableFixedPrice = this.headersTableFixedPrice.filter((header) => {
        if (!(this.action === 'set-vehicle-number') && header.value === 'action') return;
        return header;
      });
      if (!this.readonly) {
        this.headersTableFixedPrice.push({
          text: '',
          value: 'actions',
          sortable: false,
          width: 120,
        });
      }
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    actionApplyButton(item, refDialog) {
      this.$refs[refDialog].dialog = true;
      this.$refs[refDialog].item = item;
    },
    handleChangeTypeTransporter(item, index, { type } = '') {
      const { transportTypeName } = item;
      const findTransporType = this.itemsTransportTypesMouRoute.find((transport) => transport.name === transportTypeName);
      const transportTypeId = (findTransporType && findTransporType.transportTypeId) || null;
      if (type === 'transportTypeLimitform') {
        this.transportTypeLimitform.transportTypeId = transportTypeId;
        return;
      }
      this.mouTransportTypeLimit[index].transportTypeId = transportTypeId;
    },
    handleChangeUnitLimit(item, { type } = '') {
      if (+item.unitLimit === 0 && type === 'transportTypeLimitform') {
        this.transportTypeLimitform.limits = 0;
        this.transportTypeLimitform.interval = 'MONTHLY';
        this.transportTypeLimitform.price = 0;
        return;
      }
      if (+item.unitLimit === 0) {
        this.$set(item, 'price', 0);
        this.$set(item, 'limits', 0);
      }
    },
    handleAddButton(item, index) {
      if (!this.oldDataMouTransportTypeLimit[index]) return;
      const oldData = JSON.stringify(this.oldDataMouTransportTypeLimit[index]).replace(/["]/g, '');
      const newData = JSON.stringify(item).replace(/["]/g, '');
      return oldData !== newData;
    },
    changePrice(event, index) {
      if (+event.toString().charAt(0) === 0) {
        this.mouTransportTypeLimit[index].price = +event.toString().charAt(1);
        return;
      }
      this.mouTransportTypeLimit[index].price = +event.toString().replaceAll('.', '');
    },
    resetTemporaryOldDataTransportTypeLimit() {
      this.oldDataMouTransportTypeLimit = [];
      this.setOldNewDataMouTransportTypeLimit = true;
    },
    async createMouTransportTypeLimit(item) {
      const { transportTypeName } = this.transportTypeLimitform;
      if (!transportTypeName) return this.$dialog.notify.error('Harap pilih tipe kendaraan');
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.mou.createMouNewTrasportTypeLimit(this.mouBy, this.mouId, this.transportTypeLimitform);
        this.$dialog.notify.success('Data Berhasil ditambahkan');
        this.resetTemporaryOldDataTransportTypeLimit();
        this.resetForm();
        this.fetchData(this.pagination);
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
    async updateMouTransportTypeLimit(item, index) {
      const { id } = item;
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.mou.updateMouTransportTypeLimit(this.mouBy, this.mouId, id, item);
        this.$delete(item, 'isLoading');
        this.oldDataMouTransportTypeLimit.splice(index, 1, { ...this.mouTransportTypeLimit[index] });
        this.$dialog.notify.success('Data Berhasil diperbarui');
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
    async deleteMouTransportTypeLimit(item, index) {
      const { id, transportTypeName } = item;
      const userRes = await this.$dialog.warning({
        text: `Apakah Anda yakin akan menghapus fixed price dengan tipe kendaraan ${transportTypeName}?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      });
      if (!userRes) return;
      try {
        this.$set(item, 'deleting', true);
        await this.$_services.mou.deleteMouTrasportTypeLimit(this.mouBy, this.mouId, id);
        this.$dialog.notify.success('Data Berhasil dihapus');
        if (this.totalTransportTypeLimitData <= 10) {
          this.mouTransportTypeLimit.splice(index, 1);
        } else {
          this.fetchData(this.pagination);
        }
        this.resetTemporaryOldDataTransportTypeLimit();
      } finally {
        this.$delete(item, 'deleting');
      }
    },
    async checkDataChanged() {
      if (!this.isPageInbox || !this.readonly) return;
      const filter = {
        page: 0,
        size: 10,
        sort: 'status,asc',
      };
      filter.isDelete = false;
      const typeService = 'transportTypeLimit';
      let result = await this.getService1(typeService, filter);
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filter.isDelete = true;
        result = await this.getService1(typeService, filter);
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox || !this.readonly) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
  },
};

</script>
