<template>
  <v-container fluid class="pa-0">
    <h3 class="mb-4">{{$_strings.mou.LOCATION_DATA}}</h3>
    <span>{{$_strings.mou.LOCATION}}</span>
    <div v-if="showBtnChangeHistory">
      <v-btn
        color="red"
        class="pa-0 caption"
        text
        @click="showDialogChangeHistory"
      >
        {{$_strings.mou.THERE_ARE_CHANGES}}
        <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
      </v-btn>
    </div>
    <v-row class="mt-2 mb-4">
      <v-col cols="12">
        <v-data-table
          item-key="id"
          class="elevation-1"
          :server-items-length="serverItemsLength"
          :options.sync="pagination"
          :loading="isLoading"
          :headers="displayedHeaderLocation"
          :items="readonly ? mouLocations: [ ...mouLocations, {
            setNewData: true
          }]"
          :item-class="itemRowBackground"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
        >
          <template v-slot:[`item.locationName`]={item}>
            <common-auto-complete-items
              v-if="item.setNewData"
              type="locations"
              searchName="name"
              item-value="id"
              item-text="name"
              v-model="locationForm.id"
              placeholder="Masukkan Nama Lokasi"
              dense
              outlined
              class="body-2 pt-2"
              @change="changeLocation"
              @updateItems="(items) => $emit('updateLocationItems', items)"
              :filter="pageFilters"
              :items="itemsLocation"
            />
            <span v-else>{{item.locationName}}</span>
          </template>
          <template v-slot:[`item.address`]={item}>
            <v-text-field
              v-if="item.setNewData"
              dense
              v-model="locationForm.address"
              outlined
              class="body-2 pt-2"
              disabled
              placeholder="Alamat"
            ></v-text-field>
            <span v-else>{{item.address}}</span>
          </template>
          <template v-slot:[`item.locationCityName`]={item}>
            <v-text-field
              v-if="item.setNewData"
              dense
              v-model="locationForm.locationCityName"
              outlined
              class="body-2 pt-2"
              disabled
              placeholder="Kota/Kabupaten"
            ></v-text-field>
            <span v-else>{{item.locationCityName}}</span>
          </template>
          <template v-slot:[`item.latLong`]={item}>
            <v-text-field
              v-if="item.setNewData"
              dense
              v-model="locationForm.latLong"
              outlined
              class="body-2 pt-2"
              disabled
              placeholder="Koordinat"
            ></v-text-field>
            <span v-else>
              {{`Lat: ${item.latitude} Long: ${item.longitude}`}}
            </span>
          </template>
          <template v-slot:[`item.action`]="{item,index}">
            <v-btn
              v-if="item.locationId"
              @click="deleteMouLocation(item, index)"
              icon
              color="grey"
              :loading="item.deleting"
            >
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
            <div v-else>
              <v-btn :disabled="isLoadingAddLocation" @click="resetForm" icon color="red">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
              <v-btn :loading="isLoadingAddLocation" @click="createMouNewLocation" icon color="primary">
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeHistoryLocationAndTransportTypes
      :mouId="mouId"
      :service="getService1"
      ref="dialogChangeHistory"
    />
  </v-container>
</template>

<script>
import DialogChangeHistoryLocationAndTransportTypes from '../../Dialog/ChangeHistoryLocationAndTransportTypes/index.vue';

export default {
  name: 'mou-location-page',
  components: {
    DialogChangeHistoryLocationAndTransportTypes,
  },
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    getService1: {
      type: Function,
      default: () => {},
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouBy: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    itemsLocation: {
      type: Array,
      default: () => [],
    },
    mouLocations: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
    totalDataLocation: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.readonly) {
      this.headersTableLocation.pop();
    }
  },
  data() {
    return {
      locationForm: {
        id: null,
        locationName: '',
        locationCityName: '',
        address: '',
        latitude: '',
        longitude: '',
        latLong: '',
      },
      headersTableLocation: [
        {
          text: this.$_strings.mou.LOCATION_NAME,
          value: 'locationName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: `${this.$_strings.mou.CITY}/${this.$_strings.mou.DISTRICT}`,
          value: 'locationCityName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.ADDRESS,
          value: 'address',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.GOOGLE_MAPS,
          value: 'latLong',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
        {
          text: '',
          value: 'action',
          width: 150,
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      isLoadingAddLocation: false,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        const isDelete = !this.isPageInbox || !this.readonly ? false : null;
        this.$emit('fetchMouLocation', newVal, isDelete);
      },
      deep: true,
    },
    mouLocations: {
      handler() {
        const findLoc = this.findStatusAddOrDelete;
        if (this.isPageInbox && !this.readonly && !findLoc && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.checkDataChanged();
        }
      },
      deep: true,
    },
  },
  computed: {
    findStatusAddOrDelete() {
      const findStatus = this.mouLocations.find((x) => x.status === 'DELETE' || x.status === 'ADD');
      return findStatus;
    },
    showBtnChangeHistory() {
      if (!this.isPageInbox || !this.readonly) return false;
      if (this.findStatusAddOrDelete) return true;
      return this.isShowBtnChangeHistory;
    },
    serverItemsLength() {
      if (!this.readonly && this.totalDataLocation === 0) return 1;
      return this.totalDataLocation;
    },
    displayedHeaderLocation() {
      return this.headersTableLocation.filter((header) => {
        if (this.readonly && header.value === 'action') return;
        return header;
      });
    },
  },
  methods: {
    fetchData(pagination) {
      const isDelete = !this.isPageInbox || !this.readonly ? false : null;
      this.$emit('fetchMouLocation', pagination, isDelete);
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistory.type = 'location';
      this.$refs.dialogChangeHistory.dialog = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox || !this.readonly) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    async checkDataChanged() {
      if (!this.isPageInbox || !this.readonly) return;
      const filter = {
        page: 0,
        size: 10,
        sort: 'status,asc',
      };
      filter.isDelete = false;
      const typeService = 'location';
      let result = await this.getService1(typeService, filter);
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filter.isDelete = true;
        result = await this.getService1(typeService, filter);
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    changeLocation(val) {
      const location = this.itemsLocation.find((loc) => loc.id === val);
      this.locationForm = {
        id: val,
        locationName: location.name,
        locationCityName: location.city.name,
        address: location.address,
        latitude: location.latitude,
        longitude: location.longitude,
        latLong: `Lat: ${location.latitude} Long: ${location.longitude}`,
      };
    },
    resetForm() {
      this.locationForm = {
        id: null,
        locationName: '',
        locationCityName: '',
        address: '',
        latitude: '',
        longitude: '',
        latLong: '',
      };
    },
    async createMouNewLocation() {
      const { id: locationId } = this.locationForm;
      if (!locationId) return this.$dialog.notify.error('Silahkan pilih lokasi terlebih dahulu');
      try {
        this.isLoadingAddLocation = true;
        await this.$_services.mou.createMouNewLocation(this.mouBy, this.mouId, locationId);
        this.resetForm();
        this.fetchData(this.pagination);
        this.$emit('setIsCreatedPriceListSetting');
        this.$dialog.notify.success('Berhasil menambah lokasi');
      } finally {
        this.isLoadingAddLocation = false;
      }
    },
    async deleteMouLocation(item, index) {
      const userRes = await this.$dialog.warning({
        text: 'Apakah Anda yakin akan menghapus lokasi ini? Semua rute terkait dengan lokasi tersebut akan dihapus',
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      });
      if (!userRes) return;
      const { locationId, locationName } = item;
      try {
        this.$set(item, 'deleting', true);
        await this.$_services.mou.deleteMouLocation(this.mouBy, this.mouId, locationId);
        if (this.totalDataLocation <= 10) {
          this.mouLocations.splice(index, 1);
        } else {
          this.fetchData(this.pagination);
        }
        this.$emit('setIsCreatedPriceListSetting');
        this.$dialog.notify.success(`Berhasil menghapus lokasi ${locationName}`);
      } finally {
        this.$delete(item, 'deleting');
      }
    },
  },
};

</script>
