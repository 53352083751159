<template>
  <v-container fluid>
    <common-error
      v-if="errors.isErrorMouLocation"
      @fetchData="errors.isErrorMouLocation = false"
    />
    <mou-location
      v-else
      :isPageInbox="isPageInbox"
      :mouBy="mouBy"
      :mouId="mouId"
      :isLoading="loaders.isLoadingMouLocation"
      :itemsLocation="itemsLocation"
      :mouLocations="mouLocations"
      :readonly="readonly"
      :pageFilters="pageFilters.locationFilterItems"
      :totalDataLocation="totalDataLocation"
      :getService1="getService1"
      @setIsCreatedPriceListSetting="setIsCreatedPriceListSetting"
      @fetchMouLocation="(pagination, isDelete) => $emit('fetchMouLocation', pagination, isDelete)"
      @updateLocationItems="(items) => $emit('updateLocationItems', items)"
    />
    <common-error
      v-if="errors.isErrorMouTransportTypes"
      @fetchData="errors.isErrorMouTransportTypes = false"
    />
    <mou-transport-types
      v-else
      :isPageInbox="isPageInbox"
      :mouBy="mouBy"
      :mouId="mouId"
      :isLoading="loaders.isLoadingMouTransportTypes"
      :itemsTransportTypes="itemsTransportTypes"
      :mouTransportTypes="mouTransportTypes"
      :readonly="readonly"
      :pageFilters="pageFilters.transportTypesFilterItems"
      :totalDataTransportTypes="totalDataTransportTypes"
      :getService1="getService1"
      @setIsCreatedPriceListSetting="setIsCreatedPriceListSetting"
      @fetchMouTransportType="(pagination, isDelete) => $emit('fetchMouTransportType', pagination, isDelete)"
      @updateTransportTypesItems="(items) => $emit('updateTransportTypesItems', items)"
    />
    <v-row>
      <v-col cols="12" class="d-flex mt-5 justify-end">
        <v-btn @click="$emit('changeStep', 0)" class="mr-2" outlined color="primary">
          {{$_strings.common.BACK}}
        </v-btn>
        <v-btn @click="$emit('changeStep', 2)" color="primary">
          {{$_strings.common.NEXT}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MouLocation from './MouLocation.vue';
import MouTransportTypes from './MouTransportTypes.vue';

export default {
  name: 'location-vehicle-page',
  components: {
    MouLocation,
    MouTransportTypes,
  },
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    getService1: {
      type: Function,
      default: () => {},
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouBy: {
      type: String,
      default: '',
    },
    form: {
      type: Object,
      default: () => {},
    },
    loaders: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    itemsTransportTypes: {
      type: Array,
      default: () => [],
    },
    itemsLocation: {
      type: Array,
      default: () => [],
    },
    mouLocations: {
      type: Array,
      default: () => [],
    },
    mouTransportTypes: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    pageFilters: {
      type: Object,
      default: () => {},
    },
    checkboxServiceType: {
      type: Object,
      default: () => {},
    },
    step: {
      type: Number,
      default: 0,
    },
    totalDataLocation: {
      type: Number,
      default: 0,
    },
    totalDataTransportTypes: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    step(newVal) {
      if (newVal === 2 && this.isCreatedPriceListSetting) {
        this.$emit('counterReCreatedPriceListSetting');
        const { isFCL, isBackhauling, isLCL } = this.tempCheckBoxServiceType;
        const { tempIsPriceFixed } = this;

        this.checkboxServiceType.isFCL = isFCL;
        this.checkboxServiceType.isBackhauling = isBackhauling;
        this.checkboxServiceType.isLCL = isLCL;
        this.form.isPriceFixed = tempIsPriceFixed;
        this.isCreatedPriceListSetting = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      isCreatedPriceListSetting: false,
      // for rendering component
      tempCheckBoxServiceType: {
        isFCL: false,
        isBackhauling: false,
        isLCL: false,
      },
      tempIsPriceFixed: false,
    };
  },
  methods: {
    setIsCreatedPriceListSetting() {
      const { isFCL, isBackhauling, isLCL } = this.checkboxServiceType;
      const { isPriceFixed } = this.form;
      if (!this.isCreatedPriceListSetting) {
        this.tempCheckBoxServiceType.isFCL = isFCL;
        this.tempCheckBoxServiceType.isBackhauling = isBackhauling;
        this.tempCheckBoxServiceType.isLCL = isLCL;
        this.tempIsPriceFixed = isPriceFixed;
        this.isCreatedPriceListSetting = true;

        // set false
        this.form.isPriceFixed = false;
        this.checkboxServiceType.isFCL = false;
        this.checkboxServiceType.isBackhauling = false;
        this.checkboxServiceType.isLCL = false;
      }
    },
  },
};

</script>
